import React, { createContext, useContext, useEffect, useState } from "react"
import { useHookWithRefCallback } from "../hooks/Hooks"
import Menu from '../components/menus/Menu'
import Notification from "../components/Notification"
import SearchBar from "../components/SearchBar"
import { AuthDispatchContext } from "../App"
import Footer from "../components/Footer"
import { useLocation } from "react-router"



export const LayoutContext = createContext(null)


const Layout = (props) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const { pathname } = useLocation()

    const [scroller, setScroller] = useHookWithRefCallback()
    const [notifContent, setNotifContent] = useState()

    const [forceCloseNotif, setForceCloseNotif] = useState()


    useEffect(() => {
        if (scroller && scroller.current) {
            scroller.current.scrollTo(0, 0)
        }
    }, [scroller, pathname])
    

    return (
        <LayoutContext.Provider value={{scroller:scroller, setNotifContent:setNotifContent, setForceCloseNotif:setForceCloseNotif}}>
            <div className='d-flex flex-column min-vh-100'>
                <Notification content={notifContent} forceCloseNotif={forceCloseNotif} setForceCloseNotif={setForceCloseNotif}/>
                <Menu/>
                <div 
                    id='editorContainer'
                    ref={setScroller}
                    // className='pt-5' 
                    style={{
                        flex: '1 1 0',
                        display: 'flex',                
                        flexDirection:'column',
                        overflow:'auto'
                    }}
                >
                    {authDispatchContext.isDomain &&
                    <div className='d-sm-block d-md-none mt-3'>
                        <SearchBar filtername='names'/>
                    </div>}

                    {props.children}

                    <Footer/>
                </div>
            </div>
        </LayoutContext.Provider>
    )
}

export default Layout