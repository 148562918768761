import { useContext } from "react"
import { AuthDispatchContext } from "../App"
import { useNavigate } from "react-router"


export const userSubdomain = "s"
export const domains = ["blender", "unreal", "godot", "indiegames", "houdini", "3dsmax", "maya", "3dmodels", "books", "fiberart"]



export const UserUrl = (username) => {
    let url = `${window.location.protocol}//${username}.${userSubdomain}.${process.env.REACT_APP_FRONT_DOMAIN}`
    if (window.location.port === '3000') {
        url = `${url}:3000`
    }

    return url
}



export const ProductUrl = (slug, isSubDomain) => {
    if (isSubDomain) {
        let domain = process.env.REACT_APP_FRONT_DOMAIN
        if (window.location.port === '3000') {
            domain = `${domain}:3000`
        }

        return `${window.location.protocol}//${domain}/product/${slug}`
    }

    return `/product/${slug}`
}



export const getSubdomain = () => {
    const subdomain_match = window.location.hostname.split(process.env.REACT_APP_FRONT_DOMAIN).filter(element => element)

    if (subdomain_match.length===0)
    {
        // subdomain, isDomain, isUser, isNeither
        return ['', false, false, true]
    }

    const subdomain = subdomain_match[0].replace(/\.$/, "").toLowerCase()

    const notDomains = ["www", "localhost", "192", "127"]

    if (notDomains.includes(subdomain))
        return [subdomain, false, false, true]

    if (domains.includes(subdomain))
        return [subdomain, true, false, false]

    const [, user, ] = subdomain.match(`([A-Za-z0-9]+).${userSubdomain}$`) || []

    if (user) {
        return [user, false, true, false]
    }

    // Remove unwanted Subdomain in current location url
    let host = window.location.host
    host = host.replace(subdomain, '')

    if (host.startsWith('.')) {
        host = host.substring(1)
    }

    window.location.assign(`http://${host}`)
    return ['', false, false, true]
}



export const changeSubdomain = (newSubdomain, {isUser=false, pathname=''}={}) => {

    const protocol = `${window.location.protocol}//` // 'http://'

    let [currentSub, , currentIsUser] = getSubdomain()

    let host = window.location.host

    if (currentSub === newSubdomain) {
        if (pathname) {
            window.location.assign(`${protocol}${host}${pathname}`)
        }
        else {
            window.location.assign(`${protocol}${host}`)
        }
        return
    }

    if (isUser) {
        newSubdomain = `${newSubdomain}.${userSubdomain}`
    }

    if (currentIsUser) {
        currentSub = `${currentSub}.${userSubdomain}`
    }

    if (currentSub)
        host = host.replace(currentSub, newSubdomain)
    else
        host = `${newSubdomain}.${window.location.host}`

    if (host.startsWith('.'))
        host = host.substring(1)

        // const start = ''
    // if (keepAll)
    //     window.location.assign(`${protocol}${host}${window.location.pathname}${window.location.search}`)
    if (pathname)
        window.location.assign(`${protocol}${host}${pathname}`)
    else
        window.location.assign(`${protocol}${host}`)
}




export const useDomainNavigate = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useNavigate()

    const domainNavigate = (pathname, subdomain='', isUser=false, force=false) => {
        if (authDispatchContext.isUser || subdomain || force) {
            changeSubdomain(subdomain, {pathname:pathname, isUser:isUser}, force)
            // if (force) {
            //     navigate(pathname)
            // }
        }
        else {
            navigate(pathname)
        }
    }

    return domainNavigate
}