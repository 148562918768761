import React from "react"
import { Button, Form, FormGroup, Input, Col, Container } from "reactstrap"
import { useSearchParams } from "react-router-dom"
import { useDomainNavigate } from "../tools/Subdomain"


const SearchBar = ({filtername='names', closeOffCanvas=()=>{}}) => {
    const navigate = useDomainNavigate()
    const [searchParams] = useSearchParams()
    // const searchName = searchParams.get(filtername)


    const [formValue, setformValue] = React.useState({
        // name: (searchName ? searchName : ''),
        name: '',
    })    


    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        })
    }


    const handleSubmit = async(event) => {
        closeOffCanvas()

        event.preventDefault()

        if (formValue.name) {
            searchParams.append(filtername, formValue.name + '_And')
            formValue.name = ''
        }
        // else {
        //     searchParams.delete(filtername)
        // }

        searchParams.delete('offset')


        navigate({
            pathname: '/search',
            search: `?${searchParams}`,
          })
    }


    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <FormGroup  className='form-group' row >
                    <Col className='mt-0'>
                        <Input
                            className='form-control'
                            type="search"
                            name="name"
                            placeholder='Search'
                            value={formValue.name}
                            onChange={handleChange}
                            // valid={true}
                        // sm={2}
                        ></Input>
                    </Col>
                    <Col xs="auto" style={{textAlign:"right"}} className='mt-0'>                        
                        <Button color='primary' type="submit" style={{fontSize: 'x-large', fontWeight: '100'}}>
                            &#8981;
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Container>
    )
}

export default SearchBar
