import React, { useState } from "react"
import { Col, Collapse, Container, Row } from "reactstrap"
import { CloudURL } from "../tools/Cloud"
import { SiteName, SiteSocialNetworks } from "../components/SiteTitle"
import { SEO } from "../components/SEO"
import { Link } from "react-router-dom"
import { useDomainNavigate } from "../tools/Subdomain"



const Carets = (open) => {
    const style = {
        fontSize: 'medium',
        verticalAlign: 'text-bottom',
        margin: '0'
    }

    return (
        open ? 
            <i class="fa-solid fa-caret-down" style={style}></i>
            :
            <i class="fa-solid fa-caret-right" style={style}></i>
    )
}



const About = () => {
    const navigate = useDomainNavigate()

    const [isOpenGrowing, setIsOpenGrowing] = useState(false)
    const [isOpenVPN, setIsOpenVPN] = useState(false)
    const [isOpenCategories, setIsOpenCategories] = useState(false)

    const toggleGrowing = () => setIsOpenGrowing(!isOpenGrowing)
    const toggleVPN = () => setIsOpenVPN(!isOpenVPN)
    const toggleCategories = () => setIsOpenCategories(!isOpenCategories)

    return (<>
        <SEO title={`About - ${SiteName}`} />

        <Container className='mt-5 mb-5' style={{ maxWidth:960 }}>
            <Row>                
                <Col xs="auto">
                    <h2>
                        <img
                            src={CloudURL('logo64.webp')}
                            style={{verticalAlign:'bottom', height:'1.2em'}}
                            className="mx-2"
                            alt=''
                        />
                        About {SiteName}
                    </h2>
                </Col>

                <Col style={{minWidth:'100px'}} className="text-end">
                    <h3>
                        <SiteSocialNetworks/>
                    </h3>    
                </Col>
            </Row>

            <p>Welcome to <i>{SiteName}</i>: The Marketplace that Empowers Purchasing Power Parity (PPP) and Ensures Fair Visibility!</p>
            <p>At <i>{SiteName}</i>, our commitment is to ensure equal access for everyone, irrespective of their location or economic status. Explore our <Link to="/philosophy">Philosophy page</Link> for insights into how we maintain fair pricing worldwide and uphold our values that shape FairMarket.Land.</p>
            <br/>
            <h6 style={{cursor:'pointer'}} onClick={toggleGrowing}>{Carets(isOpenGrowing)} Explore our growing <Link onClick={() => navigate('/.', '', false, true)}>submarkets</Link></h6>
            <p>
                <Collapse isOpen={isOpenGrowing}>
                    Right now, you can check out <Link onClick={() => navigate('', 'blender')}>Blender</Link> and <Link onClick={() => navigate('', 'houdini')}>Houdini</Link> sections, thanks to products already available.
                    <br/>Coming up, we're planning sections for <b>Unreal, Godot, Indie Games, 3ds Max, Maya, and Books</b>. They're just waiting for related products to open.
                    <br/>Have suggestions for new submarkets? Please reach out at <a target="_blank" rel="noreferrer" href={`mailto:hello@${process.env.REACT_APP_FRONT_DOMAIN}`}>hello@{process.env.REACT_APP_FRONT_DOMAIN}</a>!
                </Collapse>
            </p>
            <br/>
            <h6 style={{cursor:'pointer'}} onClick={toggleVPN}>{Carets(isOpenVPN)} No need for VPN</h6>
            <p>
                <Collapse isOpen={isOpenVPN}>
                <i>{SiteName}</i> allows individuals to change their country settings during the checkout process. This feature is particularly useful for those on holiday overseas who still wish to make a purchase and experience the same seamless transaction process as they would from their home country.
                <br/>It's important to note that the country selected for purchase must align with the country of the credit card used, a verification process handled by Stripe during payment.
                </Collapse>
            </p>
            <br/>
            <h6 style={{cursor:'pointer'}} onClick={toggleCategories}>{Carets(isOpenCategories)} Explore a Multitude of Categories</h6>
            <p>
                <Collapse isOpen={isOpenCategories}>
                    <i>{SiteName}</i> hosts numerous categories, but only those with available products are displayed. This helps us ensure that you see thriving and active segments at a glance.
                    <br/>Have thoughts on new categories you'd love to see? Please reach out at <a target="_blank" rel="noreferrer" href={`mailto:hello@${process.env.REACT_APP_FRONT_DOMAIN}`}>hello@{process.env.REACT_APP_FRONT_DOMAIN}</a>!
                </Collapse>
            </p>
            <br/>
            <h6>💸 Transparent Pricing</h6>
            <p>No subscription fees, no hidden listing fees, no discovery fees, no increased merchant or transaction fees, and no harsh commission rates. You can showcase your work without incurring any costs and without pay-to-win schemes.</p>
            <br/>
            <h6>💰 Fair Commission</h6>
            <p>We charge a flat 10% commission, plus Stripe fees (check out Stripe's pricing details <a target="_blank" rel="noreferrer" href="https://stripe.com/pricing">here</a> and <a target="_blank" rel="noreferrer" href="https://stripe.com/connect/pricing">there</a>). Our goal is to support your success, not exploit it.</p>
            <br/>
            <p className="text-center">
                <b>Don't Miss Your Chance for Lifetime Reduced Seller Fees!*</b><br/>
                <s>1% for the first 10 Sellers</s> <i className="ql-color-danger">All Gone!</i> - 5% for the next 5 Sellers<br/>
                <small>*with at least 1 approved product</small><br/>
            </p>            
            <br/>
            <h6>🌍 Broader Pool of Buyers</h6>
            <p>FairMarket.Land is all about fairness. We use the Local Purchasing Power Index (LPPI) and real-time Exchange Rates to meet buyers & sellers halfway. Buyers can make purchases in their local currency, ensuring fair and accessible pricing worldwide, while sellers maintain a reasonable and equitable selling price.</p>
            <br/>
            <h6>🌟 Fair Visibility</h6>
            <p>To achieve this, we have developed an advanced algorithm that calculates visibility fairness based on a combination of factors such as prints, views, purchases, and time.</p>
            <br/>
            <h6>🌈 Choose to Give Back</h6>
            <p>Yes, you can choose to donate 1% of your sale to either the Blender Foundation and/or the Godot Foundation and/or 1% for the Planet. It's a way to contribute to the creative community you love.</p>
            <br/>
            <h6>Contact</h6>
            <p>To get in touch with us, please contact us at <a target="_blank" rel="noreferrer" href={`mailto:support@${process.env.REACT_APP_FRONT_DOMAIN}`}>support@{process.env.REACT_APP_FRONT_DOMAIN}</a>. We welcome your feedback, suggestions, and inquiries. Together, let's build a fairer and more accessible marketplace for all.</p>
        </Container>
    </>)
}

export default About