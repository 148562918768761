import React, { useContext } from "react"
import { Navigate } from "react-router-dom"

import { AuthDispatchContext } from "../../App"
import ProductCard from "../../components/ProductCard"
import { LayoutContext } from "../../hocs/Layout"
import InfiniteScroll from "../../components/InfiniteScroll"

import * as Enspoints from '../../Endpoints'
import { EmptyLibrary } from "../../components/Empty"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"


const Library = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }


    // const [searchParams, setSearchParams] = useSearchParams()
    // let limit = searchParams.get("limit")
    // let offset = searchParams.get("offset")

    // if (!limit) {
    //     limit = 8
    // }

    // if (!offset) {
    //     offset = 0
    // }

    // const endpoint = Enspoints.LIBRARY
    // const strFilters = StringTools.dictToQuery({
    //     limit:limit,
    //     offset:offset})
    // const url = endpoint + ((strFilters.length > 0) ? '?' + strFilters : '')

    // const { responseData, isLoading, hasError, reFetch } = useRequest(authDispatchContext.dispatch, url, { auth:true, firstCall:false })

    // const onChange = (page, pageSize) => {
    //     const offset = (page - 1) * pageSize
    //     searchParams.set('limit', pageSize)
    //     searchParams.set('offset', offset)
    //     setSearchParams(searchParams)
    // }
    
    // useEffect(() => {
    //     reFetch()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [limit, offset])


    // if (hasError) {
    //     return (
    //         <Container className='mt-5'>
    //             Error!
    //         </Container>
    //         )
    // }

    // if (isLoading) {
    //     return (
    //         <Container className='mt-5'>
    //             <Loading/>
    //         </Container>
    //         )
    // }

    const renderItem = (item) => {
        return <ProductCard product={item} hideAuthor={false} editCard={false}/>
    }

    return (
        <>
            <SEO title={`Library - ${SiteName}`} />
            
            <div className='d-md-none d-block mb-3'>
                <h1>Library</h1>
            </div>

        {/* <Container className='mt-5'> */}
            <InfiniteScroll
                scrollerRef={layoutContext.scroller}
                endpoint={Enspoints.LIBRARY}
                // filters={{}}
                getKey={item => item.id}
                renderItem={renderItem}
                renderEmpty={EmptyLibrary}
                auth={true}
                useCache={false}
            />

             {/* <Row>
                <Pagination
                    responsive={true}
                    total={responseData.length}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    defaultPageSize={limit}
                    defaultCurrent={1 + offset/limit}
                    pageSizeOptions={[8, 12, 16]}
                    onChange={onChange}
                />
            </Row>
            <Row
                lg="4"
                md="2"
                sm="2"
                xs="1"
            >
                {responseData.map((item, i) => {
                    return (
                        <Col key={item.id}>
                            <ProductCard product={item} hideAuthor={false} editCard={false}/>
                        </Col>
                    )
                })}
            </Row> */}
        </>
    )
}

export default Library