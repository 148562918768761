import { useContext, useEffect, useState } from "react"
import { findFlagUrlByIso2Code } from "country-flags-svg"
import { useRequest } from "../../hooks/Hooks"
import { AuthDispatchContext } from '../../App'
import { Button, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader } from "reactstrap"
import { ListGroup, ListGroupItem } from 'reactstrap'
import { countries } from 'country-flags-svg'
import * as Request from "../../actions/Request"
import { LayoutContext } from "../../hocs/Layout"

import * as Endpoints from '../../Endpoints'



const MenuCountry = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const [flagURL, setFlagURL] = useState('')
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState('')

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, Endpoints.COUNTRY_CURRENT, {method:'post', useCache:false })

    const toggle = () => setModal(!modal)


    useEffect(() => {
        if (responseData && responseData.countryCode) {
            const svgURL = findFlagUrlByIso2Code(responseData.countryCode)
            setFlagURL(svgURL)
        }        
    }, [responseData])


    const travelTo = async(code, name) => {
        try {
            const params = {
                code: code,
            }
            await Request.request(authDispatchContext.dispatch, 'post', Endpoints.COUNTRY_CHOOSE, params)

            const svgURL = findFlagUrlByIso2Code(code)
            setFlagURL(svgURL)

            layoutContext.setNotifContent({message:`Welcome to ${name}!`})
            setModal(false)

            setTimeout(() => window.location.reload(), 1000);

            // window.location.reload()
            // authDispatchContext.forceUpdate()
            // navigate({})
            // navigate('', { replace: false })
            // navigate({}, { replace: true })
        }
        catch (error) {
            layoutContext.setNotifContent({title:'Error', message:`Cannot travel to ${name}.`})
            // throw error
        }
    }


    const hasImage = !isLoading && !hasError && !!responseData

    return (<>
        {hasImage &&
        <Button color='link' onClick={toggle}>
            <img alt='' height={32} src={flagURL}/>
            <span style={{fontSize: 'xx-small', padding: '1em'}}>
                Change Country
            </span>
        </Button>}

        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Travel to a new Country</ModalHeader>
            <ModalBody
                // style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
                <InputGroup>
                    <InputGroupText>
                        Filter
                    </InputGroupText>
                    <Input value={filter} onChange={(event) => setFilter(event.target.value)}/>
                </InputGroup>

                <ListGroup flush>
                    {countries && countries.map((element) => {
                        if (!element.name.toLowerCase().includes(filter.toLowerCase())) {
                            return <></>
                        }

                        return <ListGroupItem key={element.iso2}>
                            <Button color="light" block onClick={() => travelTo(element.iso2, element.name)}>
                                <div
                                    style={{
                                        display:'flex', 
                                        alignItems:'center',
                                        paddingLeft:'30px'
                                    }}
                                >
                                    <img alt='' className='m-2' src={element.flag} width={'50px'}
                                        style={{
                                            width:'50px',
                                            marginRight:'10px!important',
                                            marginLeft:'10px!important',
                                            objectFit:'contain'
                                        }}
                                    />
                                    <span style={{paddingLeft:'20px'}}>
                                        {element.name}
                                    </span>
                                </div>
                            </Button>
                        </ListGroupItem>
                    })}
                </ListGroup>
                </ModalBody>
        </Modal>
    </>)
}



export default MenuCountry