import { useContext, useState } from "react"
import { Alert, Button, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { AuthDispatchContext } from "../App"
import { useDomainNavigate } from "../tools/Subdomain"
import { LayoutContext } from "../hocs/Layout"
import * as Request from '../actions/Request'
import * as Endpoints from '../Endpoints'



export const AskSeller = ({seller, label, product}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const navigate = useDomainNavigate()

    const [subject, setSubject] = useState(product ? product : `Hello ${seller}`)
    const [errSubject, setErrSubject] = useState(false)

    const [message, setMessage] = useState('')
    const [errMessage, setErrMessage] = useState(false)

    const [sending, setSending] = useState(false)

    const [modalAsk, setModalAsk] = useState(false)
    const toggleModalAsk = (event) => {
        if (event && typeof event.preventDefault === "function") {
            event.preventDefault()
        }

        setErrSubject(false)
        setErrMessage(false)
        setModalAsk(!modalAsk)
    }


    const onSendMessage = async(event) => {
        if (sending) {
            return
        }

        event.preventDefault()
        
        let error = false
        setErrSubject(false)
        setErrMessage(false)
        
        if (!subject) {
            setErrSubject(true)
            error = true
        }
        
        if (!message) {
            setErrMessage(true)
            error = true
        }
        
        if (error) {
            return
        }
        
        const params = {
            seller: seller,
            subject: subject,
            message: message
        }
        
        try {
            setSending(true)
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STORE_CONTACT, params)
            
            setMessage('')
            toggleModalAsk()

            layoutContext.setNotifContent({message:'Message Sent!'})
        }
        catch(error) {
            layoutContext.setNotifContent({title:'Error', message:'Message not Sent!'})
        }
        finally {
            setSending(false)
        }
    }

    return (<>
        {!authDispatchContext.state.isAuthenticated && <Modal isOpen={modalAsk} toggle={toggleModalAsk}>
            {label && <ModalHeader toggle={toggleModalAsk}>{label}</ModalHeader>}
            {!label && <ModalHeader toggle={toggleModalAsk}>Contact {seller}</ModalHeader>}

            <ModalBody>
                {label && <>You have to log in in order to <b>{label}</b>.</>}
                {!label && <>You have to log in in order to <b>Contact {seller}</b>.</>}
                <div className="mt-3">
                    <Button color='primary' onClick={() => {authDispatchContext.navigateRedirect(navigate, '/login')}}>Login</Button>
                    &nbsp;
                    <Button onClick={() => {authDispatchContext.navigateRedirect(navigate, '/signup')}}>Sign Up</Button>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button onClick={toggleModalAsk}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>}

        {authDispatchContext.state.isAuthenticated && <Modal isOpen={modalAsk} toggle={toggleModalAsk}>
            {label && <ModalHeader toggle={toggleModalAsk}>{label}</ModalHeader>}
            {!label && <ModalHeader toggle={toggleModalAsk}>Contact {seller}</ModalHeader>}

            <ModalBody>
                <InputGroup>
                    <InputGroupText>
                        Subject
                    </InputGroupText>
                    
                    <Input
                        type="text"
                        placeholder="Subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        disabled={!!product}
                    />
                </InputGroup>
                {errSubject && <Alert color="danger">Subject can't be empty!</Alert>}

                <InputGroup>
                    <Input
                        type="textarea"
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{minHeight:'200px'}}
                        />
                </InputGroup>
                {errMessage && <Alert color="danger">Message can't be empty!</Alert>}
            </ModalBody>

            <ModalFooter>
                <Button onClick={toggleModalAsk}>
                    Cancel
                </Button>
                <Button color='primary' onClick={onSendMessage} disabled={sending}>
                    {!sending && <>Send</>}
                    {sending && <>Sending...</>}
                </Button>
            </ModalFooter>
        </Modal>}

        {label && <Button className="w-100" color="info" onClick={toggleModalAsk}>{label}</Button>}

        {!label && <a href="/" onClick={toggleModalAsk} className="fa-regular fa-envelope"><i hidden="true">&nbsp;</i></a>}
    </>)
}
