import { useRef } from "react";
import { Card, CardSubtitle, CardText, CardTitle } from "reactstrap"



export const Trustpilot = ({ className, style }) => {
    // useEffect(() => {
    //     const script = document.createElement('script')
    //     script.type = 'text/javascript'
    //     script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    //     script.async = true
    //     document.body.appendChild(script)

    //     return () => {
    //         document.body.removeChild(script)
    //     }
    // }, [])

    return (
        <a href="https://www.trustpilot.com/review/fairmarket.land" target="_blank" rel="noreferrer"><img alt="Trustpilot" height={20} style={{verticalAlign: 'sub'}} src='https://cdn.trustpilot.net/brand-assets/4.3.0/logo-black.svg'></img></a>
    )
}



export const TrustpilotReview = ({title, desc, name, link}) => {
    const anchorRef = useRef(null)

    const handleClick = () => {
        if (anchorRef.current) {
            anchorRef.current.click()
        }
    }

    return (
        <Card className="trustpilot p-3 m-1" onClick={handleClick}>
            <CardTitle className="title">
                <img className="mb-1" height={20} alt="Rated 5 out of 5 stars" src='https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg'/>
                <br/>
                <a ref={anchorRef} className="link" href={link} target="_blank" rel="noreferrer">{title}</a>
            </CardTitle>

            <CardSubtitle
                    className="mb-2 text-muted card-subtitle text-notransform"
                    tag="h6"
                >
                    {desc}
            </CardSubtitle>

            <CardText tag="h6">
                {name}
            </CardText>
        </Card>
    )
}