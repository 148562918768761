import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

import { AuthDispatchContext } from "../App"
import { useHookWithRefCallback, useRequest, useWindowDimensions } from "../hooks/Hooks"
import * as StringTools from "../tools/StringTools"

import Loading from "./Loading"
import { areArraysEqual } from "../tools/ArrayTools"



const defconfig = {
    xl:4,
    lg:3,
    md:2,
    sm:1,
    xs:1,

    fetchRows:3,
    maxRows:666
}



function observeOnOff (observer, node, On) {
    if (observer && node) {
        if (On) {
            observer.observe(node)
        }
        else {
            observer.unobserve(node)
        }
    }
}



const InfiniteScroll = ({scrollerRef, endpoint, filters, getKey, renderItem, renderEmpty=() => {}, auth=false, useCache=true, config=defconfig}) => {
    const authDispatchContext = useContext(AuthDispatchContext)

    const { width } = useWindowDimensions()

    const [list, setList] = useState()
    const [isEmpty, setIsEmpty] = useState(false)

    const obsBottomRef = useRef()
    const obsTopRef = useRef()    
    const obsNodeRef = useRef(new IntersectionObserver(handleObserverNode, {
        root: scrollerRef.current,
        rootMargin: '0px',
        threshold: 0
    }))

    const nodeRefs = useRef([])

    const [loaderBottom, setLoaderBottom] = useHookWithRefCallback(observeBottomCB)
    const [loaderTop, setLoaderTop] = useHookWithRefCallback(observeTopCB)
    
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParamsRef = useRef(searchParams)

    const filtersRef = useRef(filters)
    
    const offset = 0 //parseInt(searchParams.get("offset") ?? 0)

    const { responseData: responseDataTop, isLoading: isLoadingTop, hasError: hasErrorTop, reFetch: reFetchTop } = useRequest(authDispatchContext.dispatch, '', { auth:auth, firstCall:false, useCache:useCache })
    const { responseData: responseDataBottom, isLoading: isLoadingBottom, hasError: hasErrorBottom, reFetch: reFetchBottom } = useRequest(authDispatchContext.dispatch, '', { auth:auth, firstCall:false, useCache:useCache })

    const [topReached, setTopReached] = useState(false)
    const [bottomReached, setBottomReached] = useState(false)

    const bunchRef = useRef({
        jump:false,
        offsetTop:0,
        i:0,
    })

    const pos = useRef({
        top:offset,
        bottom:offset,
        limit:1,
        maxElements:1
    })


    function setNodeRef (node, i) {
        if (!node) {
            return
        }

        nodeRefs.current[i] = node
        obsNodeRef.current.observe(node)
    }


    function observeBottomCB () {
        const observer = new IntersectionObserver(handleObserverBottom, {
            root: scrollerRef.current,
            rootMargin: '0px',
            threshold: 0
        })
        observeBottom(true, observer)
    }


    function observeTopCB () {
        const observer = new IntersectionObserver(handleObserverTop, {
            root: scrollerRef.current,
            rootMargin: '0px',
            threshold: 0
        })
        observeTop(true, observer)
    }


    function observeBottom (On, observer=undefined) {
        let obs = obsBottomRef.current

        if (observer) {
            obs = observer
            obsBottomRef.current = observer
        }

        observeOnOff(obs, loaderBottom.current, On)
    }


    function observeTop (On, observer=undefined) {
        let obs = obsTopRef.current

        if (observer) {
            obs = observer
            obsTopRef.current = observer
        }

        observeOnOff(obs, loaderTop.current, On)
    }


    function handleObserverNode (entities, observer) {

        let anyIntersection = false

        let min = Number.MAX_SAFE_INTEGER

        for (const element of entities) {
            if (element.isIntersecting) {
                anyIntersection = true
                min = Math.min(min, element.target.id)
            }
        }

        if (anyIntersection) {
            searchParamsRef.current.set('offset', min)
            setSearchParams(searchParamsRef.current)
        }
    }


    function handleObserverBottom (entities) {
        if (entities[0].isIntersecting) {   
            observeBottom(false)

            const strFilters = StringTools.dictToQuery({
                limit:pos.current.limit,
                offset:pos.current.bottom,
                ...filtersRef.current}
            )
            const url = endpoint + ((strFilters.length > 0) ? '?' + strFilters : '')

            observeTop(false)
            reFetchBottom(url)
        }
    }


    function handleObserverTop (entities) {       
        if (entities[0].isIntersecting) {
            observeTop(false) 
            
            const newTop = Math.max(0, pos.current.top - pos.current.limit)
            const newLimit = pos.current.top - newTop

            if (newLimit === 0) {
                setTopReached(true)
            }
            else {
                setTopReached(false)
                
                const strFilters = StringTools.dictToQuery({
                    limit:newLimit,
                    offset:newTop,
                    ...filtersRef.current}
                )
                const url = endpoint + ((strFilters.length > 0) ? '?' + strFilters : '')

                observeBottom(false) 
                reFetchTop(url)
            }
        }
    }


    useLayoutEffect(() => {
        let ot = 0
        if (bunchRef.current.jump && bunchRef.current.i && nodeRefs.current.length>bunchRef.current.i && nodeRefs.current[bunchRef.current.i]) {
            ot = nodeRefs.current[bunchRef.current.i].offsetTop

            const dt = ot - bunchRef.current.offsetTop
            scrollerRef.current.scrollTop += dt

            bunchRef.current.jump = false
        }
        
        observeTop(true)
        observeBottom(true)

    // eslint-disable-next-line
    }, [list])


    useEffect(() => {
        // xl
        if (width >= 1200) {
            pos.current.limit = config.xl * config.fetchRows
            pos.current.maxElements = config.xl * config.maxRows
        }
        // lg
        else if (width >= 992) {
            pos.current.limit = config.lg * config.fetchRows
            pos.current.maxElements = config.lg * config.maxRows
        }
        // md
        else if (width >= 768) {
            pos.current.limit = config.md * config.fetchRows
            pos.current.maxElements = config.md * config.maxRows
        }
        // sm
        else if (width >= 576) {
            pos.current.limit = config.sm * config.fetchRows
            pos.current.maxElements = config.sm * config.maxRows
        }
        // xs
        else {
            pos.current.limit = config.xs * config.fetchRows
            pos.current.maxElements = config.xs * config.maxRows
        }
    // eslint-disable-next-line
    }, [width])


    useEffect(() => {
        if (isLoadingTop) {
            return
        }
        
        if (!responseDataTop.results) {
            return
        }

        pos.current.top -= responseDataTop.results.length

        const oldList = [...list]

        let newList = [...responseDataTop.results]
        if (list) {
            newList = [
                ...responseDataBottom.results,
                ...list,
            ]
        }
        
        const size = newList.length

        const maxSize = pos.current.maxElements //3 * pos.current.limit
        if (size > maxSize) {
            setBottomReached(false)
            pos.current.bottom -= size - maxSize
            newList = newList.slice(0, maxSize)
        }

        let found = false
        for (const [i, ov] of oldList.entries()) {
            for (const [j, nv] of newList.entries()) {
                if (ov === nv) {
                    bunchRef.current.i = j
                    bunchRef.current.offsetTop = nodeRefs.current[i].offsetTop
                    bunchRef.current.jump = true
                    found = true
                    break
                }
            }
            if (found) {
                break
            }
        }

        setList(newList)
    
    // eslint-disable-next-line
    }, [isLoadingTop, responseDataTop.results])
    
    
    useEffect(() => {
        if (isLoadingBottom) {
            return
        }
        
        if (!responseDataBottom.results) {
            return
        }

        pos.current.bottom += responseDataBottom.results.length

        if (responseDataBottom.results.length < pos.current.limit) {
            setBottomReached(true)
        }
        else {
            setBottomReached(false)
        }

        let newList = [...responseDataBottom.results]
        if (list) {
            newList = [
                ...list,
                ...responseDataBottom.results,
            ]
        }

        let size = newList.length
        const maxSize = pos.current.maxElements //3 * pos.current.limit
        while (size > maxSize) {
            setTopReached(false)
            pos.current.top += pos.current.limit
            newList = newList.slice(pos.current.limit)
            size = newList.length
        }

        setList(newList)
        
    // eslint-disable-next-line
    }, [isLoadingBottom, responseDataBottom.results])
        

    useEffect(() => {
        searchParamsRef.current = searchParams
    }, [searchParams])


    useEffect(() => {
        const stillFirstLoad = filtersRef.current === undefined

        if (areArraysEqual(filtersRef.current, filters)) {
            return
        }
        filtersRef.current = filters

        if (stillFirstLoad) {
            return
        }

        setList(undefined)
        setTopReached(false)
        setBottomReached(false)

        pos.current.top = 0
        pos.current.bottom = 0

        observeTop(true)
        observeBottom(true)

    // eslint-disable-next-line
    }, [filters])


    useEffect(() => {
        if (list) {
            setIsEmpty(offset === 0 && list.length === 0)
        }
    }, [offset, list])


    if (hasErrorTop || hasErrorBottom) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
            )
    }

    if (isEmpty) {
        return (
            <Container className="mt-5">
                <div ref={setLoaderTop} style={{display: topReached ? 'none' : 'block'}} id='Start'>
                    <Loading/>
                </div>
                {renderEmpty()}
                <div ref={setLoaderBottom} style={{display: bottomReached ? 'none' : 'block'}} id='End'>
                    <Loading/>
                </div>
            </Container>
        )
    }


    return (
        <div className="post-list">
            <Row className="text-center">
                <div ref={setLoaderTop} style={{display: topReached ? 'none' : 'block'}} id='Start'>
                    <Loading/>
                </div>
            </Row>

            <Row
                xl={config.xl}
                lg={config.lg}
                md={config.md}
                sm={config.sm}
                xs={config.xs}
            >
                {list && list.map((item, i) => {
                    return (                        
                        <Col key={getKey(item)}>
                            <div ref={(node) => setNodeRef(node, i)} id={pos.current.top + i}></div>
                            {renderItem(item)}
                        </Col>
                    )
                })}
            </Row>

            <Row className="text-center">
                <div ref={setLoaderBottom} style={{display: bottomReached ? 'none' : 'block'}} id='End'>
                    <Loading/>
                </div>
            </Row>
            {bottomReached && <Row className="text-center pb-5">
                <span>No more results</span>
            </Row>}
        </div>
    )
}

export default InfiniteScroll
