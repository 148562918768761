import React from "react"
import { Nav } from 'reactstrap'

import MenuCategory from "./menus/MenuCategory"



const NavCategory = ({filtername='filters'}) => {
    return (
        <div className='d-none d-md-block'>
            <Nav pills fill>
                <MenuCategory filtername={filtername} droppingDown={true}/>
            </Nav>
        </div>
    )
}

export default NavCategory
