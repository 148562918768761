import axios from 'axios'
import * as AuthRedux from '../reducers/AuthReducer'
import jwt_decode from "jwt-decode"
import { flushSync } from 'react-dom'
import * as Storage from '../tools/Storage'
import * as Endpoints from '../Endpoints'


axios.defaults.withCredentials = true

function authHeader() {
    return {
        headers: {
            Authorization: `Bearer ${Storage.getItem('access')}`
        }
    }
}


const viewAs = () => {
    const vae = Storage.getItem('viewAs')

    if (vae) {
        return {
            'viewAs': vae
        }
    }

    return {}
}


const addOrUpdateViewAsParameter = (url) => {
    const vae = Storage.getItem('viewAs')

    if (!vae) {
        return url
    }

    const urlObject = new URL(url)
    const queryParams = urlObject.searchParams

    const param = 'viewAs'
    
    if (queryParams.has(param)) {
        queryParams.set(param, vae)
    } else {
        queryParams.append(param, vae)
    }

    urlObject.search = queryParams.toString()
    
    return urlObject.toString()
}


let tokenInProgess = false
async function isTokenExpired(checkDemi=true) {
    while (tokenInProgess) {
        // console.debug('tokenInProgess')
        await new Promise(resolve => setTimeout(resolve, 100)) // Wait for a short time
    }

    try {
        // console.debug('isTokenExpired')
        const token = Storage.getItem('access')
        // console.debug(token)
        // const token = Storage.getItem('access')
        const decodedToken = jwt_decode(token)
        
        // JWT exp is in seconds
        const exp = decodedToken.exp * 1000
        const iat = decodedToken.iat * 1000
        
        const chekExp = checkDemi ? 0.5 * (iat + exp) : exp
        // const chekExp = iat + 10000
        
        const currentTime = new Date().getTime()
        const expired = chekExp < currentTime
        
        // console.debug(`${chekExp} - ${currentTime} = ${chekExp - currentTime}`)
        // console.debug(expired ? `expired ${chekExp - currentTime}` : 'not expired')
        return expired
    }
    catch {
        // console.debug('expired')

        return true
    }
}


function dispatchError(error, dispatch, defaultType=undefined) {
    let dispatchType = defaultType

    if ('response' in error && error.response)
    {
        if ('status' in error.response) {
            if (error.response.status === 401) {
                dispatchType = AuthRedux.LOGIN_FAIL
            }
        }
    }

    if (dispatchType !== undefined) {
        dispatch({ type: dispatchType })
    }

    throw error
}


let requestInProgess = false
export async function doRequest(dispatch, method, path, params={}, config={}) {
    // console.debug(`doRequest: ${path} - ${requestInProgess}`)

    while (requestInProgess) {
        await new Promise(resolve => setTimeout(resolve, 100)) // Wait for a short time
    }
    requestInProgess = true

    const fullconfig = {
        ...config,
        method,
        url: addOrUpdateViewAsParameter(process.env.REACT_APP_BACKURL + '/' + path),
        data: {...params, ...viewAs()},
    }

    try {
        const response = await axios(fullconfig)
        return response
    }
    catch(error) {
        dispatchError(error, dispatch)
    }
    finally {
        requestInProgess = false
    }
}


export async function request(dispatch, method, path, params={}) {
    // console.debug(`request: ${path}`)

    if (await isTokenExpired()) {
        try {
            await tokenRefresh(dispatch)
            await new Promise(r => setTimeout(r, 100)) // Wait for Dispatched Msg if needed
            
            if (await isTokenExpired()) {
                return doRequest(dispatch, method, path, params)
            }
            else {
                return authRequest(dispatch, method, path, params)
            }
        }
        catch {
            return doRequest(dispatch, method, path, params)
        }
    }

    return authRequest(dispatch, method, path, params)
}


export async function tokenObtainPair(dispatch, email, password, otp) {
    // console.debug('tokenObtainPair')

    try {
        const loginData = { email: email, password: password, otp: otp }
        const response = await request(dispatch, 'post', Endpoints.ACCOUNTS_TOKEN, loginData)
        flushSync(() => dispatch({ type: AuthRedux.TOKEN_SUCCESS, payload: response.data }))
    }
    catch(error) {
        dispatchError(error, dispatch, AuthRedux.LOGIN_FAIL)
    }
}


async function tokenRefresh(dispatch) {
    // console.debug(`tokenRefresh: ${tokenInProgess}`)
    tokenInProgess = true

    try {
        // const data = { refresh: Storage.getItem('refresh') }
        // const config = {withCredentials:true}
        // const response = await request(dispatch, 'post', Endpoints.ACCOUNTS_TOKEN_REFRESH)
        const response = await doRequest(dispatch, 'post', Endpoints.ACCOUNTS_TOKEN_REFRESH)
        dispatch({ type: AuthRedux.TOKEN_SUCCESS, payload: response.data })

        // console.debug(`new token: ${response.data.access}`)

        // const decodedToken = jwt_decode(response.data.access)
        // const currentTime = new Date().getTime()
        // const chekExp = decodedToken.iat * 1000
        // console.debug(`${chekExp} - ${currentTime} = ${chekExp - currentTime}`)

    }
    catch(error) {
        dispatchError(error, dispatch, AuthRedux.LOGIN_FAIL)
    }
    finally {
        tokenInProgess = false
    }
}


export async function authRequest(dispatch, method, path, params={}) {
    // console.debug(`authRequest: ${path}`)

    // const paramsHeader = {
    //     ...authHeader(),
    //     ...params
    // }

    if (await isTokenExpired()) {
        await tokenRefresh(dispatch)
        await new Promise(r => setTimeout(r, 100)) // Wait for Dispatched Msg if needed
    }

    return doRequest(dispatch, method, path, params, authHeader())
}
