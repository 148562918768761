import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { Button, NavItem } from "reactstrap"

import 'antd/dist/reset.css'

import ProductCellar from "../../components/ProductCellar"
import { AuthDispatchContext } from "../../App"
import { useDomainNavigate } from "../../tools/Subdomain"
import { LayoutContext } from "../../hocs/Layout"
import InfiniteScroll from "../../components/InfiniteScroll"

import * as Endpoints from '../../Endpoints'
import { EmptyCellar } from "../../components/Empty"
import { CellarName, CellarReplace } from "../../components/CellarReplace"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"


export const CellarMenu = () => {
    // const { slug } = useParams()
    const navigate = useDomainNavigate()
    
    async function onCheckout() {
        navigate(`/checkout`)
    }
    
    return (
        <NavItem key='Checkout'>
            <Button color='primary' onClick={onCheckout} className="ps-5 pe-5">
                Checkout
            </Button>
        </NavItem>
    )
}



const Cellar = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    
    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    const renderItem = (item) => {
        return <ProductCellar cellarProduct={item}/>
    }

    return (
        <>
            <SEO title={`${CellarName} - ${SiteName}`} />

            <div className='d-md-none d-block mb-3'>
                <h1><CellarReplace/></h1>
            </div>

            <InfiniteScroll
                scrollerRef={layoutContext.scroller}
                endpoint={Endpoints.CELLAR}
                // filters={{}}
                getKey={item => item.id}
                renderItem={renderItem}
                renderEmpty={EmptyCellar}
                auth={true}
                useCache={false}
            />

             {/* <Row>
                <Pagination
                    responsive={true}
                    total={responseData.count}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    defaultPageSize={limit}
                    defaultCurrent={1 + offset/limit}
                    pageSizeOptions={[8, 12, 16]}
                    onChange={onChange}
                />
            </Row>
            <Table>
                <thead>
                    <tr>
                        <th>
                        </th>
                        <th>
                        </th>
                        <th>
                            Product
                        </th>
                        <th>
                            Price
                        </th>
                        <th>
                            Creator
                        </th>
                        <th>
                            Notifications
                        </th>
                        <th>
                            Reminder
                        </th>
                        <th>
                            Reminder Date
                        </th>
                        <th>
                            Checkout
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {responseData.results.map((item, i) => {
                        return (
                            <ProductCellar key={i} cellarProduct={item} id={i}/>
                        )
                    })}
                </tbody>
            </Table> */}
        </>
    )
}

export default Cellar