import { Container, List } from "reactstrap"
import { SiteName } from "../../components/SiteTitle"
import { CloudURL } from "../../tools/Cloud"
import { SEO } from "../../components/SEO"



const TermsOfService = () => {
    return (<>
        <SEO title={`Terms Of Service - ${SiteName}`} />

        <Container style={{ maxWidth:960 }}>
            {/* <h1>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                {SiteName}
            </h1> */}
            <h2>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                Terms Of Service
            </h2>

            <p>Welcome to {SiteName}! These Terms of Service ("Terms") govern your access to and use of our platform and services. By using our website or any of its features, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using our services.</p>

            <h5>1. Definitions</h5>
            <List>
                <li>"FML" refers to {SiteName}, an online platform facilitating the buying and selling of digital products.</li>
                <li>"User(s)" refers to any individual or entity accessing and using the FML, including buyers, sellers and affiliates.</li>
                <li>"Digital Products" encompass any intangible, electronically delivered goods or services, such as software, e-books, digital art, templates, and other downloadable content.</li>
            </List>

            <h5>2. Registration and Account</h5>
            <List>
            <li>Users must create an account to access and use certain features of the FML.</li>
            <li>Users must provide accurate and up-to-date information during the registration process.</li>
            <li>Users are responsible for maintaining the confidentiality of their account credentials and any activities that occur under their account.</li>
            </List>

            <h5>3. Buying and Selling Digital Products</h5>
            <List>
            <li>Buyers may purchase digital products directly from sellers through the FML.</li>
            <li>Sellers are responsible for ensuring the accuracy and legality of the digital products they list for sale.</li>
            <li>Buyers are encouraged to review product details carefully before making a purchase.</li>
            </List>

            <h5>3.1. Affiliate Program</h5>
            <List>
            <li>Anyone registered on FairMarket.Land can become an Affiliate.</li>
            <li>To participate as an Affiliate, users must set up a Stripe Express account within their Profile page.</li>
            <li>Upon linking a Stripe Express account, users gain access to "Affiliate Links" displayed on eligible product pages. These links can be shared by the Affiliate.</li>
            <li>Affiliate members are entitled to a 15% commission on the creator's earnings for future purchases made by individuals who add the product to their collection via the Affiliate's shared link. This commission applies only if the creator has granted permission for their product to be part of the Affiliate program.</li>
            </List>

            <h5>4. Payment and Fees</h5>
            <List>
            <li>The FML charges sellers a fee of 10% of each sale's total amount.</li>
            <li>Additional Stripe fees apply to each sale transaction, and sellers are responsible for covering these fees.</li>
            <li>Another Stripe fee occurs for each monthly payout to users.</li>
            <li>The user's payout will take place on a monthly basis, provided that the payout amount has reached a minimum of $10 USD.</li>
            <li>The FML may update or modify the fees at any time, and users will be notified of such changes.</li>
            <li>The FML acts as the Merchant of Record, taking care of all transaction processing and payment collection for the products you list. Afterwards, we compensate you for your creative work, development, maintenance, and product support, based on the sales generated.</li>
            <li>Sellers are responsible for any taxes or fees applicable to their earnings from the sale of digital products.</li>
            </List>

            <h5>5. Intellectual Property</h5>
            <List>
            <li>Sellers must have the legal right to sell the digital products they list on the FML.</li>
            <li>Buyers are granted a limited, non-exclusive, non-transferable license to use purchased digital products for personal or commercial purposes, depending on the license terms set by the seller.</li>
            <li>Users shall not infringe upon any intellectual property rights while using the FML.</li>
            </List>

            <h5>6. Prohibited Conduct</h5>
            <List>
            <li>Users shall not engage in any unlawful, abusive, offensive, or fraudulent activities on the FML.</li>
            <li>Users shall not upload, transmit, or distribute any harmful or malicious content.</li>
            <li>Users shall not interfere with the platform's functionality or security.</li>
            </List>

            <h5>7. Content Moderation</h5>
            <List>
            <li>The FML reserves the right to moderate and remove any content that violates these Terms or may be deemed inappropriate.</li>
            <li>Users may report any suspicious or infringing content for review.</li>
            </List>

            <h5>8. Disclaimer of Warranties</h5>
            <List>
            <li>The FML provides its services "as is" without any warranties or guarantees.</li>
            <li>The platform does not guarantee the accuracy, reliability, or availability of any digital products listed on the site.</li>
            </List>

            <h5>9. Limitation of Liability</h5>
            <List>
            <li>The FML shall not be liable for any direct, indirect, incidental, or consequential damages resulting from the use of the platform or any digital products purchased through it.</li>
            </List>

            <h5>10. Termination</h5>
            <List>
            <li>The FML may terminate or suspend user accounts at its discretion, without prior notice, for any violations of these Terms.</li>
            </List>

            <h5>11. Changes to the Terms</h5>
            <List>
            <li>The FML reserves the right to modify or update these Terms at any time. Users will be notified of significant changes.</li>
            </List>

            <h5>12. Contact Information</h5>
            <List>
            <li>For any questions or concerns regarding these Terms or the FML, please contact <a target="_blank" rel="noreferrer" href={`mailto:support@${process.env.REACT_APP_FRONT_DOMAIN}`}>support@{process.env.REACT_APP_FRONT_DOMAIN}</a>.</li>
            </List>

            <p>By using {SiteName}, you acknowledge that you have read, understood, and agreed to these Terms. Thank you for being a part of our community! Happy buying and selling!</p>
        </Container>
    </>)
}

export default TermsOfService