import { Link } from "react-router-dom"
import { Container } from "reactstrap"
import SiteTitle, { SiteSocialNetworks } from "./SiteTitle"



const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className="footer pb-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave" preserveAspectRatio='none'>
                <path className="second"
                    d="M 0,400 C 0,400 0,133 0,133 C 120.10526315789474,161.5263157894737 240.21052631578948,190.05263157894737 324,176 C 407.7894736842105,161.94736842105263 455.2631578947369,105.3157894736842 547,99 C 638.7368421052631,92.6842105263158 774.7368421052631,136.6842105263158 883,149 C 991.2631578947369,161.3157894736842 1071.7894736842106,141.94736842105263 1160,134 C 1248.2105263157894,126.05263157894737 1344.1052631578946,129.5263157894737 1440,133 C 1440,133 1440,400 1440,400 Z"
                    transform="rotate(-180 720 200)"
                ></path>
                <path className="first"
                    d="M 0,400 C 0,400 0,266 0,266 C 102.29665071770336,262.1244019138756 204.59330143540672,258.2488038277512 297,263 C 389.4066985645933,267.7511961722488 471.92344497607655,281.12918660287085 557,272 C 642.0765550239234,262.87081339712915 729.712918660287,231.23444976076559 836,222 C 942.287081339713,212.76555023923441 1067.2248803827752,225.93301435406698 1171,237 C 1274.7751196172248,248.06698564593302 1357.3875598086124,257.03349282296654 1440,266 C 1440,266 1440,400 1440,400 Z"
                    transform="rotate(-180 720 200)"
                ></path>
            </svg>

            <Container className="text-center">
                <p><b>
                    <SiteTitle/>
                </b></p>
                <p>
                    <SiteSocialNetworks/>
                </p>
                <small>
                    &copy; {currentYear} iwHiteRabbiT LLC, All Right Reserved
                </small>
                <br/>
                <small>
                    <Link to='/legal/privacypolicy'>Privacy Policy</Link>, <Link to='/legal/termsofservice'>Terms Of Service</Link>
                </small>
            </Container>
        </footer>
    )
}


export default Footer