import { CloudURL } from "../tools/Cloud"
import { CellarReplace } from "./CellarReplace"

const EmptySuggestOwnProduct = () => {
    return (<div className="text-center">
        <img alt='' src={CloudURL('empty.webp')} className="mb-2" style={{height:'15em'}}/>
        <h4>Be the first to suggest your own product!</h4>
    </div>
    )
}



export const EmptySearch = () => {
    return (<div className="text-center">
        <img alt='' src={CloudURL('empty.webp')} className="mb-2" style={{height:'15em'}}/>
        <h4>Be the first to suggest your own product!</h4>
        {/* <h4>No product matching your criteria were found.</h4> */}
    </div>
    )
}



export const EmptyCellar = () => {
    return (<div className="text-center">
        <img alt='' src={CloudURL('empty.webp')} className="mb-2" style={{height:'15em'}}/>
        <h4>No product in your <CellarReplace/>.</h4>
    </div>
    )
}



export const EmptyLibrary = () => {
    return (<div className="text-center">
        <img alt='' src={CloudURL('empty.webp')} className="mb-2" style={{height:'15em'}}/>
        <h4>No product in your Library.</h4>
    </div>
    )
}



export const EmptyStore = () => {
    return (<div className="text-center">
        <img alt='' src={CloudURL('empty.webp')} className="mb-2" style={{height:'15em'}}/>
        <h4>No product in your Store.</h4>
    </div>
    )
}



export const Empty = ({msg=''}) => {
    return (<div className="text-center">
        <img alt='' src={CloudURL('empty.webp')} className="mb-2" style={{height:'15em'}}/>
        {msg && <h4>{msg}</h4>}
    </div>)
}



export default EmptySuggestOwnProduct