import React, { useContext, useEffect, useState } from "react";
import { AuthDispatchContext } from '../App'
import { CloudURL, PrefixENV } from "../tools/Cloud";
import { domains, useDomainNavigate } from "../tools/Subdomain";
import { Button } from "reactstrap";



export const SiteName = 'FairMarket.Land'

export const BGByDomain = (domain) => {
    // https://colorkit.co/background-maker/ff999a-53f489/

    const d = domain.toLowerCase()

    switch(d) {
        case "blender":
            return {useBG:true, bg:'bgs/colorkit_01.webp'}

        case "unreal":
            return {useBG:true, bg:'bgs/colorkit_04.webp'}

        case "godot":
            return {useBG:true, bg:'bgs/colorkit_07.webp'}

        case "indiegames":
            return {useBG:true, bg:'bgs/colorkit_00.webp'}

        case "houdini":
            return {useBG:true, bg:'bgs/colorkit_08.webp'}

        case "3dsmax":
            return {useBG:true, bg:'bgs/colorkit_05.webp'}
    
        case "maya":
            return {useBG:true, bg:'bgs/colorkit_06.webp'}

        case "books":
            return {useBG:true, bg:'bgs/colorkit_03.webp'}
    
        case "3dmodels":
            return {useBG:true, bg:'bgs/colorkit_09.webp'}

        case "fiberart":
            return {useBG:true, bg:'bgs/colorkit_10.webp'}
            
        default:
            return {useBG:false, bg:''}
    }
}



export const ButtonBG = ({label, padding='1.7em', fontSize='larger'}) => {    
    const navigate = useDomainNavigate()
    const url = label.toLowerCase().replace(/\s+/g, '')
    const backgroundImage = CloudURL(BGByDomain(url).bg)

    const buttonStyle = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // width: '200px',
        // height: '50px',
        // Add any additional styles as needed
        padding: padding,
    }

    return (
        <Button color="light" block size="lg"
            onClick={() => navigate('', url)}
            style={buttonStyle}
            className='text-start'
            // className={`text-start p-${padding}`}
        >
            {/* <span style={{ letterSpacing: 2, fontSize: 'larger', fontWeight: '500' }}> */}
            <span style={{ letterSpacing: 2, fontSize: fontSize, fontWeight: '500' }}>
                {label}
            </span>
        </Button>
    )
}



export const SiteSocialNetworks = () => {
    return (
        <span style={{fontSize:'larger'}}>
            <a href={`https://twitter.com/FairMarketLand`} rel="noreferrer" target="_blank" className="fa-brands fa-x-twitter"><i hidden="true">&nbsp;</i></a>
            &nbsp;
            <a href={`https://www.facebook.com/fairmarketland`} rel="noreferrer" target="_blank" className="fa-brands fa-facebook-f"><i hidden="true">&nbsp;</i></a>
        </span>
    )
}



const SiteTitle = ({offCanvas=false, showTitle=true}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const bgType = BGByDomain(authDispatchContext.domain)

    const [domain, setDomain] = useState('')

    const prefix = PrefixENV()

    
    useEffect(() => {
        if (domains.includes(authDispatchContext.domain)) {
            setDomain(authDispatchContext.domain.toUpperCase())
        }
        else {
            setDomain('')
        }

    }, [authDispatchContext.domain])


    // useEffect(() => {
    //     document.title = prefix + domain + ' ' + SiteName
    // // eslint-disable-next-line
    // }, [domain])

    const bg = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3)), url(${CloudURL(bgType.bg)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // width: '200px',
        // height: '50px',
        // Add any additional styles as needed
        padding: '0.2em'
    }

    let styl = { letterSpacing: 2 }

    // if (offCanvas) {
    //     styl = { letterSpacing: 2, fontSize: '0.75em' }
    // }

    return (
        <span style={styl}>
            {false && prefix && <span className="alert-danger" style={{padding:'0.9em'}}>{prefix}</span>}
            <span style={bgType.useBG ? bg : {}}>{domain}</span>
            {/* {offCanvas && <br/>} */}
            {showTitle && <b style={{padding: '0.2em'}}>{SiteName}</b>}
            <img
                alt=''
                src={CloudURL('logo64.webp')}
                style={{verticalAlign:'text-bottom', height:'1.4em'}}
                className="mx-2"
            />
        </span>
    )
}

export default SiteTitle