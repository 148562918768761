import React, { useContext } from "react"
import { AuthDispatchContext } from "../../App"
import * as Endpoints from '../../Endpoints'
import { useRequest } from "../../hooks/Hooks"
import Loading from "../../components/Loading"



const Onboard = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const code = new URLSearchParams(window.location.search).get('code')

    const { isLoading, hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.STRIPE_ONBOARD}`, {auth:true, method:'post', data:{'auth':code}})

    if (!code) {
        return <span>Link</span>
    }

    if (isLoading) {
        return <Loading/>
    }

    if (hasError) {
        return <span>Error!</span>
    }

    // if (!authDispatchContext.state.isAuthenticated) {
    //     return <Navigate to='/' />
    // }

    return (
        <span>Done</span>
    )
}


export default Onboard