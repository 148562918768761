import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { Container, Table } from "reactstrap"
import { AuthDispatchContext } from "../../App"
import { useRequest } from "../../hooks/Hooks"
import ProductCheckout from "./ProductCheckout"
import { StripeSetup } from "./StripeElement"
import MenuCountry from "../../components/menus/MenuCountry"
import * as Endpoints from '../../Endpoints'
import { useDomainNavigate } from "../../tools/Subdomain"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"


const Checkout = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, Endpoints.CHECKOUT, { auth:true })

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    if (hasError || isLoading || !responseData)
        return (<div></div>)


    if (responseData.length === 0) {
        navigate('/cellar')
        return (<div></div>)
    }

    return (<>
        <SEO title={`Checkout - ${SiteName}`} />
        
        <Container style={{ maxWidth:696 }} className="mt-5 mb-5">
            <h2>Order Summary</h2>
            <Table>
                <thead>
                    <tr>
                        <th>
                            {/* Image */}
                        </th>
                        <th>
                            Product
                        </th>
                        <th>
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {responseData && responseData.map((item, i) => {
                        return (
                            <ProductCheckout key={item.name} product={item}/>
                            )
                        })}
                </tbody>
            </Table>

            <h3 className="mt-5">Payment Details <MenuCountry/></h3>
            <StripeSetup/>
        </Container>
    </>)
}


export default Checkout