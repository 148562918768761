import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

import * as Auth from '../../actions/AuthActions'
import { AuthDispatchContext } from '../../App'
import { useDomainNavigate } from '../../tools/Subdomain'
import { useState } from 'react'
import { LayoutContext } from '../../hocs/Layout'
import * as Request from '../../actions/Request'

import * as Endpoints from '../../Endpoints'
import { SEO } from '../../components/SEO'
import { SiteName } from '../../components/SiteTitle'



const Login = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const navigate = useDomainNavigate()

    const [errMsg, setErrMsg] = useState()
    const [showOTP, setShowOTP] = useState(false)
    const [resendBtn, setResendBtn] = useState(false)
    const [modalForgotPWD, setModalDelStripe] = useState(false)

    const [formValue, setformValue] = React.useState({
        email: '',
        password: '',
        otp: ''
    })    
    
    const toggleForgotPWD = () => setModalDelStripe(!modalForgotPWD)

    if (authDispatchContext.state.isAuthenticated) {
        authDispatchContext.navigateBack(navigate)
        // navigate(-1)
        // return <Navigate to='/' />
    }


    const handleSubmit = async(event) => {
        event.preventDefault()
        
        setErrMsg(undefined)
        setResendBtn(false)

        Auth.login(
            authDispatchContext.dispatch,
            formValue.email,
            formValue.password,
            formValue.otp,
            (error) => {
                if (error.response.status === 401) {
                    setErrMsg('No active account found with the given credentials')
                }
                if (error.response.status === 412) {
                    setErrMsg('OTP required')
                    setShowOTP(true)
                }
                if (error.response.status === 417) {
                    setErrMsg(error.response.data.detail)
                    setResendBtn(true)
                }
                if (error.response.status === 400) {
                    setErrMsg(error.response.data.non_field_errors[0])
                }
            })
    }


    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        })
    }


    const handleChangePWD = async(event) => {
        event.preventDefault()
        Request.request(authDispatchContext.dispatch, 'post', Endpoints.ACCOUNTS_EMAIL_RESET, { email:formValue.email })
        setModalDelStripe(false)
        layoutContext.setNotifContent({title:'Reset Password', message:'Check your email for password reset instructions.', notimer:true})
    }


    const handleResend = async(event) => {
        event.preventDefault()
        setModalDelStripe(false)

        try {
            await Request.request(authDispatchContext.dispatch, 'post', Endpoints.ACCOUNTS_PROFILE_RESEND, { email:formValue.email })
            layoutContext.setNotifContent({title:'Verification Email Sent', message:"We've just sent you a verification email. To complete the process, kindly click on the link provided within the email to confirm your email address. If you haven't received the email, please take a moment to check your spam folder. If you still can't find it, feel free to reach out to us for assistance.", notimer:true})
        }
        catch(error) {
            layoutContext.setNotifContent({title:'Cannot send a verification email', message:"We are unable to send an email to this address at the moment, or the maximum rate limit has been reached. Please consider trying again later or reaching out to us for further assistance.", notimer:true})
        }        
    }


    return (<>
        <SEO title={`Log In - ${SiteName}`} />
        <Container className='mt-5' style={{ maxWidth:696 }}>
            <h1>Sign In</h1>
            <p>Sign into your Account</p>
            <Form onSubmit={handleSubmit}>
                {errMsg && <Alert color="danger">
                    <Row>
                        <Col className='m-auto'>
                            {errMsg}
                        </Col>

                        <Col className='text-end'>
                            {resendBtn && <Button color="primary" onClick={handleResend} size="sm" outline>
                                Resend Verification Email
                            </Button>}
                        </Col>
                    </Row>
                </Alert>}

                {/* <div>{errEmail && <span>{errEmail}</span>}</div>
                <div>{errPassword && <span>{errPassword}</span>}</div> */}
                <div className='form-group'>
                    <Label>Email</Label>
                    <Input
                        className='form-control'
                        type="email"
                        name="email"
                        placeholder="enter an email"
                        value={formValue.email}
                        onChange={handleChange}
                        autoComplete="email"
                        required
                    />
                    {/* <Input
                        className='form-control'
                        type="text"
                        name="username"
                        placeholder="enter your username"
                        value={formValue.username}
                        onChange={handleChange}
                        required
                    /> */}
                    <Label>Password</Label>
                    <Input
                        className='form-control'
                        type="password"
                        name="password"
                        placeholder="enter your password"
                        value={formValue.password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        required
                    />
                    {showOTP && <>
                        <Label>OTP</Label>
                        <Input
                            className='form-control'
                            type="text"
                            name="otp"
                            placeholder="otp code"
                            value={formValue.otp}
                            onChange={handleChange}
                            autoComplete="one-time-code"
                        />
                    </>}
                    <Button color='primary' type="submit">
                        Login
                    </Button>
                </div>
            </Form>
            <p className='mt-3'>
                Don't have an account? <Link to='/signup'>Sign Up</Link>
            </p>

            <p className='mt-3'>
                <Link onClick={toggleForgotPWD}>Forgot your password?</Link>
            </p>

            <Modal isOpen={modalForgotPWD} toggle={toggleForgotPWD}>
                <ModalHeader toggle={toggleForgotPWD}>Password Forgotten</ModalHeader>
                <Form onSubmit={handleChangePWD}>
                    <ModalBody>
                        Please enter your email:
                        <Input
                            className='form-control'
                            type="email"
                            name="email"
                            placeholder="enter an email"
                            value={formValue.email}
                            onChange={handleChange}
                            autoComplete="email"
                            required
                            valid={false}
                            invalid={false}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" type='submit'>
                            Reset Password
                        </Button>
                        <Button onClick={toggleForgotPWD}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Container>
    </>)
}

export default Login