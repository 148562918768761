import React, { useContext } from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap'

import { AuthDispatchContext } from '../../App'
import * as Auth from '../../actions/AuthActions'
import { useDomainNavigate } from "../../tools/Subdomain"
import { CloudURL } from "../../tools/Cloud"
import { CellarDoorReplace } from "../CellarReplace"



const MenuProfileRows = ({isAuth, onProfile, onCellar, onStore, onLogin, onSignup, onLogout, vertical}) => {
    const rows = {}

    if (isAuth) {
        rows['Cellar'] = ['', <NavLink href='#' onClick={onCellar}><CellarDoorReplace/></NavLink>]
        rows['Profile'] = ['', <NavLink href='#' onClick={onProfile}>Profile</NavLink>]
        rows['Store'] = ['', <NavLink href='#' onClick={onStore}>MyStore</NavLink>]
        rows['Logout'] = ['ms-auto me-1 text-end', <span onClick={onLogout} className="btn btn-secondary">Logout</span>]
    }
    else {
        rows['Signup'] = ['', <NavLink href='#' onClick={onSignup}>Sign Up</NavLink>]
        rows['LoginNoBG'] = ['ms-auto me-1 text-end', <span onClick={onLogin} className="btn btn-primary">Log In</span>]
    }


    const colorBG = (name, cname) => {
        if (window.location.pathname.startsWith("/" + name.toLowerCase())) {
            return cname + ' bg-current-menu'
        }

        return cname
    }


    if (vertical) {
        return <div className="mt-2">
            <h6>Account</h6>
            {Object.entries(rows) && Object.entries(rows).map(([k, v], i) => 
                <NavItem key={k} className={colorBG(k, v[0])}>
                    {v[1]}
                </NavItem>
            )}
        </div>
    }

    return (
        <UncontrolledDropdown nav>
            <DropdownToggle nav caret>
                <img alt='' src={CloudURL('profile.webp')} height={24}/>
            </DropdownToggle>
            <DropdownMenu>
                {Object.entries(rows) && Object.entries(rows).map(([k, v], i) => 
                    <DropdownItem key={k} className={colorBG(k, v[0])}>
                        {v[1]}
                    </DropdownItem>
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}



const MenuProfileDoc = ({onDoc=()=>{}, showTitle=false}) => {
    return (<>
        {showTitle && <h6>Doc</h6>}

        <NavItem key='Doc'>
            <NavLink href='#' onClick={onDoc}>
            Doc
            </NavLink>
        </NavItem>
    </>)
}



const MenuProfile = ({closeOffCanvas=()=>{}, vertical=false}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const onLogin = () => { closeOffCanvas(); authDispatchContext.navigateRedirect(navigate, `/login`) }
    const onSignup = () => { closeOffCanvas(); authDispatchContext.navigateRedirect(navigate, `/signup`) }
    const onProfile = () => { closeOffCanvas(); navigate(`/profile`) }
    const onCellarDoor = () => { closeOffCanvas(); navigate(`/cellar`) }
    const onStore = () => { closeOffCanvas(); navigate(`/store`) }
    const onLogout = () => { closeOffCanvas(); Auth.logout(authDispatchContext.dispatch) }

    const onDoc = () => { closeOffCanvas(); navigate(`/doc`) }

    return <>
        {authDispatchContext.state.isAuthenticated && authDispatchContext.state.user.doc && <MenuProfileDoc onDoc={onDoc} showTitle={vertical}/>}
        <MenuProfileRows
            isAuth={authDispatchContext.state.isAuthenticated}
            onProfile={onProfile} onCellar={onCellarDoor} onStore={onStore} onLogout={onLogout}
            onLogin={onLogin} onSignup={onSignup}
            vertical={vertical}
        />
    </>
}


export default MenuProfile