import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { SiteName } from './SiteTitle';
import { useContext } from 'react';
import { AuthDispatchContext } from '../App';
import { Badge } from 'reactstrap';
import { featAffiliates } from '../containers/store/Store';
import { LayoutContext } from '../hocs/Layout';



const twitterAccount = '@FairMarketLand'
const facebookAccount = 'facebook.com/fairmarketland'



export const Share = ({owner='', shareUrl=`https://${process.env.REACT_APP_FRONT_DOMAIN}`, title}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const idhash = authDispatchContext.state.isAuthenticated ? authDispatchContext.state.user.idhash : ''

    const usehash = featAffiliates && idhash && owner && owner !== idhash
    const affshareUrl = usehash ? `${shareUrl}?a=${idhash}` : shareUrl


    const shareNatif = (event) => {
        event.preventDefault()

        const shareData = {
            title: title,
            text: SiteName,
            url: affshareUrl,
        }

        if (navigator.share) {
            navigator.share(shareData)
        }
    }


    const handleCopyLink = async (event) => {
        event.preventDefault()
        
        try {
            await navigator.clipboard.writeText(affshareUrl)
            layoutContext.setNotifContent({message:'Copied to clipboard!'})
        }
        catch (err) {
            layoutContext.setNotifContent({message:'Failed to copy to clipboard'})
        }
    }
    
    
    return (
        <>
            {usehash && <Badge color="warning">Affiliate Links</Badge>}
            <a href={affshareUrl} onClick={handleCopyLink} target='_blank' rel='noreferrer' className="fa-solid fa-link"><i hidden="true">&nbsp;</i></a>

            <TwitterShareButton url={affshareUrl} title={`${title} - ${twitterAccount}`} hashtags={['fairmarket', title.replace(/\s+/g, '')]}>
                <span className="fa-brands fa-x-twitter"></span>
            </TwitterShareButton>

            <FacebookShareButton url={affshareUrl} hashtag={'#fairmarketland'} quote={`${title} - ${facebookAccount}`}>
                <span className="fa-brands fa-facebook-f"></span>
            </FacebookShareButton>

            {/* <LinkedinShareButton url={affshareUrl}> */}
            <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${affshareUrl}`}
                rel="noreferrer" target='_blank'
                onClick={() => {
                    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
                    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

                    const w = 550
                    const h = 400

                    const systemZoom = width / window.screen.availWidth;
                    const left = (width - w) / 2 / systemZoom
                    const top = (height - h) / 2 / systemZoom

                    window.open(
                        `https://www.linkedin.com/sharing/share-offsite/?url=${affshareUrl}`,
                        "_blank",
                        `noreferrer, resizable=yes, scrollbars=yes, titlebar=yes, width=${w}, height=${h}, left=${left}, top=${top}, chrome=yes`
                    )
                }}
            >
                <span className="fa-brands fa-linkedin"></span>
            </a>
            {/* </LinkedinShareButton> */}

            {/* <PinterestShareButton url={affshareUrl} media={`${affshareUrl}/image.jpg`} description={title}>
                <span className="fa-brands fa-pinterest"></span>
            </PinterestShareButton> */}

            <WhatsappShareButton url={affshareUrl} title={title}>
                <span className="fa-brands fa-whatsapp"></span>
            </WhatsappShareButton>

            {navigator.share && <Link href="#" onClick={shareNatif} className="fa-solid fa-share-nodes"><i hidden="true">&nbsp;</i></Link>}
        </>
    )
}