import React from "react"
import { NavItem, NavLink } from 'reactstrap'

import { useDomainNavigate } from "../../tools/Subdomain"
import { CellarDoorReplace, CellarReplace } from "../CellarReplace"


const MenuCellar = ({showTitle=false, closeOffCanvas=()=>{}}) => {
    const navigate = useDomainNavigate()

    // const onHome = () =>  { closeOffCanvas(); navigate(`dashboard`) }
    const onCellar = () => { closeOffCanvas(); navigate(`/cellar`) }
    const onLibrary = () => { closeOffCanvas(); navigate(`library`) }

    return (
        <>
        {showTitle && <h6><CellarDoorReplace/></h6>}
        <NavItem>
            <NavLink href='#' onClick={onCellar} className = { window.location.pathname === "/cellar" ? 'active' : '' }>
                <CellarReplace/>
            </NavLink>
        </NavItem>

        <NavItem>
            <NavLink href='#' onClick={onLibrary} className = { window.location.pathname === "/cellar/library" ? 'active' : '' }>
                Library
            </NavLink>
        </NavItem>
        </>
    )
}

export default MenuCellar;
