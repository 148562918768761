import { useState, useEffect, useRef, useCallback } from 'react'
import * as Request from '../actions/Request'



const requestByQuery = {}


const pushRequest = (query,
    auth=false, method='get', data={}, firstCall,
    setResponseData=()=>{},
    setIsLoading=()=>{},
    setHasError=()=>{},
    cached=true) => {

    if (query in requestByQuery) {
        const { params, setResponseDatas, setIsLoadings, setHasErrors } = requestByQuery[query]
        if (params['auth'] === auth &&
            params['method'] === method &&
            compareDictionaries(params['data'], data) &&
            params['firstCall'] === firstCall) {
                if (!(setResponseData in setResponseDatas)) {
                    setResponseDatas.push(setResponseData)
                }
                if (!(setIsLoading in setIsLoadings)) {
                    setIsLoadings.push(setIsLoading)
                }
                if (!(setHasError in setHasErrors)) {
                    setHasErrors.push(setHasError)
                }

                return requestByQuery[query]
        }
    }

    requestByQuery[query] = {
        'params': {
            'auth': auth,
            'method': method,
            'data': data,
            'firstCall': firstCall
        },
        'setResponseDatas':[setResponseData],
        'setIsLoadings':[setIsLoading],
        'setHasErrors':[setHasError],
        'requested':[false],
        'cached':[cached],
        'cachedData':[undefined],
        'cachedError':[false]
    }

    return requestByQuery[query]
}


const popRequest = (query,
    auth=false, method='get', data={}, firstCall) => {
    if (query in requestByQuery) {
        const { params } = requestByQuery[query]
        if (params['auth'] === auth &&
            params['method'] === method &&
            compareDictionaries(params['data'], data) &&
            params['firstCall'] === firstCall) {
                delete requestByQuery[query]
        }
    }
}


const compareDictionaries = ( d1, d2 ) => {
    if( d1 === d2 )
        return true

    if( d1 == null || d2 == null )
        return false

    var count = 0
    for( var key in d1 )
    {
        if( !( key in d2 ) )
            return false

        if( d1[key] !== d2[key] )
            return false

        count++
    }

    var count2 = 0
    for( key in d2 )
        count2++

    return ( count === count2 )
}


export function useRequest(dispatch, query, {auth=false, method='get', data={}, firstCall=true, useCache=false}={}) {
    const [responseData, setResponseData] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false)

    
    async function fetch(dispatch, method, query, data) {
        const { setResponseDatas, setIsLoadings, setHasErrors, requested, cached, cachedData, cachedError } = pushRequest(query, auth, method, data, firstCall, setResponseData, setIsLoading, setHasError, useCache)

        if (requested[0] && useCache) {
            if (cached[0] && cachedData[0]) {
                setResponseData(cachedData[0])
                setIsLoading(false)
                setHasError(cachedError[0])
                return
            }
            if (cached[0] && cachedError[0]) {
                setResponseData('')
                setIsLoading(false)
                setHasError(true)
            }
        }
        requested[0] = true


        for (var setIsLoading0 of setIsLoadings) {
            setIsLoading0(true)
        }
        for (var setHasError0 of setHasErrors) {
            setHasError0(false)
        }

        try {
            if (auth) {
                const response = await Request.authRequest(dispatch, method, query, data)
                for (var setResponseData00 of setResponseDatas) {
                    setResponseData00(response.data)
                }

                if (cached[0]) {
                    cachedData[0] = response.data
                }
            }
            else {
                const response = await Request.request(dispatch, method, query, data)
                for (var setResponseData01 of setResponseDatas) {
                    setResponseData01(response.data)
                }

                if (cached[0]) {
                    cachedData[0] = response.data
                }
            }
        }
        catch(error) {
            console.log(error)
            for (var setResponseData0 of setResponseDatas) {
                setResponseData0('')
            }

            for (var setHasError00 of setHasErrors) {
                setHasError00(true)
            }
            cachedError[0] = true
        }
    
        for (var setIsLoading00 of setIsLoadings) {
            setIsLoading00(false)
        }

        setResponseDatas.length = 0
        setIsLoadings.length = 0
        setHasErrors.length = 0

        if (!cached[0]) {
            popRequest(query, auth, method, data, firstCall)
        }
    }

    const reFetch = (newQuery=undefined, newData=undefined) => {
        if (newQuery)
            query = newQuery

        if (newData)
            data = newData
            
        fetch(dispatch, method, query, data)
    }

    useEffect(() => {
        if (firstCall) {
            reFetch()
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    return { responseData:responseData, isLoading:isLoading, hasError:hasError, reFetch:reFetch }
}



export function useHookWithRefCallback(cb= () => {}) {
    const ref = useRef(null)
    const setRef = useCallback(node => {
        if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
        }

        if (node) {
            // Check if a node is actually passed. Otherwise node would be null.
            // You can now do what you need to, addEventListeners, measure, etc.
        }

        // Save a reference to the node
        ref.current = node

        cb()
    // eslint-disable-next-line 
    }, [])
    
    return [ref, setRef]
}



export function useWindowDimensions() {
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window
        return {
            width,
            height
        }
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }
  
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
  
    return windowDimensions
}
  