import React, { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { Alert, Button, Card, CardBody, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, NavLink } from "reactstrap"
import * as Request from "../../actions/Request"

import { AuthDispatchContext } from '../../App'
import Loading from "../../components/Loading"
import { useRequest } from "../../hooks/Hooks"
import { LayoutContext } from "../../hocs/Layout"

import * as Endpoints from '../../Endpoints'
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"


const Profile = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    const [stripeLink, setStripeLink] = useState(false)
    const [modalDelStripe, setModalDelStripe] = useState(false)
    const [freezeButton, setFreezeButton] = useState(false)

    const [errUsername, setErrUsername] = useState()
    const [errEmail, setErrEmail] = useState()
    const [errTwitter, setErrTwitter] = useState()
    const [errFacebook, setErrFacebook] = useState()
    const [errNewsletter, setErrNewsletter] = useState()
    const [errPassword, setErrPassword] = useState()

    const [validUsername, setValidUsername] = useState(true)
    const [validEmail, setValidEmail] = useState(true)
    const [validTwitter, setValidTwitter] = useState(true)
    const [validFacebook, setValidFacebook] = useState(true)
    const [validNewsletter, setValidNewsletter] = useState(true)

    const [password2, setPassword2] = useState()
    const [errPassword2, setErrPassword2] = useState()

    const [timeoutId, setTimeoutID] = useState()

    const { responseData:responseDataExpress, isLoading:isLoadingExpress, hasError:hasErrorExpress, reFetch:reFetchExpress } = useRequest(authDispatchContext.dispatch, Endpoints.STRIPE_STRIPELINK, {auth:true, method:'post'})

    const [formValue, setformValue] = React.useState({
        username: authDispatchContext.state.user ? authDispatchContext.state.user.username : '',
        email: authDispatchContext.state.user ? authDispatchContext.state.user.email : '',
        password: '',
        newsletter: authDispatchContext.state.user ? authDispatchContext.state.user.newsletter : false,
        twitter: authDispatchContext.state.user ? authDispatchContext.state.user.twitter : false,
        facebook: authDispatchContext.state.user ? authDispatchContext.state.user.facebook : false,
    })    

    const toggleDelStripe = () => setModalDelStripe(!modalDelStripe);

    useEffect(() => {
        if (!isLoadingExpress && !hasErrorExpress && responseDataExpress.hasStripe)
            setStripeLink(responseDataExpress.link)
        else
            setStripeLink('')

    }, [responseDataExpress, isLoadingExpress, hasErrorExpress])


    useEffect(() => {
        if ((!formValue.password && !password2) || formValue.password === password2)
        {
            setErrPassword2('')
        }
        else {
            setErrPassword2("Passwords don't match!")
        }
    }, [formValue.password, password2])


    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        setErrPassword(undefined)

        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.ACCOUNTS_PROFILE_PWD, {value:formValue.password})

            layoutContext.setNotifContent({message:`Password changed!`})
        }
        catch (error) {
            if ('password' in error.response.data){
                setErrPassword(error.response.data['password'])
            }
        }
    }

    const handleChange = async (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        })

        if (['username', 'email', 'twitter', 'facebook', 'newsletter'].includes(event.target.name)) {

            if (event.target.name === 'username') {
                setValidUsername(false)
            }
            else if (event.target.name === 'email') {
                setValidEmail(false)
            }
            else if (event.target.name === 'twitter') {
                setValidTwitter(false)
            }
            else if (event.target.name === 'facebook') {
                setValidFacebook(false)
            }
            else if (event.target.name === 'newsletter') {
                setValidNewsletter(false)
            }

            if (timeoutId) {
                clearTimeout(timeoutId)
                setTimeoutID(undefined)
            }

            setTimeoutID(setTimeout(async() => {
                setTimeoutID(undefined)

                setErrUsername(undefined)
                setErrEmail(undefined)
                setErrTwitter(undefined)
                setErrFacebook(undefined)
                setErrNewsletter(undefined)

                try {
                    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
                    await Request.authRequest(authDispatchContext.dispatch, 'post', `${Endpoints.ACCOUNTS_PROFILE}${event.target.name}/`, {value:value})
        
                    // layoutContext.setNotifContent({message:`${event.target.name} saved!`})
                    if (event.target.name === 'username') {
                        setValidUsername(true)
                    }
                    else if (event.target.name === 'email') {
                        setValidEmail(true)
                    }
                    else if (event.target.name === 'twitter') {
                        setValidTwitter(true)
                    }
                    else if (event.target.name === 'facebook') {
                        setValidFacebook(true)
                    }
                    else if (event.target.name === 'newsletter') {
                        setValidNewsletter(true)
                    }
        
                }
                catch (error) {
                    if ('username' in error.response.data){
                        setErrUsername(error.response.data['username'])
                    }
                    if ('email' in error.response.data){
                        setErrEmail(error.response.data['email'])
                    }
                    if ('twitter' in error.response.data){
                        setErrTwitter(error.response.data['twitter'])
                    }
                    if ('facebook' in error.response.data){
                        setErrFacebook(error.response.data['facebook'])
                    }
                    if ('newsletter' in error.response.data){
                        setErrNewsletter(error.response.data['newsletter'])
                    }
                }
            }, 1500))
        }        
    }


    const handleLinkStripe = async(event) => {
        setFreezeButton(true)

        try {
            const response = await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_CREATESTRIPE)
            if (response.status === 200) {
                window.location.href = response.data.url
            }
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            setFreezeButton(false)
        }
    }

    const handleOpenStripe = (event) => {
        window.open(stripeLink, '_blank', 'noreferrer')
    }

    const handleDelStripe = async(event) => {
        setFreezeButton(true)
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_REMOVE)
            reFetchExpress()
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            setModalDelStripe(false)
            setFreezeButton(false)
        }
    }

    return (<>
        <SEO title={`Profile - ${SiteName}`} />
        <Container className='mt-5 mb-5' style={{ maxWidth:696 }}>
            <h1>Profile</h1>

            <div className='form-group'>
                <Label className="mt-3">Username</Label>
                <Input
                    className='form-control'
                    type="text"
                    name="username"
                    placeholder="enter an username"
                    value={formValue.username}
                    onChange={handleChange}
                    valid={validUsername}
                    invalid={errUsername}
                />
                {errUsername && <Alert color="danger">{errUsername}</Alert>}

                <Label className="mt-3">Email</Label>
                <Input
                    className='form-control'
                    type="email"
                    name="email"
                    placeholder="enter an email"
                    value={formValue.email}
                    onChange={handleChange}
                    valid={validEmail}
                    invalid={errEmail}
                />
                {errEmail && <Alert color="danger">{errEmail}</Alert>}

                <Label className="mt-3">Twitter</Label>
                <Input
                    className='form-control'
                    type="text"
                    name="twitter"
                    placeholder="Twitter's username"
                    value={formValue.twitter}
                    onChange={handleChange}
                    valid={validTwitter}
                    invalid={errTwitter}
                />
                {errTwitter && <Alert color="danger">{errTwitter}</Alert>}

                <Label className="mt-3">Facebook</Label>
                <Input
                    className='form-control'
                    type="text"
                    name="facebook"
                    placeholder="Facebook's username"
                    value={formValue.facebook}
                    onChange={handleChange}
                    valid={validFacebook}
                    invalid={errFacebook}
                />
                {errFacebook && <Alert color="danger">{errFacebook}</Alert>}

                <Label className="mt-3">Newsletter</Label>
                <div>
                    <Input
                        id='newsletter'
                        type="checkbox"
                        onChange={handleChange}
                        defaultChecked={formValue.newsletter}
                        name='newsletter'
                        valid={validNewsletter}
                        invalid={errNewsletter}
                        className="me-1"
                    />
                    <Label htmlFor='newsletter'>Receive Newsletter</Label>
                    {errNewsletter && <Alert color="danger">{errNewsletter}</Alert>}
                </div>
            </div>

            <Card className="mt-3">
                <CardBody>
                    <Form onSubmit={handleSubmit} color="primary">
                        <div className='form-group'>
                            <Label>Password</Label>
                            <Input
                                className='form-control'
                                type="password"
                                name="password"
                                placeholder="enter your password"
                                value={formValue.password}
                                onChange={handleChange}
                                required
                            />
                            {errPassword && <Alert color="danger">{errPassword}</Alert>}
                            <Label>Reenter Password</Label>
                            <Input
                                className='form-control'
                                type="password"
                                name="password2"
                                placeholder="Reenter your password"
                                value={password2}
                                onChange={(event) => setPassword2(event.target.value)}
                                required
                            />
                            {errPassword2 && <Alert color="danger">{errPassword2}</Alert>}

                            <Button color='primary' type="submit">
                                Change Password
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>

            <div className="mt-3">
                <h2>Payout method</h2>
                <Alert color="info">
                    You only need a Stripe Express account if you plan to <b>sell your own product or share affiliate links</b>.<br/>
                    Please note that the <b>Stripe Express account won't be used to purchase products</b>; only a personal credit card will be used for transactions.
                </Alert>

                {isLoadingExpress && <Loading/>}

                {!isLoadingExpress && !responseDataExpress.hasStripe && <>
                    <Button color='primary' onClick={handleLinkStripe} disabled={freezeButton}>
                        Link Stripe Express
                    </Button>
                </>}

                {!isLoadingExpress && responseDataExpress.hasStripe && !responseDataExpress.onboarded && <>
                    <Button color='primary' onClick={handleLinkStripe} disabled={freezeButton}>
                        Continue Stripe Express Onboarding
                    </Button>
                    <NavLink href='#' onClick={toggleDelStripe} disabled={freezeButton}>
                        {responseDataExpress.isExpress ?
                            'Delete Stripe Express Account' :
                            'Unlink Stripe Account'}
                    </NavLink>
                </>}

                {!isLoadingExpress && responseDataExpress.hasStripe && responseDataExpress.onboarded && <div>
                    <Button color='primary' onClick={handleOpenStripe}>Open Stripe{responseDataExpress.isExpress ? ' Express' : ''}</Button>
                    <NavLink href='#' onClick={toggleDelStripe} disabled={freezeButton}>
                        {responseDataExpress.isExpress ?
                            'Delete Stripe Express Account' :
                            'Unlink Stripe Account'}
                    </NavLink>
                </div>}

                <Modal isOpen={modalDelStripe} toggle={toggleDelStripe}>
                    <ModalHeader toggle={toggleDelStripe}>
                        {responseDataExpress.isExpress ?
                            'Delete Stripe Express Account' :
                            'Unlink Stripe Account'}
                    </ModalHeader>
                    <ModalBody>
                    {responseDataExpress.isExpress ?
                            'Are you sure you want to delete your Stripe Express account?' :
                            'Are you sure you want to unlink your Stripe Account?'}                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelStripe}>
                            {responseDataExpress.isExpress ?
                                'Delete Stripe Express Account' :
                                'Unlink Stripe Account'}
                            </Button>
                        <Button onClick={toggleDelStripe}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>

            {/* <div>
                <h2>notification preferences</h2>
            </div> */}
        </Container>
    </>)
}

export default Profile