import React, {useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { Row, Col, Container } from 'reactstrap'
import { Button, UncontrolledCollapse } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { AuthDispatchContext } from '../../App'
import { useRequest } from "../../hooks/Hooks"
import { addFilterSearch, FilterMode } from "../CategoryFilters"
import Loading from "../Loading"
import { useDomainNavigate } from "../../tools/Subdomain"

import * as Endpoints from '../../Endpoints'



const MenuCategory = ({filtername='filters', droppingDown, closeOffCanvas}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const [collapse, setCollapse] = React.useState({});
    const [searchParams] = useSearchParams()

    const { responseData: rDCatTags, isLoading: iLCatTags, hasError: hECatTags } = useRequest(authDispatchContext.dispatch, Endpoints.STORE_CATEGORIES, { method:'post', useCache:true, data:{store:authDispatchContext.domain} })

    const filterTags = authDispatchContext.filterTags

    const toggle = (name, val) => {
        Object.keys(collapse).forEach(v => collapse[v] = false)

        setCollapse({
            ...collapse,
            [name]: val})
    }    

    var i = 0
    var nPerCol = 4

    const dropItem = (catName, tags) => {
        var rows = []
        for (var r = 0; (r < nPerCol && i<tags.length); r++) {
            const name = tags[i].name
            const label = tags[i].label

            if (droppingDown) {
                rows.push(
                    <DropdownItem key={name} onClick={() => addFilterSearch(filtername, [name, FilterMode.And.name, catName, label], filterTags, searchParams, navigate)}>
                        {tags[i].label}
                    </DropdownItem>
                )
            }
            else {
                rows.push(
                    <Button
                        key={name}
                        onClick={() => { closeOffCanvas(); addFilterSearch(filtername, [name, FilterMode.And.name, catName, label], filterTags, searchParams, navigate) }}
                        block
                        outline
                        className="mb-1"
                    >
                        {tags[i].label}
                    </Button>
                )
            }
            i++
        }
        return rows
    }
    
    const drop = (catName, tags, vertical=false) => {
        var rows = []
        i = 0

        nPerCol = tags.length <= 3 * 4 ? 4 : Math.ceil(tags.length / 3)
        if (vertical) {
            nPerCol = tags.length
        }

        for (var c = 0; c < tags.length/nPerCol; c++) {
            rows.push(
                <Col key={tags[c].name}>
                    {dropItem(catName, tags)}
                </Col>
            )
        }
        return rows
    }

    const menuDrop = () => {
        var rows = []

        if (rDCatTags === undefined || rDCatTags === '')
            return ''

        const rDCats = rDCatTags.categories
        const rDTags = rDCatTags.tags

        for (const cat of rDCats) {
            const tags = []
            rDTags.forEach(element => {
                if (element.category_id === cat.id) {
                    tags.push(element)
                }
            })

            rows.push(
                <Dropdown
                    style={{maxWidth:'300px'}}
                    key={cat.name}
                    nav
                    isOpen={collapse[cat.name]}
                    onMouseEnter={() => toggle(cat.name, true)}
                    onMouseLeave={() => toggle(cat.name, false)}
                    toggle={() => toggle(cat.name, !collapse[cat.name])}
                >
                <DropdownToggle
                    // key={cat.name+'toggle'}
                    // caret
                    nav
                >
                    {cat.name}
                </DropdownToggle>
        
                <DropdownMenu
                    // key={cat.name+'menu'}
                    // className='w-100'
                    style={{width:525, padding:15}}
                >
                    <Row>
                        {drop(cat.name, tags)}
                    </Row>        
                </DropdownMenu>
            </Dropdown>
            )
        }
        return rows
    }

    const menuCollapse = () => {
        var rows = []        

        if (rDCatTags === undefined || rDCatTags === '')
            return ''

        const rDCats = rDCatTags.categories
        const rDTags = rDCatTags.tags

        for (const cat of rDCats) {
            const tags = []
            rDTags.forEach(element => {
                if (element.category_id === cat.id) {
                    tags.push(element)
                }
            });

            rows.push(
                <Button color="primary" className='text-start' outline
                    // key={cat.name+'button'}
                    id={cat.name}
                    style={{
                        marginBottom: '1rem'
                    }}
                >
                    {cat.name}
                </Button>
            )

            rows.push(
                <UncontrolledCollapse toggler={`#${cat.name}`}>
                    <Row>
                        {drop(cat.name, tags, true)}
                    </Row>        
                </UncontrolledCollapse>
            )
        }
        return rows
    }

    if (!authDispatchContext.isDomain) {
        return <></>
    }

    if (hECatTags) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
            )
    }

    if (iLCatTags || !rDCatTags) {
        return (
            <Container className='mt-5'>
                <Loading/>
            </Container>
            )
    }

    if (droppingDown) {
        return menuDrop()
    }

    return menuCollapse()
}

export default MenuCategory
