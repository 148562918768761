import React, { useContext } from "react"
import { Navigate } from "react-router"
import { Route, Routes } from 'react-router-dom'

import { AuthDispatchContext } from '../../App'

import Dashboard from "./Dashboard"
import Products from "./Products"
import Coupons from "./Coupons"
import Inbox from "./Inbox"
import ProductNew from "./ProductNew"
import ProductEdit from "./ProductEdit"
import { Container, Nav } from "reactstrap"
import MenuStore from "../../components/menus/MenuStore"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"


export const featDashboard = true
export const featCoupons = false
export const featAffiliates = true
export const featInbox = false


const Store = () => {
    const authDispatchContext = useContext(AuthDispatchContext)

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    return (<>
        <SEO title={`Store - ${SiteName}`} />
        
        {/* <Container className='mt-5'> */}
        <Container className='mt-5'>
            <div className='d-none d-md-block mb-3'>
                <Nav tabs>
                    <MenuStore/>
                </Nav>
            </div>

            <Routes>
                {featDashboard && <Route exact path='dashboard' element={<Dashboard />} />}
                <Route exact path='products' element={<Products />} />
                {featCoupons && <Route exact path='coupons' element={<Coupons />} />}
                {featInbox && <Route exact path='inbox' element={<Inbox />} />}
                <Route exact path='productnew' element={<ProductNew />} />
                <Route exact path='productedit/:slug' element={<ProductEdit />} />
                <Route exact path='*' element={<Products />} />
            </Routes>
        </Container>
        {/* </div> */}
    </>)
}

export default Store