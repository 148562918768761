import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom";
import { Button } from 'reactstrap'



/**
 * Enum.
 * @readonly
 * @enum {{name: string, hex: string}}
 */
export const FilterMode = Object.freeze({
    And: { name: "And", color: "success" },
    Or: { name: "Or", color: "warning" },
    Not: { name: "Not", color: "danger" }
});

const getFilterMode = (name) => {
    const n = name.toLowerCase()

    switch(n) {
        case 'and': return FilterMode.And
        case 'or': return FilterMode.Or
        case 'not': return FilterMode.Not
        default: return FilterMode.And
    }
}



export const addFilterSearch = (filtername, filter=['idname', FilterMode.And.name], filters, searchParams, navigate) => {

    for (const item of filters) {
        if (item.name === filter[0])
        {
            return
        }
    }

    searchParams.append(filtername, filter.join('_'))
    searchParams.delete('offset')

    navigate({
        pathname: '/search',
        search: `?${searchParams}`,
    })
}



const parseFilterSearch = (filtername, setFilters, searchParams, createFilter) => {

    const urlFilters = searchParams.getAll(filtername)

    const newFilters = []

    for (const filter of urlFilters) {
        // const [catName, name, label, modestr] = filter.split('_')
        const f = filter.split('_')
        const name = f[0]
        const modestr = f[1]
        const mode = getFilterMode(modestr)

        let already = false
        for (const filter of newFilters) {
            if (filter.name === name)
            {
                already = true
                break
            }
        }

        if (already) {
            continue
        }
    
        // const newFilter = {
        //     catName: catName,
        //     name: name,
        //     label: label,
        //     mode:mode
        // }
    
        newFilters.push(createFilter(name, mode, f))
    }

    setFilters(newFilters)
}



const removeFilterSearch = (filtername, item, searchParams, setSearchParams) => {

    const urlFilters = searchParams.getAll(filtername)
    searchParams.delete(filtername)

    for (const filter of urlFilters) {
        const [name] = filter.split('_')

        if (name !== item.name) {
            searchParams.append(filtername, filter)
        }
    }

    searchParams.delete('offset')
    setSearchParams(searchParams);
}



export const updateFilterSearch = (filtername, item, searchParams, setSearchParams, serializeFilter) => {

    const urlFilters = searchParams.getAll(filtername)
    searchParams.delete(filtername)

    for (const filter of urlFilters) {
        const [name] = filter.split('_')

        if (name !== item.name) {
            searchParams.append(filtername, filter)
        }
        else {
            searchParams.append(filtername, serializeFilter(item))
        }
    }

    searchParams.delete('offset')
    setSearchParams(searchParams);
}


const CategoryFilter = ({item, removeFilter, updateFilter, toStringFilter}) => {

    const onDelete = (event) => {
        event.stopPropagation();
        removeFilter()
    }

    const onChangeFilter = (event) => {
        switch(item.mode) {
            case FilterMode.And:
                item.mode = FilterMode.Or
                break

            case FilterMode.Or:
                item.mode = FilterMode.Not
                break

            default:
                item.mode = FilterMode.And
                break
            }

        updateFilter()
    }

    return (
        <span
            // size="sm"
            className='m-1 p-0 btn btn-secondary'
            // color='secondary'
        //     outline={true}
            onClick={onChangeFilter}
        >
            {/* <ButtonGroup   size="sm"> */}
            <Button color={item.mode.color} className='me-2' disabled onClick={onChangeFilter} size='sm'>{item.mode.name}</Button>
            <span style={{verticalAlign:'middle'}}>
                {toStringFilter(item)}
            </span>
            {/* <Button disabled onClick={onChangeFilter}>{item.name}</Button> */}
            <Button className='ms-2 me-1' style={{verticalAlign:'middle'}} close size='sm' onClick={onDelete}/>

            {/* </ButtonGroup> */}
        </span>
    )
}



const CategoryFilters = ({filtername='filters', filters, setFilters=() => {}, createFilter=() => {}, serializeFilter=() => {}, toStringFilter=() => {}}) => {
    // const authDispatchContext = useContext(AuthDispatchContext)
    const [searchParams, setSearchParams] = useSearchParams()

    // const filters = authDispatchContext.filters
    // const setFilters = authDispatchContext.setFilters

    useEffect(() => {
        parseFilterSearch(filtername, setFilters, searchParams, createFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    return (
        <div>
            {filters && filters.map((item, i) => {
                return (
                    <CategoryFilter
                        key={item.name}
                        item={item}
                        toStringFilter={toStringFilter}
                        removeFilter={() => removeFilterSearch(filtername, item, searchParams, setSearchParams)}
                        updateFilter={() => updateFilterSearch(filtername, item, searchParams, setSearchParams, serializeFilter)}
                    />
                )
                })}
        </div>
    )
}

export default CategoryFilters
