import { useContext, useEffect, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { CloudURL, Upload, resizeFile } from '../tools/Cloud'
import { AuthDispatchContext } from '../App'
import * as Request from '../actions/Request'
import * as Enpoints from '../Endpoints'
import DOMPurify from 'dompurify'

import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import variables from './../scss/custom.scss'


// Quill & Sanitize
const Image = Quill.import('formats/image')
Image.className = 'imgEmbed'
Quill.register(Image, true)


// Color Blot
const colorDict = {
    [variables.primary]: 'primary',
    [variables.secondary]: 'secondary',
    [variables.success]: 'success',
    [variables.info]: 'info',
    [variables.warning]: 'warning',    
    [variables.danger]: 'danger',
    [variables.light]: 'light',
    [variables.dark]: 'dark',
}
const Inline = Quill.import('blots/inline')
class ColorPrimary extends Inline {
    static blotName = 'colorblock'
    static tagName = 'span'
    
    static create(value) {
        if (value in colorDict) {
            const node = super.create(value)
            node.setAttribute('class', `ql-color-${colorDict[value]}`)
            return node
        }

        const node = super.create(value)
        if (value.className.startsWith('ql-color-')) {
            node.setAttribute('class', value.className)
        }

        return node
    } 

    static formats(node) {
        return node
    }
}
Quill.register(ColorPrimary)


// Sanitize
const allowedTags = [
    'h1', 'h2', 'p', 'br',
    'strong', 'em', 'u', 's',
    'blockquote',
    'ol', 'ul', 'li',
    'a',
    'img', 'iframe'
]

const allowedAttrs = [
    'src', 'href'
]

const getVideoUrl = (url) => {
    // Youtube video URL
    let match =
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/embed\/([a-zA-Z0-9_-]+)/)

    if (match) {
        return `${match[1] || 'https'}://www.youtube.com/embed/${match[2]}?showinfo=0`
    }

    // Vimeo video URL
    match = 
        url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:www\.)?player\.vimeo\.com\/video\/(\d+)/)
    if (match) {
        return `${match[1] || 'https'}://player.vimeo.com/video/${match[2]}/`
    }

    // DailyMotion video URL
    match =
        url.match(/^(?:(https?):\/\/)?(?:(?:www)\.)?dailymotion\.com\/video\/([a-zA-Z0-9_]+)*/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www)\.)?dailymotion\.com\/embed\/video\/([a-zA-Z0-9_]+)*/)
    if (match) {
        return `https://www.dailymotion.com/embed/video/${match[2]}`
    }

    // Check if the URL ends with a common video file extension (add more if needed)
    const videoFileExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv']
    const urlParts = url.split('.')
    const fileExtension = urlParts[urlParts.length - 1].toLowerCase()

    if (videoFileExtensions.includes(fileExtension)) {
        return url
    }

    return null
}


const onSanitizeElement = (node, data) => {
    if (data.attrName === 'src') {
        if (node.tagName && !['img', 'iframe'].includes(node.tagName.toLowerCase())) {
            node.remove()
        }
    }      

    if (node.tagName && node.tagName.toLowerCase() === 'b') {
        const el = document.createElement("strong")
        el.innerHTML = node.innerHTML

        node.parentNode.replaceChild(el, node)
    }

    if (node.tagName && node.tagName.toLowerCase() === 'iframe') {
        const vurl = getVideoUrl(node.src)

        if (!vurl) {
            node.remove()
        }
    }
}

DOMPurify.addHook('uponSanitizeElement', onSanitizeElement)


// Paste
const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')

class PlainClipboard extends Clipboard {
    onPaste(e) {
        e.preventDefault()
        this.quill.focus()

        const textPlain = e.clipboardData.getData('text/plain').replace(/(\r\n|\n)/g, '<br>')
        const textHtml = e.clipboardData.getData('text/html').replace(/(\r\n|\n)/g, '')
        const pastedText = textHtml ? textHtml : textPlain

        const sanitizedHTML = DOMPurify.sanitize(pastedText, {ALLOWED_TAGS: allowedTags, ALLOWED_ATTR: allowedAttrs, ALLOW_ARIA_ATTR: false, ALLOW_DATA_ATTR: false})


        const range = this.quill.getSelection()

        const delta = new Delta()
          .retain(range.index)
          .delete(range.length)
        //   .insert(sanitizedHTML)
        // const index = sanitizedHTML.length + range.index
        // const length = 0
        this.quill.updateContents(delta, 'silent')
        this.dangerouslyPasteHTML(range.index, sanitizedHTML)
        // this.quill.setSelection(range.index, 0, 'silent')
        // this.quill.scrollIntoView()
    }
}
Quill.register('modules/clipboard', PlainClipboard, true)



const DragDropText = ({productID, value='', onChange=() => {}}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const quillRef = useRef(null)
    const [modalImg, setModalImg] = useState(false)
    const [modalVideo, setModalVideo] = useState(false)
    const [ddOpenSourceImg, setDDOpenSourceImg] = useState(false)

    const [imgSource, setImgSource] = useState('Source')

    const [imgURL, setImgURL] = useState('')
    const [videoURL, setVideoURL] = useState('')
    

    const toggleModalImg = () => setModalImg(!modalImg)
    const toggleModalVideo = () => setModalVideo(!modalVideo)
    
    const handleOnUpload = async(path, isThumbnail) => {
        if (!productID) {
            return
        }

        const params = {
            id: productID,
            path: path,
        }

        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Enpoints.UPDATEPRODUCT_IMG, params)
            
            const editor = quillRef.current.getEditor()
            const range = editor.getSelection(true)
            editor.insertEmbed(range.index, 'image', CloudURL(path))
        }
        catch (error) {
            console.log(error)
        }
    }


    const handleImageUpload = async (event) => {
        if (!productID) {
            return
        }

        const file = event.target.files[0]

        const fileImg = await resizeFile(file, 1920, 1080)
        Upload(fileImg, fileImg.name, productID, handleOnUpload, true)
    }


    const handleDragOver = (event) => {
        event.preventDefault()
    }
  

    const handleDrop = async (event) => {
        event.preventDefault()

        if (!productID) {
            return
        }

        const file = event.dataTransfer.files[0]
        const fileImg = await resizeFile(file, 1920, 1080)
        Upload(fileImg, fileImg.name, productID, handleOnUpload, true)
    }


    const handleImageButton = () => {
        setImgSource('Source')
        toggleModalImg()
    }


    const handleVideoButton = () => {
        toggleModalVideo()
    }

    const handleColorButton = (color) => {
        const editor = quillRef.current.getEditor()
        const range = editor.getSelection(true)
        if (range) {
            editor.formatText(range, 'colorblock', color)
        }
    }


    const onOpenFile = () => {
        toggleModalImg()

        const input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        input.onchange = handleImageUpload
        input.click()
    }


    const onInsertImgURL = () => {
        toggleModalImg()

        const editor = quillRef.current.getEditor()
        const range = editor.getSelection(true)
        editor.insertEmbed(range.index, 'image', imgURL)
    }


    const onInsertVideoURL = () => {
        toggleModalVideo()

        const vurl = getVideoUrl(videoURL)
        
        if (vurl) {
            const editor = quillRef.current.getEditor()
            const range = editor.getSelection(true)
            
            editor.insertEmbed(range.index, 'video', getVideoUrl(videoURL))
        }
    }


    const onChangeImgSource = (type) => {
        setImgSource(type)
    }


    useEffect(() => {
        if (quillRef.current) {
            const editor = quillRef.current.getEditor()
            const container = editor.container
            container.addEventListener('dragover', handleDragOver)
            container.addEventListener('drop', handleDrop)

            var toolbar = editor.getModule('toolbar')
            toolbar.addHandler('image', handleImageButton)
            toolbar.addHandler('video', handleVideoButton)
            toolbar.addHandler('color', handleColorButton)
            
            return () => {
                container.removeEventListener('dragover', handleDragOver)
                container.removeEventListener('drop', handleDrop)
            }
        }
    // eslint-disable-next-line
    }, [quillRef])
  

    return (
        <>
            <Modal isOpen={modalImg} toggle={toggleModalImg}>
                <ModalHeader toggle={toggleModalImg}>Insert a new Image</ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <ButtonDropdown isOpen={ddOpenSourceImg} toggle={() => setDDOpenSourceImg(!ddOpenSourceImg)}>
                            <DropdownToggle caret>
                                {imgSource}
                            </DropdownToggle>

                            <DropdownMenu>
                                {productID && <DropdownItem onClick={() => onChangeImgSource('File')}>
                                    Import from Computer
                                </DropdownItem>}
                                <DropdownItem onClick={() => onChangeImgSource('URL')}>
                                    URL
                                </DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>

                        {['URL', 'ImgUr'].includes(imgSource) && <Input type="text" value={imgURL} onChange={e => setImgURL(e.target.value)} />}
                    </InputGroup>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={toggleModalImg}>
                        Cancel
                    </Button>
                    {imgSource === 'File' && <Button color='primary' onClick={onOpenFile}>
                        Open...
                    </Button>}
                    {imgSource === 'URL' && <Button color='primary' onClick={onInsertImgURL}>
                        Insert
                    </Button>}
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalVideo} toggle={toggleModalVideo}>
                <ModalHeader toggle={toggleModalVideo}>Insert a Video</ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <InputGroupText>
                            Video URL
                        </InputGroupText>

                        <Input type="text" value={videoURL} onChange={e => setVideoURL(e.target.value)} />
                    </InputGroup>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={toggleModalVideo}>
                        Cancel
                    </Button>
                    <Button color='primary' onClick={onInsertVideoURL}>
                        Insert
                    </Button>
                </ModalFooter>
            </Modal>


            <ReactQuill
                ref={quillRef}
                theme="snow"
                scrollingContainer="#editorContainer"
                value={value}
                onChange={onChange}
                modules={{
                    toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{ color: [
                            variables.primary,
                            variables.secondary,
                            variables.success,
                            variables.info,
                            variables.warning,
                            variables.danger,
                            variables.light,
                            variables.dark
                        ]}],
                        [{ 'align': [] }], // Align options: center, right, left
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image', 'video'],
                        ['clean']
                    ]
                }}
            />
        </>
    )
}
  
export default DragDropText
  