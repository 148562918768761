import Cookies from 'js-cookie'

const LOCAL = 'local'
const COOKIE = 'cookie'
const MEMORY = 'memory'
// const CROSS = 'cross'

const storage = MEMORY

// TODO:
// - Access Token in Mem Only
// - Refresh Token httpOnly Secure


const cookiesAttributes = { httpOnly: false, secure: false, domain: process.env.REACT_APP_FRONT_COOKIE_DOMAIN }
const memory = {}

export const setItem = (key, value, local24=false) => {
    if (local24) {
        const expTime = new Date().getTime() + 24 * 60 * 60 * 1000 // 24 hours in milliseconds
        localStorage.setItem(key, value)
        localStorage.setItem(key + 'exp', expTime)
        return
    }

    switch(storage) {
        case LOCAL:
            localStorage.setItem(key, value)
            break

        case COOKIE:
            // Must be something like '.example.com' to handle Subdomains
            Cookies.set(key, value, cookiesAttributes) //, { httpOnly: false, secure: false, domain: process.env.REACT_APP_FRONT_COOKIE_DOMAIN })
            break

        case MEMORY:
            memory[key] = value
            break

        // case CROSS:
        //     return Storage.getItem(key)

        default:

    }
}


export const getItem = (key, local24=false) => {
    if (local24) {
        const currentTime = new Date().getTime()
        const expTime = localStorage.getItem(key + 'exp')

        if (expTime && currentTime > expTime) {
            console.log('expired!')
            removeItem(key, local24)
        }

        return localStorage.getItem(key)
    }

    switch(storage) {
        case LOCAL:
            return localStorage.getItem(key)

        case COOKIE:
            return Cookies.get(key)


        case MEMORY:
            return memory[key]

        // case CROSS:
        //     return Storage.getItem(key)

        default:
    }
}


export const removeItem = (key, local24=false) => {
    if (local24) {
        localStorage.removeItem(key + 'exp')
        localStorage.removeItem(key)
    }

    switch(storage) {
        case LOCAL:
            localStorage.removeItem(key)
            break

        case COOKIE:
            Cookies.remove(key, cookiesAttributes)
            break

        case MEMORY:
            if (key in memory) {
                delete memory[key]
            }
            break

        // case CROSS:
        //     return Storage.getItem(key)
        default:
    }
}