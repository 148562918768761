import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from "reactstrap"
import { useDomainNavigate } from "../../tools/Subdomain"
import { SiteName, SiteSocialNetworks } from "../SiteTitle"
import { CloudURL } from "../../tools/Cloud"



export const MenuMarket = ({closeOffCanvas=() => {}, vertical=false}) => {
    const navigate = useDomainNavigate()


    const onAbout = () => {
        closeOffCanvas()

        navigate('/about')
    }

    const onPhilosophy = () => {
        closeOffCanvas()

        navigate('/philosophy')
    }

    const onNews = () => {
        closeOffCanvas()

        navigate('/news')
    }

    const onFAQs = () => {
        closeOffCanvas()

        navigate('/faqs')
    }

    const onLegal = () => {
        closeOffCanvas()

        navigate('/legal')
    }

    const onRoot = () => {
        closeOffCanvas()

        navigate('/.', '', false, true)
    }

    const colorBG = (name, cname) => {
        if (window.location.pathname.startsWith("/" + name.toLowerCase())) {
            return cname + ' bg-current-menu'
        }

        return cname
    }

    // if (!authDispatchContext.isDomain) {
    //     return <></>
    // }

    if (vertical) {
        return <div className="border-top mt-3 pt-3">
            <h6>{SiteName}</h6>
            <NavItem className={colorBG('About', '')}>
                <NavLink href='#' onClick={onAbout}>
                    About
                </NavLink>
            </NavItem>

            <NavItem className={colorBG('Philosophy', '')}>
                <NavLink href='#' onClick={onPhilosophy}>
                    Philosophy
                </NavLink>
            </NavItem>

            <NavItem className={colorBG('News', '')}>
                <NavLink href='#' onClick={onNews}>
                    News
                </NavLink>
            </NavItem>

            <NavItem className={colorBG('FAQs', '')}>
                <NavLink href='#' onClick={onFAQs}>
                    FAQs
                </NavLink>
            </NavItem>

            <NavItem className={colorBG('Legal', '')}>
                <NavLink href='#' onClick={onLegal}>
                    Legal
                </NavLink>
            </NavItem>

            <NavItem>
                <NavLink href='#' onClick={onRoot}>
                    {SiteName}
                </NavLink>
            </NavItem>

            <NavItem className="text-end">
                <SiteSocialNetworks/>
            </NavItem>
        </div>
    }

    return (
        <UncontrolledDropdown nav>
            <DropdownToggle nav caret>
                <img alt='' src={CloudURL('logo64.webp')} height={24}/>
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem className={colorBG('About', '')}>
                    <NavLink href='#' onClick={onAbout}>
                        About
                    </NavLink>
                </DropdownItem>

                <DropdownItem className={colorBG('Philosophy', '')}>
                    <NavLink href='#' onClick={onPhilosophy}>
                        Philosophy
                    </NavLink>
                </DropdownItem>

                <DropdownItem className={colorBG('News', '')}>
                    <NavLink href='#' onClick={onNews}>
                        News
                    </NavLink>
                </DropdownItem>

                <DropdownItem className={colorBG('FAQs', '')}>
                    <NavLink href='#' onClick={onFAQs}>
                        FAQs
                    </NavLink>
                </DropdownItem>

                <DropdownItem className={colorBG('Legal', '')}>
                    <NavLink href='#' onClick={onLegal}>
                        Legal
                    </NavLink>
                </DropdownItem>

                <DropdownItem>
                    <NavLink href='#' onClick={onRoot}>
                        {SiteName}
                    </NavLink>
                </DropdownItem>

                <DropdownItem className="text-end">
                    <SiteSocialNetworks/>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}