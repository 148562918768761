import { useContext, useEffect, useState } from "react"
import { Alert, Button, Container } from "reactstrap"
import { AuthDispatchContext } from "../../App"
import { useRequest } from "../../hooks/Hooks"
import Loading from "../../components/Loading"
import { useDomainNavigate } from "../../tools/Subdomain"
import * as Endpoints from '../../Endpoints'
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"



const Verify = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()
    const token = new URLSearchParams(window.location.search).get('token')
    const [success, setSuccess] = useState(false)

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, Endpoints.ACCOUNTS_EMAIL_VERIFY, { method:'post', data:{token:token} })

    // const done = !isLoading && !hasError && responseData.length>0


    useEffect(() => {
        if (responseData.status === 'success') {
            setSuccess(true)
        }

    }, [responseData])

    if (isLoading || !responseData) {
        return (<>
            <SEO title={`Verify - ${SiteName}`} />
            <Container className='mt-5'>
                <Loading/>
            </Container>
        </>)
    }

    if (hasError) {
        return (<>
            <SEO title={`Verify - ${SiteName}`} />
            <Container className='mt-5'>
                Error!
            </Container>
        </>)
    }

    return (<>
        <SEO title={`Verify - ${SiteName}`} />
        <Container className='mt-5' style={{ maxWidth:696 }}>
            {success && <>
                <h1>Congratulations!</h1>
                <p>Your email address has been successfully verified. You can now log in to your account and start using our website.</p>
                <p>Thank you for verifying your email address, and we look forward to seeing you on our website!</p>
                <Button color="primary" onClick={() => navigate('/login')}>Log In</Button>
            </>}

            {!success && <>
                <Alert>Error!</Alert>
            </>}
        </Container>
    </>)
}



export default Verify