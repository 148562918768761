import React from "react";
import { Route, Routes } from 'react-router-dom'

import { Container, Nav } from "reactstrap";
import MenuLegal from "../../components/menus/MenuLegal";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import CustomerPolicy from "./CustomerPolicy";
import { SEO } from "../../components/SEO";
import { SiteName } from "../../components/SiteTitle";



const Legal = () => {

    return (<>
        <SEO title={`Legal - ${SiteName}`} />

        <Container className='mt-5 mb-5'>
            <div className='d-none d-md-block mb-3'>
                <Nav tabs>
                    <MenuLegal/>
                </Nav>
            </div>

            <Routes>
                <Route exact path='termsofservice' element={<TermsOfService />} />
                <Route exact path='privacypolicy' element={<PrivacyPolicy />} />
                <Route exact path='customerpolicy' element={<CustomerPolicy />} />
                <Route exact path='*' element={<TermsOfService />} />
            </Routes>
        </Container>
    </>)
}

export default Legal