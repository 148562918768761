import { useContext, useEffect, useState } from "react"
import { AuthDispatchContext } from "../../App"
import { useDomainNavigate } from "../../tools/Subdomain"
import * as Request from "../../actions/Request"
import { useParams } from "react-router"
import { Alert, Button, Container, Form, Input, Label } from "reactstrap"

import * as Endpoints from '../../Endpoints'
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"


const ResetPWD = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()
    const [errMsg, setErrMsg] = useState()
    const [password2, setPassword2] = useState()
    const [errPassword2, setErrPassword2] = useState()

    const { token } = useParams()

    const [formValue, setformValue] = useState({
        password: ''
    })

    
    const handleSubmit = async(event) => {
        event.preventDefault()
        
        setErrMsg(undefined)

        try {
            const response = await Request.request(authDispatchContext.dispatch, 'post', Endpoints.ACCOUNTS_EMAIL_CHANGE, { token:token, pwd:formValue.password })
            if (response.status === 200) {
                navigate('/login')
            }
            else {
                setErrMsg('Something went wrong.')
            }
        }
        catch(error) {
            setErrMsg('Something went wrong.')
        }
    }


    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        })
    }


    useEffect(() => {
        if ((!formValue.password && !password2) || formValue.password === password2)
        {
            setErrPassword2('')
        }
        else {
            setErrPassword2("Passwords don't match!")
        }
    }, [formValue.password, password2])


    return (<>
        <SEO title={`Reset Password - ${SiteName}`} />

        <Container className='mt-5' style={{ maxWidth:696 }}>
            <h1>Reset your Password</h1>
            <Form onSubmit={handleSubmit}>
                {errMsg && <Alert color="danger">{errMsg}</Alert>}
                <div className='form-group'>
                    <Label>New Password</Label>
                    <Input
                        className='form-control'
                        type="password"
                        name="password"
                        placeholder="enter your password"
                        value={formValue.password}
                        onChange={handleChange}
                        required
                    />
                    <Label>Reenter Password</Label>
                    <Input
                        className='form-control'
                        type="password"
                        name="password2"
                        placeholder="Reenter your password"
                        value={password2}
                        onChange={(event) => setPassword2(event.target.value)}
                        required
                    />
                    {errPassword2 && <Alert color="danger">{errPassword2}</Alert>}
                    <Button color="primary" type="submit">
                        Reset Password
                    </Button>
                </div>
            </Form>
        </Container>
    </>)
}

export default ResetPWD