import { useContext } from "react"
import { AuthDispatchContext } from "../../App"
import * as Endpoints from '../../Endpoints'
import { useRequest } from "../../hooks/Hooks"
import Loading from "../Loading"
import { Col, Input, Row } from "reactstrap"
import * as Request from '../../actions/Request'
import { LayoutContext } from "../../hocs/Layout"



const EditTags = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    const { responseData, hasError } = useRequest(authDispatchContext.dispatch, Endpoints.STORE_TAGS, { method:'post' })
    const { responseData: rDStores, hasError: hEStores } = useRequest(authDispatchContext.dispatch, Endpoints.STORE_ALL, { method:'post' })

    const label_width_0 = 250
    const label_width_1 = 150
    const store_width = 150

    if (!responseData || !rDStores) {
        return (
            <Loading/>
        )
    }

    if (hasError || hEStores) {
        return (
            <>Error!</>
        )
    }

    const tt_width = 2 * label_width_0 + label_width_1 + rDStores.length * store_width

    const onChangeStore = async(event) => {
        const [tagName, storeName] = event.target.id.split('_')

        try {
            const params = {
                tagName: tagName,
                storeName: storeName,
                include: event.target.checked,
            }

            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STORE_UPDATE_TAG_STORE, params)
        }
        catch(error) {
            layoutContext.setNotifContent({title:'Error', message:'Cannot update Tag Store.'})
        }
    }

    const StoreCols = (stores) => {
        const cols = []

        for(var store of stores) {
            const col = <Col className="text-center square border-end border-bottom">{store.label}</Col>
            cols.push(col)
        }

        return cols
    }

    const TagRows = (tags, stores) => {
        const rows = []

        for (var tag of tags) {
            const cols = []

            cols.push(
                <Col style={{ backgroundColor: '#fff', position:"sticky", minWidth:label_width_0, left:0}} className="square border-end border-top">
                    {tag.name}
                </Col>
            )

            cols.push(
                <Col style={{ backgroundColor: '#fff', position:"sticky", minWidth:label_width_0, left:label_width_0}} className="square border-end border-top">
                    {tag.label}
                </Col>
            )

            cols.push(
                <Col style={{ backgroundColor: '#fff', position:"sticky", minWidth:label_width_1, left:label_width_0*2}} className="square border-end border-top">
                    {tag.category}
                </Col>
            )

            for(var store of stores) {
                cols.push(
                    <Col className="text-center square border-end border-top">
                        <Input id={`${tag.name}_${store.name}`} name={`${tag.name}_${store.name}`} onChange={onChangeStore} type="checkbox" defaultChecked={tag.stores.includes(store.label)}/>
                    </Col>
                )
            }

            const row = <Row style={{ width:tt_width }}>{cols}</Row>

            rows.push(row)
        }
    
        return rows
    }

    return (
        <>
            <Row style={{ width:tt_width, position:"sticky", top:0, fontWeight:"bold", backgroundColor: '#f8f9fa', zIndex: 1}}>
                <Col style={{ backgroundColor: '#f8f9fa', position:"sticky", minWidth:label_width_0, left:0}} className="square border-end border-bottom">ID Name</Col>
                <Col style={{ backgroundColor: '#f8f9fa', position:"sticky", minWidth:label_width_0, left:label_width_0}} className="square border-end border-bottom">Label</Col>
                <Col style={{ backgroundColor: '#f8f9fa', position:"sticky", minWidth:label_width_1, left:label_width_0*2}} className="square border-end border-bottom">Category</Col>
                {StoreCols(rDStores)}
            </Row>
            {TagRows(responseData, rDStores)}
        </>
    )
}

export default EditTags