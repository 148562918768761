import React, { useContext } from "react";
import { Card, CardBody, CardTitle, CardImg, CardSubtitle, CardText, CardLink, CardImgOverlay, Row, Col, Badge } from 'reactstrap';
import { AuthDispatchContext } from '../App'
import { CloudURL } from '../tools/Cloud'
import { ProductUrl, UserUrl, useDomainNavigate } from "../tools/Subdomain";



const ProductCard = ({product, hideAuthor=false, editCard=false}) => {

    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()


    const onMouseDwn = (e) => {
        if (e.button === 1) {
            e.preventDefault()
        }
    }


    const onClickCard = (e) => {
        if (editCard) {
            navigate(`/store/productedit/${product.slug}`)
        }
        else {
            navigate(`/product/${product.slug}`)
        }
    }


    const onAuxClickCard = (e) => {
        if (e.button !== 1) {
            return
        }

        if (e.target.className === 'card-link') {
            return
        }
        
        if (editCard) {
            window.open(`/store/productedit/${product.slug}`, '_blank', 'noreferrer')
        }
        else {
            window.open(ProductUrl(product.slug, authDispatchContext.isUser), '_blank', 'noreferrer')
        }
    }


    const onClickUser = (event) => {
        event.stopPropagation()
        navigate('', product.username, true)
    }


    const showBadges = authDispatchContext.state.isAuthenticated && (authDispatchContext.state.user.doc || authDispatchContext.state.user.id === parseInt(product.userid))

    return (
        <Card className='m-2'
            color="light"
            onClick={onClickCard}
            onAuxClick={onAuxClickCard}
            onMouseDown={onMouseDwn}
            style={{ cursor: "pointer" }}
        >
            {showBadges && <CardImgOverlay style={{height:'fit-content'}}>
                <Row>
                    <Col>
                        {product.active && <Badge color="success">Active</Badge>}
                        {!product.active && <Badge color="danger">Inactive</Badge>}
                    </Col>
                    <Col className="text-end">
                        <Badge color={product.state === 'Rejected' ? 'danger' : product.state === 'Needs Review' ? 'warning' : product.state === 'Updated' ? 'info' : 'success'}>{product.state}</Badge>
                    </Col>
                </Row>
            </CardImgOverlay>}

            <CardImg
                alt="Card image cap"
                src={CloudURL(product.thumbnail)}
                top
                className="product-card-cover"
                onError={(e) => { e.target.src = CloudURL('missing_thumbnail.jpeg') }}
            />

            <CardBody>
                <CardTitle tag="h5">
                    {product.name}
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted card-subtitle text-notransform"
                    tag="h6"
                >
                    {product.summary}
                </CardSubtitle>
                {!hideAuthor && <CardText>
                    by <CardLink onClick={onClickUser} href={UserUrl(product.username)}>{product.username}</CardLink>
                </CardText>}
            </CardBody>
        </Card>
    )
}


export default ProductCard;
