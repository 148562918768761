import React from 'react'
import { IntlProvider } from 'react-intl'
import App from './App'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from "react-helmet-async";

import './scss/custom.scss'
import { Empty } from './components/Empty';


const closedForMaintenance = false


const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <IntlProvider locale='en'>
            <HelmetProvider>
                {closedForMaintenance && <Empty msg={<>
                    Oops! <b>FairMarket.Land</b> is currently undergoing maintenance.<br/>
                    Don't worry, we'll be back and better than ever in no time!<br/>
                    Thanks for your patience.<br/>
                </>}/>}

                {!closedForMaintenance && <App />}
            </HelmetProvider>
        </IntlProvider>
    </React.StrictMode>
)