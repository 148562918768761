import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Container, Form, Input, Label } from "reactstrap";

import * as Auth from '../../actions/AuthActions'
import { AuthDispatchContext } from '../../App'
import { SiteName } from '../../components/SiteTitle';
import { useDomainNavigate } from '../../tools/Subdomain';
import { SEO } from '../../components/SEO';

const Signup = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const [errMsg, setErrMsg] = useState()
    const [errUsername, setErrUsername] = useState()
    const [errEmail, setErrEmail] = useState()
    const [errPassword, setErrPassword] = useState()
    const [verifyEmail, setVerifyEmail] = useState()

    const [password2, setPassword2] = useState()
    const [errPassword2, setErrPassword2] = useState()

    const [formValue, setformValue] = useState({
        username: '',
        email: '',
        password: '',
        twitter: '',
        facebook: ''
    })
    
    if (authDispatchContext.state.isAuthenticated) {
        authDispatchContext.navigateBack(navigate)
        // return <Navigate to='/' />
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        
        setErrMsg(undefined)
        setErrUsername(undefined)
        setErrEmail(undefined)
        setErrPassword(undefined)

        Auth.signup(
            authDispatchContext,
            formValue.username,
            formValue.email,
            formValue.password,
            formValue.twitter,
            formValue.facebook,
            (email) => {
                setVerifyEmail(email)
            },
            (error) => {
                if (error.response.status === 401) {
                    setErrMsg('No active account found with the given credentials')
                }
                else {
                    if ('username' in error.response.data){
                        setErrUsername(error.response.data['username'])
                    }
                    if ('email' in error.response.data){
                        setErrEmail(error.response.data['email'])
                    }
                    if ('password' in error.response.data){
                        setErrPassword(error.response.data['password'])
                    }
                }
            }
        )
    }

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        })
    }



    useEffect(() => {
        if (!password2 || (!formValue.password && !password2) || formValue.password === password2)
        {
            setErrPassword2('')
        }
        else {
            setErrPassword2("Passwords don't match!")
        }
    }, [formValue.password, password2])



    if (verifyEmail) {
        return (
            <Container className='mt-5'>
                <h1>Verify your Email</h1>
                <p>Thank you for registering with <b>{SiteName}</b>! Before you can start using your account, we need to verify your email address.</p>
                <p>We have sent an email to <b>{verifyEmail}</b>. Please click on the link in that email to verify your email address. If you did not receive the email, please check your spam folder or contact us for assistance.</p>
            </Container>
        )
    }

    return (<>
        <SEO title={`Sign Up - ${SiteName}`} />

        <Container className='mt-5' style={{ maxWidth:696 }}>
            <h1>Sign Up</h1>
            <p>Create a new Account</p>
            <Form onSubmit={handleSubmit}>
                {errMsg && <Alert color="danger">{errMsg}</Alert>}
                <div className='form-group'>
                    <Label>Email</Label>
                    <Input
                        className='form-control'
                        type="email"
                        name="email"
                        placeholder="enter an email"
                        value={formValue.email}
                        onChange={handleChange}
                        autoComplete="email"
                        required
                    />
                    {errEmail && <Alert color="danger">{errEmail}</Alert>}

                    <Label>Username</Label>
                    <Input
                        className='form-control'
                        type="text"
                        name="username"
                        placeholder="enter your username"
                        value={formValue.username}
                        onChange={handleChange}
                        autoComplete="username"
                        required
                    />
                    {errUsername && <Alert color="danger">{errUsername}</Alert>}

                    <Label>Password</Label>
                    <Input
                        className='form-control'
                        type="password"
                        name="password"
                        placeholder="enter your password"
                        value={formValue.password}
                        onChange={handleChange}
                        autoComplete="new-password"
                        required
                    />
                    {errPassword && <Alert color="danger">{errPassword}</Alert>}

                    <Label>Reenter Password</Label>
                    <Input
                        className='form-control'
                        type="password"
                        name="password2"
                        placeholder="Reenter your password"
                        value={password2}
                        onChange={(event) => setPassword2(event.target.value)}
                        autoComplete="new-password"
                        required
                    />
                    {errPassword2 && <Alert color="danger">{errPassword2}</Alert>}

                    <Label className="mt-3">Social Media Handles</Label>
                    <Container className="mb-3">
                        <Label>Twitter</Label>
                        <Input
                            className='form-control'
                            type="text"
                            name="twitter"
                            placeholder="enter your Twitter handle"
                            value={formValue.twitter}
                            autoComplete="nickname"                            
                            onChange={handleChange}
                        />

                        <Label>Facebook</Label>
                        <Input
                            className='form-control'
                            type="text"
                            name="facebook"
                            placeholder="enter your Facebook username"
                            value={formValue.facebook}
                            autoComplete="nickname"   
                            onChange={handleChange}
                        />
                    </Container>

                    <Button color='primary' type="submit">
                        Sign Up
                    </Button>
                </div>
            </Form>
        </Container>
    </>)
}

export default Signup