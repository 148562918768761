import React, { useContext } from "react"

import { AuthDispatchContext } from '../App'
import { useRequest } from "../hooks/Hooks"

import Loading from "./Loading"
import InfiniteScroll from "./InfiniteScroll"
import { LayoutContext } from "../hocs/Layout"
import ProductCard from "./ProductCard"
import { Container } from "reactstrap"

import * as Endpoints from '../Endpoints'
import { Empty } from "./Empty"
import { SEO } from "./SEO"
import { SiteName } from "./SiteTitle"
import { Share } from "./Share"
import { AskSeller } from "./AskSeller"



const UserDomain = () => {

    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    // const { username } = useParams()
    const username = authDispatchContext.domain

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.ACCOUNTS_USERNAME}${username}/`, { useCache:true })

    if (hasError) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
            )
    }

    if (isLoading || !responseData) {
        return (
            <Container className='mt-5'>
                <Loading/>
            </Container>
            )
    }

    const renderItem = (item) => {
        return <ProductCard product={item} hideAuthor={true} editCard={false}/>
    }

    return (<>
        <SEO title={`${responseData.username} - ${SiteName}`} domain={`${responseData.username}.s`} canonical={''}/>

        <Container className='mt-5'>
            <h1 className="text-center">
                {responseData.username}
                <span style={{fontSize:'large'}}>
                    {responseData.twitter && <a href={`https://twitter.com/${responseData.twitter}`} rel="noreferrer" target="_blank" className="fa-brands fa-x-twitter"><i hidden="true">&nbsp;</i></a>}
                    {responseData.facebook && <a href={`https://facebook.com/${responseData.facebook}`} rel="noreferrer" target="_blank" className="fa-brands fa-facebook-f"><i hidden="true">&nbsp;</i></a>}
                    <AskSeller seller={responseData.username}/>
                </span>
            </h1>

            <div className="text-end">
                <Share shareUrl={`https://${username}.s.${process.env.REACT_APP_FRONT_DOMAIN}`} title={`${username}`}/>
            </div>

            <InfiniteScroll
                scrollerRef={layoutContext.scroller}
                endpoint={Endpoints.PRODUCTCARD}
                filters={{username: responseData.username, all: true}}
                getKey={item => item.id}
                renderItem={renderItem}
                renderEmpty={() => <Empty msg="No product yet."/>}
            />
        </Container>
    </>)
}

export default UserDomain
