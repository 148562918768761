import React, { useContext } from "react"
import { StripePay } from "./StripeElement"
import Library from "../cellar/Library"
import { AuthDispatchContext } from "../../App"
import { Navigate } from "react-router-dom"
import { Container } from "reactstrap"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"



const CheckoutPay = () => {
    const authDispatchContext = useContext(AuthDispatchContext)

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    return (<>
        <SEO title={`Checkout Pay - ${SiteName}`} />

        <Container className='mt-5'>
            <StripePay>
                <Library/>
            </StripePay>
        </Container>
    </>)
}


export default CheckoutPay