import React, { useContext } from "react"
import { Offcanvas, OffcanvasHeader, OffcanvasBody, NavLink } from "reactstrap"
import { Navbar, NavbarBrand, NavbarToggler, Nav } from 'reactstrap'
import { Route, Routes } from 'react-router-dom'

import MenuCategory from "./MenuCategory"
import MenuProfile from "./MenuProfile"
import MenuStore from "./MenuStore"
import SiteTitle, { SiteName } from "../SiteTitle"
import { ProductMenu } from "../Product"
import { CellarMenu } from "../../containers/cellar/Cellar"
import { useDomainNavigate } from "../../tools/Subdomain"
import MenuCellar from "./MenuCellar"
import { MenuMarket } from "./MenuMarket"
import MenuLegal from "./MenuLegal"
import { CloudURL } from "../../tools/Cloud"
import { AuthDispatchContext } from "../../App"
import MenuLoneCellar from "./MenuLoneCellar"
import { FetchContext } from "../../hocs/Fetch"
import SearchBar from "../SearchBar"


const BGByDomain = (domain) => {
    return (false, '')
    // switch(domain) {
    //     case "blender":
    //         return {useBG:true, bg:'bgs/colorkit_01.webp'}

    //     case "unreal":
    //         return {useBG:true, bg:'bgs/colorkit_04.webp'}

    //     case "godot":
    //         return {useBG:true, bg:'bgs/colorkit_07.webp'}

    //     case "indiegames":
    //         return {useBG:true, bg:'bgs/colorkit_00.webp'}

    //     case "houdini":
    //         return {useBG:true, bg:'bgs/colorkit_08.webp'}

    //     case "3dsmax":
    //         return {useBG:true, bg:'bgs/colorkit_05.webp'}

    //     case "maya":
    //         return {useBG:true, bg:'bgs/colorkit_06.webp'}

    //     case "books":
    //         return {useBG:true, bg:'bgs/colorkit_03.webp'}
    // }

    // return (false, '')
}


const Menu = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const bgType = BGByDomain(authDispatchContext.domain)
    const fetchContext = useContext(FetchContext)

    const navigate = useDomainNavigate()
    const [offCanvas, setOffCanvas] = React.useState();

    const toggleOffCanvas = (event) => {
        setOffCanvas(!offCanvas)
    }

    const closeOffCanvas = (event) => {
        setOffCanvas(false)
    }

    const onHome = () => { closeOffCanvas(); navigate(`/`) }

    const currentProduct = (fetchContext.fetchs.product.isLoading || fetchContext.fetchs.product.hasError || !fetchContext.fetchs.product.responseData) ?
        '' : fetchContext.fetchs.product.responseData.name

    const bg = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3)), url(${CloudURL(bgType.bg)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // width: '200px',
        // height: '50px',
        // Add any additional styles as needed
    }

    return (        
        <div style={bg}>
            <Navbar // Mobile / Small
                color={bgType.useBG ? "faded" : 'light'}
                expand="md"
                // fixed="top"
                light
                className='d-sm-block d-md-none'
            >
                <NavbarToggler onClick={toggleOffCanvas} />

                <Nav
                    className={"me-auto ms-2"}
                    navbar
                >
                    <Routes>
                        {/* <Route exact path='/cellar' element={<></>} /> */}
                        <Route path='*' element={<MenuLoneCellar closeOffCanvas={closeOffCanvas} height={30}/>} />
                    </Routes>                    
                </Nav>

                <Nav
                    // className={"ms-auto me-auto"}
                    navbar
                >
                    <Routes>
                        <Route exact path='/product/:slug' element={<ProductMenu/>} />
                        <Route exact path='/cellar' element={<CellarMenu/>} />
                        <Route path='*' element={<></>} />
                    </Routes>
                </Nav>

                <Routes>
                    <Route exact path='/product/:slug' element={<></>} />
                    <Route exact path='/cellar' element={<></>} />
                    <Route path='*' element={
                        <NavbarBrand href='#' onClick={onHome} style={{fontSize:'1.3em'}}>
                            <SiteTitle showTitle={!authDispatchContext.isDomain}/>
                        </NavbarBrand>
                    } />
                </Routes>
                
            </Navbar>

            <Navbar // Large
                color={bgType.useBG ? "faded" : 'light'}
                expand="md"
                // fixed="top"
                light
                className='d-none d-md-block'
            >
                <NavbarBrand href='#' onClick={onHome}>
                    <SiteTitle/>
                </NavbarBrand>

                <Nav
                    className={"ms-3 me-2"}
                    navbar
                >
                    <Routes>
                        <Route exact path='/product/:slug' element={<ProductMenu/>} />
                        <Route exact path='/cellar' element={<CellarMenu/>} />
                        <Route path='*' element={<></>} />
                    </Routes>
                </Nav>

                {authDispatchContext.isDomain &&
                <Nav className="flex-fill me-3">
                    <SearchBar filtername='names'/>
                </Nav>}

                <Nav
                    className={"me-1"}
                    navbar
                >
                    <MenuLoneCellar imgCol={true}/>
                    <MenuMarket/>
                    <MenuProfile/>
                </Nav>

            </Navbar>

            <Offcanvas
                isOpen={offCanvas}
                scrollable
                toggle={toggleOffCanvas}
            >
                <OffcanvasHeader toggle={toggleOffCanvas}>
                    <NavLink href='#' onClick={onHome}>
                        <SiteTitle offCanvas={true} showTitle={!authDispatchContext.isDomain}/>
                    </NavLink>
                </OffcanvasHeader>

                <OffcanvasBody>
                    {/* <div className="bg-light p-3"> */}

                    {authDispatchContext.isDomain &&
                    <Nav className="mb-3">
                        <SearchBar filtername='names' closeOffCanvas={closeOffCanvas}/>
                    </Nav>}

                    <Nav
                        vertical
                        // className={"ms-auto me-1"}
                        // navbar
                        className="bg-current-menu p-3 mb-3"
                    >

                        <Routes>
                            <Route exact path='/store/*' element={<MenuStore showTitle={true} closeOffCanvas={closeOffCanvas}/>} />
                            <Route exact path='/cellar/*' element={<MenuCellar showTitle={true} closeOffCanvas={closeOffCanvas}/>} />
                            <Route exact path='/search' element={<MenuCategory closeOffCanvas={closeOffCanvas} filtername='tags'/>} />
                            <Route exact path='/legal/*' element={<MenuLegal showTitle={true} closeOffCanvas={closeOffCanvas}/>} />
                            <Route exact path='/product/:slug' element={<h6>{currentProduct}</h6>}/>
                            <Route exact path='/signup/*' element={<h6>Sign Up</h6>} />
                            <Route exact path='/login/*' element={<h6>Log In</h6>} />
                            <Route exact path='/profile/*' element={<h6>Profile</h6>} />
                            <Route exact path='/about/*' element={<h6>About</h6>} />
                            <Route exact path='/philosophy/*' element={<h6>Philosophy</h6>} />
                            <Route exact path='/news/*' element={<h6>News</h6>} />
                            <Route exact path='/faqs/*' element={<h6>FAQs</h6>} />
                            {authDispatchContext.isDomain && <Route exact path='/' element={<MenuCategory closeOffCanvas={closeOffCanvas} filtername='tags'/>} />}
                            {!authDispatchContext.isDomain && <Route exact path='/' element={<h6>{authDispatchContext.isUser ? authDispatchContext.domain : SiteName}</h6>} />}
                        </Routes>
                    </Nav>                    
                    {/* </div> */}

                    <Nav
                        vertical
                        // className={"ms-auto me-1"}
                        // navbar
                        // className="mb-2 ms-3"
                        className="bg-light p-3"
                    >
                        <MenuProfile closeOffCanvas={closeOffCanvas} vertical/>
                        <MenuMarket closeOffCanvas={closeOffCanvas} vertical/>
                    </Nav>                    
                </OffcanvasBody>

            </Offcanvas>
        </div>
    )
}

export default Menu