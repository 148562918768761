import { Helmet } from "react-helmet-async"
import { CloudURL } from "../tools/Cloud"
import { SiteName } from "./SiteTitle"



const GetDescByDomain = (domain) => {
    const lDomain = domain.toLowerCase()

    const desc = `Welcome to ${SiteName}: The Marketplace that Empowers Purchasing Power Parity (PPP) and Ensures Fair Visibility!`

    const descByDomain = {
        'blender': `Exclusive hub for selling and buying Blender assets at Fair Prices`,
        'unreal': `Exclusive hub for selling and buying Unreal Engine assets at Fair Prices`,
        'godot': `Exclusive hub for selling and buying Godot assets at Fair Prices`,
        'indiegames': `Exclusive hub for selling and buying Indie Games at Fair Prices`,
        '3dsmax': `Exclusive hub for selling and buying 3DS Max assets at Fair Prices`,
        'maya': `Exclusive hub for selling and buying Maya assets at Fair Prices`,
        'books': `Exclusive hub for selling and buying eBooks at Fair Prices`,
        'houdini': `Exclusive hub for selling and buying Houdini assets at Fair Prices`,
        '3dmodels': "Exclusive hub for selling and buying 3D Models at Fair Prices",
        'fiberart': "Exclusive hub for selling and buying patterns for sewing, quilting, needle point, macrame, weaving, felting, crocheting, knitting, embroidery, rug-making, basket weaving, etc... at Fair Prices"
    }

    if (lDomain in descByDomain) {
        return `${descByDomain[lDomain]} - ${desc}`
    }

    return desc
}



export const SEO = ({title='', description='', domain='', canonical=undefined, img=''}) => {

    const useCanonical = canonical !== undefined
    const cano = useCanonical ? canonical : ''
    const url = domain ? `https://${domain}.${process.env.REACT_APP_FRONT_DOMAIN}${cano}` : `https://${process.env.REACT_APP_FRONT_DOMAIN}${cano}`
    const imgUrl = img ? img : CloudURL('missing_thumbnail.jpeg')

    const desc = description ? description : GetDescByDomain(domain)

    return (
        <Helmet>
            {/* General */}
            {title && <title>{title}</title>}
            {desc && <meta
                name="description"
                content={desc}
            />}
            {title && <meta name="title" content={title}/>}
            <meta name="image" content={imgUrl}/>
            <meta name="type" content="article"/>
            <meta name="url" content={url}/>
            <meta name="author" content="@FairMarketLand"/>
            {useCanonical && <link rel="canonical" href={url}/>}


            {/* Twitter */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={imgUrl} />
            {title && <meta
                name="twitter:title"
                content={title}
            />}
            <meta name="twitter:creator" content="@FairMarketLand" />
            <meta name="twitter:site" content="@FairMarketLand" />
            {desc && <meta
                name="twitter:description"
                content={desc}
            />}


            {/* Facebook */}
            <meta property="fb:app_id" content="1424005828178010" />
            <meta property="og:updated_time" content={Math.floor(Date.now() / 1000).toString()} />
            <meta property="article:modified_time" content={Math.floor(Date.now() / 1000).toString()} />            
            <meta property="og:type" content="article" />
            <meta property="og:url" content={url} />
            {title && <meta
                property="og:title"
                content={title}
            />}
            {desc && <meta
                property="og:description"
                content={desc}
            />}
            <meta property="og:image" content={imgUrl}/>
            <meta property="og:image:url" content={imgUrl}/>
            <meta property="og:image:secure_url" content={imgUrl}/>
            <meta property="og:image:type" content="image/jpeg"/>
        </Helmet>
    )
}