
import { NavItem, NavLink } from "reactstrap"
import { useDomainNavigate } from "../../tools/Subdomain";
import { useContext } from "react";
import { AuthDispatchContext } from "../../App";
import { CellarReplace } from "../CellarReplace";



const MenuLoneCellar = ({vertical=false, closeOffCanvas=()=>{}, imgCol=false, height=24}) => {
    const authDispatchContext = useContext(AuthDispatchContext)

    const navigate = useDomainNavigate()
    const onCellarDoor = () => { closeOffCanvas(); navigate(`/cellar`) }

    if (!authDispatchContext.state.isAuthenticated) {
        return (<></>)
    }

    // if (vertical) {
    //     return (
    //         <div className="mb-3 pt-3 text-end">
    //             <NavItem key='Cellar'>
    //                 <NavLink href='#' onClick={onCellarDoor}>
    //                     <CellarReplace useImg={true}/>
    //                 </NavLink>
    //             </NavItem>
    //         </div>
    //     )
    // }

    return (
        <NavItem key='Cellar'>
            <NavLink href='#' onClick={onCellarDoor}>
                <CellarReplace useImg={true} imgCol={imgCol} height={height}/>
            </NavLink>
        </NavItem>
    )
}

export default MenuLoneCellar


