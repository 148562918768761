import React from "react"
import { Label } from "reactstrap"



export const Switch = ({text='', imgOn='', imgOff='', maxHeight=24, id, name, checked, onChange, disabled=false}) => {
    return (
        <label
            style={{cursor:'pointer'}}
        >
            <input
                type="checkbox"
                onChange={onChange}
                style={{visibility:"hidden"}}
                checked={checked}
                id={id}
                name={name}
                disabled={disabled}
            />
            <Label htmlFor={id}>
                {checked && <img alt='' src={imgOn} style={{maxHeight:`${maxHeight}px`}}/>}
                {!checked && <img alt='' src={imgOff} style={{maxHeight:`${maxHeight}px`}}/>}
                {text}
            </Label>
        </label>
    )
}


export const SwitchOverlay = ({text='', imgOn='', imgOff='', width=24, height=24, id, name, checked, onChange, disabled=false}) => {

    const spanStyle = {
        // backgroundImage: `url(${backgroundImage})`,
        // backgroundSize: 'contain',
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: 'rgba(1, 1, 1, 0.5)',
        width: `40px`,
        // height: `${height}px`,
        display:'inline-block',
        fontWeight: 900,
        fontSize: '1.5em',
        verticalAlign: 'middle',
        /* padding-right: 0.2em; */
        /* padding-bottom: 0.2em; */

        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    }

    const imgStyle = {
        maxHeight: `${height}px`,

        webkitTouchCallout: 'none',
        webkitUserSelect: 'none',
        khtmlUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    }


    return (
        <label
            style={{cursor:'pointer'}}
        >
            <input
                type="checkbox"
                onChange={onChange}
                style={{visibility:"hidden"}}
                checked={checked}
                id={id}
                name={name}
                disabled={disabled}
            />

            <span style={spanStyle} className="text-primary">
                {checked && <>&#x2713;</>}
                {!checked && <>&nbsp;</>}
            </span>

            {checked && <img alt='' src={imgOn} style={imgStyle}/>}
            {!checked && <img alt='' src={imgOff} style={imgStyle}/>}
        </label>
    )
}