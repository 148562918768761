import React, { useContext, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Row, Col } from 'reactstrap';

import { AuthDispatchContext } from '../App'
import { LayoutContext } from "../hocs/Layout";
import * as Request from "../actions/Request";
import { Switch, SwitchOverlay } from "../tools/Switch";

import { format, parseISO } from 'date-fns'
// import dayjs from 'dayjs'

import { FormattedNumber } from 'react-intl'
import { CloudURL } from '../tools/Cloud'
// import { DatePicker, Space } from "antd";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { UserUrl, useDomainNavigate } from "../tools/Subdomain"

import * as Enpoints from '../Endpoints'
import { CellarName } from "./CellarReplace";


export const featNotif = false
export const featReminder = false


const ProductCellar = ({cellarProduct, id}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    const navigate = useDomainNavigate()
    const [cellar, setCellar] = useState({
        incheckout: cellarProduct.incheckout,
        notifications: cellarProduct.notifications,
        reminderactive: cellarProduct.reminderactive,
        reminderdate: cellarProduct.reminderdate,
    })

    const [disabled, setDisabled] = useState(false)

    // const { responseData:responseDataUser, isLoading:isLoadingUser, hasError:hasErrorUser } = useRequest(authDispatchContext.dispatch, `${Enpoints.ACCOUNTS_USER}${cellarProduct.product.user}/`, { useCache:true })
    // const { responseData:responseDataFile, isLoading:isLoadingFile, hasError:hasErrorFile } = useRequest(authDispatchContext.dispatch, `${Enpoints.PRODUCTFILE}?filetype=THU&product=${cellarProduct.product.id}`, { useCache:true })
    // const { responseData:responseDataProduct, isLoading:isLoadingProduct, hasError:hasErrorProduct } = useRequest(authDispatchContext.dispatch, `${Enpoints.PRODUCTPRICE}${cellarProduct.product.slug}/`, { useCache:true })
    
    const onMouseDwn = (e) => {
        if (e.button === 1) {
            e.preventDefault()
        }
    }

    const onClickProduct = (event) => {
        event.preventDefault()
        navigate(`/product/${cellarProduct.product.slug}`)
    }

    const onAuxClickProduct = (e) => {
        if (e.button !== 1) {
            return
        }

        if (e.target.className === 'card-link') {
            return
        }
        
        window.open(`/product/${cellarProduct.product.slug}`, '_blank', 'noreferrer')
    }

    const onClickUser = (event) => {
        event.preventDefault()
        // navigate(`/user/${responseDataUser.username}`)
        navigate('', cellarProduct.product.username, true)
    }

    async function handleOnChangeBool(event) {
        setCellar({
            ...cellar,
            [event.target.name]: event.target.checked
        })
        // setIsChecked(!isChecked)

        try {
            const params = {
                [event.target.name]: event.target.checked,
                // incheckout:!isChecked
            }
            await Request.authRequest(authDispatchContext.dispatch, 'patch', `${Enpoints.CELLAREDIT}${cellarProduct.id}/`, params)
        }
        catch (error) {
            // throw error
        }
    }

    async function handleOnChangeDate(date) {
        setCellar({
            ...cellar,
            reminderdate: format(date, 'yyyy-MM-dd')
        })
        // setIsChecked(!isChecked)

        try {
            const params = {
                reminderdate: format(date, 'yyyy-MM-dd'),
                // incheckout:!isChecked
            }
            await Request.authRequest(authDispatchContext.dispatch, 'patch', `${Enpoints.CELLAREDIT}${cellarProduct.id}/`, params)
        }
        catch (error) {
            // throw error
        }
    }


    async function remove() {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'delete', `${Enpoints.CELLAREDIT}${cellarProduct.id}/`)

            layoutContext.setNotifContent({message:`Removed from ${CellarName}!`})
            setDisabled(true)
        }
        catch (error) {
            layoutContext.setNotifContent({title:'Error', message:`Cannot delete from ${CellarName}.`})
            // throw error
        }
    }

    async function undo() {
        try {
            const params = {
                slug: cellarProduct.product.slug,
            }
            const resp = await Request.authRequest(authDispatchContext.dispatch, 'post', Enpoints.CELLAREDIT, params)

            cellarProduct.id = resp.data.id

            layoutContext.setNotifContent({message:`Added to ${CellarName}!`})
            setDisabled(false)
        }
        catch (error) {
            layoutContext.setNotifContent({title:'Error', message:`Cannot add to ${CellarName}.`})
            // throw error
        }
    }

    // const hasImage = !isLoadingFile && !hasErrorFile && responseDataFile.length>0
    // const hasBy = !isLoadingUser && !hasErrorUser
    // const hasPrice = !isLoadingProduct && !hasErrorProduct

    return (
        <Card className='m-2' color="light">
            <CardHeader>
                <Row>
                    <Col>
                        {!disabled && <Button color="danger" outline size="sm" onClick={remove}>Remove</Button>}
                        {disabled && <Button color="warning" outline size="sm" onClick={undo}>Undo</Button>}
                    </Col>

                    <Col className="text-end">

                        {featNotif && <Switch
                            id="notifications"
                            name="notifications"
                            checked={cellar.notifications}
                            onChange={handleOnChangeBool}
                            imgOn={process.env.PUBLIC_URL + '/imgs/mail_on.png'}
                            imgOff={process.env.PUBLIC_URL + '/imgs/mail_off.png'}
                            disabled={disabled}
                        />}

                        {featReminder && <Switch
                            type="checkbox"
                            id="reminderactive"
                            name="reminderactive"
                            checked={cellar.reminderactive}
                            onChange={handleOnChangeBool}
                            imgOn={process.env.PUBLIC_URL + '/imgs/bell_on.png'}
                            imgOff={process.env.PUBLIC_URL + '/imgs/bell_off.png'}
                            disabled={disabled}
                        />}
                    </Col>

                    <Col xs="auto" className="text-end ps-0">
                        <SwitchOverlay
                            type="checkbox"
                            id="incheckout"
                            name="incheckout"
                            checked={cellar.incheckout}
                            onChange={handleOnChangeBool}
                            imgOn={process.env.PUBLIC_URL + '/imgs/cart_on.png'}
                            imgOff={process.env.PUBLIC_URL + '/imgs/cart_off.png'}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </CardHeader>


            <CardImg
                alt="Card image cap"
                src={CloudURL(cellarProduct.product.thumbnail)}
                top
                style={{aspectRatio:'36/9', objectFit:'cover', opacity:(disabled ? 0.3 : 1), cursor: "pointer" }}
                onMouseDown={onMouseDwn}
                onClick={onClickProduct}
                onAuxClick={onAuxClickProduct}
                onError={(e) => { e.target.src = CloudURL('missing_thumbnail.jpeg') }}
            />

            <CardBody>
                <CardTitle tag="h5" style={{minHeight: '3em'}}>
                    <span
                        onMouseDown={onMouseDwn}
                        onClick={onClickProduct}
                        onAuxClick={onAuxClickProduct}
                        style={{ cursor: "pointer" }}
                    >
                        {cellarProduct.product.name}
                    </span> 
                </CardTitle>
            {/* <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
            >
                Card subtitle
            </CardSubtitle> */}
            
                {featReminder && <DatePicker
                    showIcon
                    selected={parseISO(cellar.reminderdate)}
                    onChange={handleOnChangeDate}
                    // onClick={handleButtonClick}
                />}

                <Row>
                    <Col>
                        <CardText>
                            by <CardLink onClick={onClickUser} href={UserUrl(cellarProduct.product.username)} style={{ cursor: "pointer" }}>{cellarProduct.product.username}</CardLink>
                        </CardText>
                    </Col>

                    <Col xs='auto' className="text-end" tag="h5">
                        <FormattedNumber                    
                            value={cellarProduct.product.fairprice}
                            style={`currency`}
                            currency={cellarProduct.product.currency}
                            maximumFractionDigits='0'
                        />
                    </Col>
                </Row>

            </CardBody>
        </Card>
    )
}


export default ProductCellar;
