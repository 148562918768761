import React from "react";
import { FormattedNumber } from "react-intl";

import { CloudURL } from "../../tools/Cloud";



const ProductCheckout = ({product}) => {

    // const { responseData:responseDataFile, isLoading:isLoadingFile, hasError:hasErrorFile } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTFILE}?filetype=THU&product=${product.id}`, { useCache:true })
    // const { responseData:responseDataProduct, isLoading:isLoadingProduct, hasError:hasErrorProduct } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTPRICE}${product.slug}/`)

    // const hasImage = !isLoadingFile && !hasErrorFile && responseDataFile.length>0
    // const hasPrice = !isLoadingProduct && !hasErrorProduct

    return (
        <tr>
            <th scope="row">
                 <img
                    alt="Card cap"
                    src={CloudURL(product.thumbnail)}
                    height={40}
                    className="product-checkout-cover"
                    onError={(e) => { e.target.src = CloudURL('missing_thumbnail.jpeg') }}
                />
            </th>
            <td>
                {product.name}
            </td>
            <td>
                <div>
                    <FormattedNumber                    
                        value={product.product.fairprice}
                        style={`currency`}
                        currency={product.product.currency}
                        maximumFractionDigits='2'
                    />
                    {product.product.tax_rate !== 0 && <>
                        &nbsp;(
                        <FormattedNumber                    
                            value={product.product.fairprice * (1 - 1.0 / (1 + product.product.tax_rate))}
                            style={`currency`}
                            currency={product.product.currency}
                            maximumFractionDigits='2'
                            />
                        <span style={{ textTransform: 'uppercase'}}>
                            &nbsp;{product.product.tax} {product.product.tax_rate * 100}%)
                        </span>
                    </>}
                </div>
            </td>
        </tr>
    )
}



export default ProductCheckout;
