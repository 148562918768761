import { Badge, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import parse from 'html-react-parser'
import { useContext, useState } from "react"
import * as Endpoints from '../Endpoints'
import { AuthDispatchContext } from "../App"
import { LayoutContext } from "../hocs/Layout"
import * as Request from "../actions/Request"
import { FormattedDate } from "react-intl"
import DragDropText from "./DragDropText"



const parseDate = (date) => {
    if (!date) {
        return ''
    }

    const d = new Date(date)
    const strD = d.toISOString()
    
    return strD.substring(0, strD.indexOf('T'))
}

const parseTime = (date) => {
    if (!date) {
        return ''
    }
    
    const d = new Date(date)
    d.setSeconds(0, 0)
    let strD = d.toISOString()
    
    strD = strD.substring(strD.indexOf('T') + 1)

    return strD.substring(0, strD.indexOf('Z'))
}

const mergeDateTime = (date, time) => {
    if (!date || !time) {
        return null
    }

    return `${date}T${time}Z`
}


export const EditNewsArticle = ({article=undefined}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    const [formValue, setformValue] = useState({
        title: article ? article.title : '',
        slug: article ? article.slug : '',
        description: article ? article.description : '',
        hashtags: article ? article.hashtags : '',
        schedule: article ? article.schedule : null
    })

    const [date, setDate] = useState(article ? parseDate(article.schedule) : '')
    const [time, setTime] = useState(article ? parseTime(article.schedule) : '')

    const [modalDeleteNews, setModalDeleteNews] = useState(false)

    const toggleDeleteNews = () => setModalDeleteNews(!modalDeleteNews)

    const handleChangeDescription = (description) => {
        setformValue({
            ...formValue,
            description: description
        })
    }


    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        })

        if (event.target.name === 'title' && (!article || !article.published)) {
            setformValue({
                ...formValue,
                title: event.target.value,
                slug: event.target.value.replace(/\W+/g, '-')
            })
        }
        else if (event.target.name === 'slug') {
            setformValue({
                ...formValue,
                slug: event.target.value.replace(/\W+/g, '-')
            })
        }
    }


    const handleChangeDate = (event) => {
        setDate(event.target.value)

        let t = time
        if (!time) {
            t = '16:00'
            setTime(t)
        }

        formValue.schedule = mergeDateTime(event.target.value, t)

        if (!formValue.schedule) {
            setTime('')
        }
    }


    const handleChangeTime = (event) => {
        setTime(event.target.value)
        formValue.schedule = mergeDateTime(date, event.target.value)

        if (!formValue.schedule) {
            setDate('')
        }
    }


    const handleDelete = async() => {
        toggleDeleteNews()

        try {
            await Request.authRequest(authDispatchContext.dispatch, 'delete', `${Endpoints.NEWS}${article.id}/`)
            window.location.reload(false)
            
            layoutContext.setNotifContent({message:'Deleted!'})
        }
        catch(error) {
            layoutContext.setNotifContent({title:'Error', message:'Cannot delete this news.'})
        }
    }


    const handleSubmit = async(event) => {
        event.preventDefault()

        const params = {
            ...formValue,
            // user: authDispatchContext.state.user.id
        }

        try {
            if (article) {
                await Request.authRequest(authDispatchContext.dispatch, 'patch', `${Endpoints.NEWS}${article.id}/`, params)
            }
            else {
                await Request.authRequest(authDispatchContext.dispatch, 'post', `${Endpoints.NEWS}`, params)
                window.location.reload(false)
            }
            
            layoutContext.setNotifContent({message:'Saved!'})
        }
        catch(error) {
            layoutContext.setNotifContent({title:'Error', message:'Changes not saved.'})
        }
    }

    return (<>
        <Modal isOpen={modalDeleteNews} toggle={toggleDeleteNews}>
            <ModalHeader toggle={toggleDeleteNews}>Delete News</ModalHeader>
            <ModalBody>
                Are you sure you want to delete this news?
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleDeleteNews}>
                    Cancel
                </Button>
                <Button color="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>

        <Container className="mt-3 mb-5">
            <Form onSubmit={handleSubmit}>
                {/* <Label tag='h5' className="mt-3">Title</Label> */}
                <Input
                    className='form-control'
                    type="text"
                    name="title"
                    placeholder="News Title"
                    value={formValue.title}
                    onChange={handleChange}
                    required
                />

                <FormGroup row>
                    <Label xs={1}>Slug</Label>
                    <Col>
                        <Input
                            className='form-control'
                            type="text"
                            name="slug"
                            placeholder="Slug"
                            value={formValue.slug}
                            onChange={handleChange}
                            required
                            disabled={article && article.published}
                        />
                    </Col>
                </FormGroup>


                {article && article.date && <FormattedDate
                    value={article.date}
                    day="numeric"
                    month="long"
                    year="numeric"
                />}

                {/* <Label tag='h2' className="mt-3">Description</Label> */}
                <DragDropText 
                    value={formValue.description}
                    onChange={handleChangeDescription}
                ></DragDropText>
                {/* <ReactQuill
                    // ref={quillRef}
                    theme="snow"
                    value={formValue.description}
                    onChange={handleChangeDescription}
                    modules={{
                        toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline','strike', 'blockquote'],
                            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                            ['link'],
                            ['clean']
                        ]
                    }}
                /> */}

                <Input
                    className='form-control'
                    type="text"
                    name="hashtags"
                    placeholder="Hashtags"
                    value={formValue.hashtags}
                    onChange={handleChange}
                    required
                />

                <Row>
                    <Col>
                        {article && <Button color='danger' onClick={toggleDeleteNews}>Delete</Button>}
                    </Col>

                    <Col xs='auto' className='text-end'>
                        <FormGroup inline>
                            {article && article.published && <>
                                <Badge color="success">Published!</Badge>

                                <Button color='warning' type="submit">
                                    Update
                                </Button>
                            </>}

                            {(!article || !article.published) && <>
                                UTC&nbsp;
                                <Input
                                    id="exampleDate"
                                    name="date"
                                    placeholder="date placeholder"
                                    type="date"
                                    className="w-auto d-inline-block"
                                    value={date}
                                    onChange={handleChangeDate}
                                />

                                <Input
                                    id="exampleTime"
                                    name="time"
                                    placeholder="time placeholder"
                                    type="time"
                                    className="w-auto d-inline-block"
                                    value={time}
                                    onChange={handleChangeTime}
                                />

                                <Button color="primary" type="submit">
                                    Save
                                </Button>
                            </>}
                        </FormGroup>
                    </Col>
                </Row>

            </Form>
        </Container>
    </>)
}



const NewsArticle = ({article}) => {    
    return (
        <Container className="mt-3 mb-5">
            <h3>{article.title}</h3>

            <span style={{fontSize:'0.75rem', color:'#6d6e78'}}>
                <FormattedDate
                    value={article.date}
                    day="numeric"
                    month="long"
                    year="numeric"
                />
            </span>

            <div>
                {parse(article.description)}
            </div>
        </Container>
    )
}



export default NewsArticle