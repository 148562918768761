import React from "react"
import { Col, Container, Row } from "reactstrap"
import { CloudURL } from "../tools/Cloud"
import { SiteName, SiteSocialNetworks } from "../components/SiteTitle"
import { SEO } from "../components/SEO"



const Philosophy = () => {

    return (<>
        <SEO title={`Philosophy - ${SiteName}`} />

        <Container className='mt-5 mb-5' style={{ maxWidth:960 }}>
            <Row>
                <Col xs="auto">
                    <h2>
                        <img
                            src={CloudURL('logo64.webp')}
                            style={{verticalAlign:'bottom', height:'1.2em'}}
                            className="mx-2"
                            alt=''
                        />
                        {SiteName}'s Philosophy
                    </h2>
                </Col>

                <Col style={{minWidth:'100px'}} className="text-end">
                    <h3>
                        <SiteSocialNetworks/>
                    </h3>    
                </Col>
            </Row>

            <p>At <i>{SiteName}</i>, we believe that everyone should have equal access to goods and services, regardless of their geographical location or economic status. Our unique approach combines the Local Purchasing Power Index (LPPI) and up-to-date Exchange Rates to ensure that prices are fair and reflect the true purchasing power of individuals in different regions.</p>
            
            <br/>

            <h6>Relative Purchasing Power</h6>
            <p>We understand that economic disparities exist globally, and traditional marketplaces often fail to consider these differences. However, at <i>{SiteName}</i>, we strive to level the playing field by taking into account the LPPI. This index provides us with valuable insights into the relative purchasing power of consumers in different regions, allowing us to determine fair prices for products and services.</p>
            
            <br/>

            <h6>Fair Visibility</h6>
            <p>In addition to PPP, we place a strong emphasis on fair visibility. We believe that all sellers should have equal opportunities to showcase their products and reach potential buyers. To achieve this, we have developed a sophisticated algorithm that calculates visibility fairness based on a combination of factors, including Print, View, Purchase, and Time. This formula ensures that every seller has a fair chance to present their offerings to interested buyers, promoting a balanced and inclusive marketplace.</p>

            <br/>

            <h6>Fair Compensation: No Free Products</h6>
            <p>Our decision not to offer free products is rooted in a fundamental principle: valuing the dedication and expertise invested by professionals in creating their offerings. Each product on our platform represents not just an item but a culmination of effort, time, and skill, deserving fair compensation.</p>
            <p>By exclusively listing priced products, <i>{SiteName}</i> acknowledges and respects the commitment professionals make to their craft. This stance underscores the belief that their work contributes significantly to their livelihood and the overall quality of life.</p>
            <p>While free products have their merits, such as attracting users, our commitment to upholding fair compensation stands as a cornerstone of our values. Prioritizing fair payment aligns with our mission to uphold the worth of creators' contributions and maintains a standard of excellence and respect within our marketplace.</p>

            <br/>

            <h6>Year-Round Pricing: No Event Sales</h6>
            <p>Our commitment to fair pricing year-round is in line with our mission to cultivate a discerning community that values quality and sustainability. By abstaining from sales tactics that promote impulse buying and often result in overselling, we ensure a consistent, fair value for all customers. This approach not only attracts a better quality of customers—those who prioritize value over discounts—but also prevents situations where products not on sale are unfairly priced due to short-term promotions.</p>

            <br/>

            <h6>Single-tier Pricing</h6>
            <p><i>{SiteName}</i> refrains from tiered pricing structures because accurately distinguishing between different user tiers—whether they're a company, indie developer, or hobbyist—poses significant challenges. For instance, within a company setting, if the product isn't available in the market, it could cost substantially more than the listed price. However, verifying these distinctions becomes complex and prone to exploitation, leading to potential unfairness. To ensure fairness and equal access for all users, <i>{SiteName}</i> opts for a uniform pricing strategy, providing the same advantages to everyone and offering complete access upfront. This approach particularly benefits indie developers who might face obstacles in multi-tier pricing models, acknowledging the difficulty in monitoring and distinguishing usage contexts.</p>

            <br/>
            <br/>

            <p>At <i>{SiteName}</i>, our commitment to transparency and equity resonates through every facet of our platform, ensuring an environment where buyers and sellers thrive equally. Our emphasis on accurate pricing and fair visibility underscores the bedrock of our marketplace. Leveraging the principles of the Local Purchasing Power Index (LPPI) and our algorithm-based fair visibility metrics, we empower individuals and communities globally by acknowledging economic disparities and providing fair access. Furthermore, our stance on fair compensation, rejecting free products to honor creators' dedication, upholds the value of craftsmanship and expertise. By maintaining year-round fair pricing without event-driven sales tactics, we cultivate a discerning community that values quality and sustainability over short-term discounts. Our single-tier pricing strategy, inspired by the challenges of accurately differentiating user tiers and the commitment to fairness, ensures equal opportunities for all, particularly supporting indie developers. <i>{SiteName}</i> remains dedicated to fostering a transparent, equitable marketplace that uplifts both creators and consumers, fostering a global economy where everyone can participate on an equal footing.</p>
        </Container>
    </>)
}

export default Philosophy