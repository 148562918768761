import React, { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router"
import { useParams } from "react-router-dom"
import { Button, Form, Input, Label, Container, Row, Col, InputGroup, InputGroupText, UncontrolledCollapse, FormGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap"

import { AuthDispatchContext } from '../../App'
import { useRequest } from "../../hooks/Hooks"

import Loading from "../../components/Loading"
import DragDropUploader from "../../components/DragDropUploader"

import * as Request from '../../actions/Request'
import { CloudURL, BackURL, FrontURL } from '../../tools/Cloud'

import 'react-quill/dist/quill.snow.css'

import * as Storage from '../../tools/Storage'
import { useIntl } from "react-intl"
import { LayoutContext } from "../../hocs/Layout"
import { useDomainNavigate } from "../../tools/Subdomain"
import DragDropText from "../../components/DragDropText"

import * as Endpoints from '../../Endpoints'
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"
import { EmbedCode } from "../../components/Embed"



const PriceHelper = ({Toggler=false, currency}) => {
    const units = {
        'Hour': 1,
        'Day': 8,
        'Week': 8*5,
        'Month': 8*5*4.325,
        'Year': 8*5*4.325*12
    }
    const [unit, setUnit] = useState(Object.entries(units)[4])
    const [amount, setAmount] = useState()
    const [unitSpent, setUnitSpent] = useState(Object.entries(units)[1])
    const [amountSpent, setAmountSpent] = useState()
    const [sales, setSales] = useState()
    const [price, setPrice] = useState()


    useEffect(() => {
        const hrate = amount / unit[1]
        const nbh = amountSpent * unitSpent[1]
        const cost = hrate * nbh
        const price = cost / sales

        if (!price || !sales) {
            setPrice('')
        }
        else {
            setPrice(price.toFixed(2))
        }
    }, [unit, amount, unitSpent, amountSpent, sales])


    if (Toggler) {
        return (
            <Button
                className="m-1"
                color="light"
                id="PriceHelperToggle"
                // outline
                size="sm"
            >
                Toggle Price Helper
            </Button>
        )
    }

    return (
        <UncontrolledCollapse toggler="#PriceHelperToggle">
            <Alert color="info">
                <span check style={{fontSize:'0.75rem', color:'#6d6e78'}} className="mb-3">
                    The Price Helper assists in determining the ideal selling price for your product.<br/><br/>
                    The most challenging factor to consider is the <b>'Projected Number of Sales'</b> (which shouldn't rely solely on the product's potential visibility).
                    Instead, focus on estimating <b>'How many sales similar products achieve in 1 year'</b> (strive for comparable quality, field, etc).
                </span>

                <InputGroup className="mt-3">
                    <InputGroupText>{currency}</InputGroupText>
                    <Input
                        className='form-control'
                        type="float"
                        placeholder="Salary Amount"
                        value={amount}
                        onChange={(e) => {e.preventDefault(); setAmount(e.target.value)}}
                    />

                    <UncontrolledDropdown>
                        <DropdownToggle caret>
                            {unit[0]}
                        </DropdownToggle>

                        <DropdownMenu container="body">
                            {Object.entries(units).map((item) => (
                                <DropdownItem onClick={() => setUnit(item)}>
                                    {item[0]}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </InputGroup>

                <InputGroup>
                    <Input
                        className='form-control'
                        type="float"
                        placeholder="Time Spent"
                        value={amountSpent}
                        onChange={(e) => {e.preventDefault(); setAmountSpent(e.target.value)}}
                    />

                    <UncontrolledDropdown>
                        <DropdownToggle caret>
                            {unitSpent[0]}
                        </DropdownToggle>

                        <DropdownMenu container="body">
                            {Object.entries(units).map((item) => (
                                <DropdownItem onClick={() => setUnitSpent(item)}>
                                    {item[0]}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </InputGroup>

                <InputGroup>
                    <InputGroupText>Sales</InputGroupText>
                    <Input
                        className='form-control'
                        type="int"
                        placeholder="Annual Estimated Sales"
                        value={sales}
                        onChange={(e) => {e.preventDefault(); setSales(e.target.value)}}
                    />
                </InputGroup>

                <InputGroup>
                    <InputGroupText>{currency}</InputGroupText>
                    <Input
                        className='form-control'
                        type="float"
                        placeholder="Price"
                        value={price}
                    />
                </InputGroup>
            </Alert>
        </UncontrolledCollapse>
    )
}


const ProductEdit = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const navigate = useDomainNavigate()
    const { slug } = useParams()

    const [cover, setCover] = useState()
    const [thumbnail, setThumbnail] = useState()
    const [files, setFiles] = useState([])
    const [noStore, setNoStore] = useState(false)
    const [noTag, setNoTag] = useState(false)
    const [slugError, setSlugError] = useState(false)
    const [summaryError, setSummaryError] = useState(false)
    const [usaConv, setUsaConv] = useState(0)
    const [usaPrice, setUsaPrice] = useState(0)
    const [sketchfabID, setSketchfabID] = useState()

    const [sketchfabURL, setSketchfabURL] = useState('')
    const [modalSketchfab, setModalSketchfab] = useState(false)

    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTDETAIL}${slug}/`, { auth:true })
    const { responseData:responseCov, reFetch:reFetchCov } = useRequest(authDispatchContext.dispatch, '', { firstCall:false })
    const { responseData:responseThu, reFetch:reFetchThu } = useRequest(authDispatchContext.dispatch, '', { firstCall:false })
    const { responseData:responseDataFile, reFetch } = useRequest(authDispatchContext.dispatch, '', { firstCall:false })
    // const { responseData:responseDataTags, isLoading:isLoadingTags, hasError:hasErrorTags } = useRequest(authDispatchContext.dispatch, Endpoints.STORE_TAGS, { method:'post', useCache:true })

    const intl = useIntl()
    const [currency, setCurrency] = useState('')

    const [formValue, setformValue] = React.useState({
        active: false,
        affiliation: false,
        name: '',
        description: '',
        sketchfab: '',
        slug: '',
        summary: '',
        price: '',
        stores: [],
        tags: []
    })

    const [initialForm, setInitialForm] = React.useState({
    })

    const haveCommonElement = (arr1, arr2) => {
        const set1 = new Set(arr1);
        return arr2.some(element => set1.has(element));
    }

    const updateCategoriesAndTags = () => {
        const cats = responseData.alltags.categories
        const tgs = responseData.alltags.tags

        var c_ids = []
        var ts = []

        for (var t of tgs) {
            if (haveCommonElement(t.store_ids, formValue.stores)) {
                ts.push(t)

                if (!c_ids.includes(t.category_id)) {
                    c_ids.push(t.category_id)
                }
            }
        }

        var cs = []
        for (var c of cats) {
            if (c_ids.includes(c.id)) {
                cs.push(c)
            }

        }

        setCategories(cs)
        setTags(ts)
    }

    const toggleModalSketchfab = () => setModalSketchfab(!modalSketchfab)

    const onGetSketchfabID = async() => {
        toggleModalSketchfab()

        try {
            const params = {
                url: sketchfabURL,
            }

            const resp = await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.UPDATEPRODUCT_GETSKETCHFAB, params)

            setformValue({
                ...formValue,
                sketchfab: resp.data.sketchfab
            })
            setSketchfabID(resp.data.sketchfab)
        }
        catch(error) {
            layoutContext.setNotifContent({title:'Error', message:'Cannot Get Sketchfab ID.'})
        }
    }

    const clearSketchfabID = () => {
        setformValue({
            ...formValue,
            sketchfab: ''
        })
        setSketchfabID('')
    }

    useEffect(() => {
        if (responseData) {
            formValue.active = responseData.active
            formValue.affiliation = responseData.affiliation
            formValue.name = responseData.name
            formValue.description = responseData.description
            formValue.sketchfab = responseData.sketchfab
            formValue.slug = responseData.slug
            formValue.summary = responseData.summary
            formValue.price = responseData.price
            formValue.stores = responseData.stores
            formValue.tags = responseData.tags
            
            setUsaConv(responseData.conversion)
            setUsaPrice(Number.parseFloat(responseData.price * responseData.conversion).toFixed(2))

            setSketchfabID(responseData.sketchfab)

            setCover(responseData.cover)
            setThumbnail(responseData.thumbnail)
            setFiles(responseData.files)
            
            const currSymbol = intl.formatNumber(
                0,
                { style: 'currency', currency: responseData.country_currency_code },
            ).replace(/[\d.,\s]/g, '')

            setCurrency(currSymbol)

            setInitialForm(formValue)

            setNoStore(formValue.stores.length === 0)
            setNoTag(formValue.tags.length === 0)

            updateCategoriesAndTags()

            // if (responseData.id) {
            //     reFetchCov(`${Endpoints.PRODUCTFILE}?filetype=COV&product=${responseData.id}`)
            //     reFetchThu(`${Endpoints.PRODUCTFILE}?filetype=THU&product=${responseData.id}`)
            //     reFetch(`${Endpoints.PRODUCTFILE}?filetype=DAT&product=${responseData.id}`)
            // }
        }            
            // eslint-disable-next-line
    }, [responseData])


    useEffect(() => {
        if (responseCov) {
            setCover(responseCov[0])
        }
    }, [responseCov])


    useEffect(() => {
        if (responseThu) {
            setThumbnail(responseThu[0])
        }
    }, [responseThu])


    useEffect(() => {
        if (responseDataFile) {
            setFiles(responseDataFile)
        }
    }, [responseDataFile])


    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    if (hasError) {
        return (<>
            <SEO title={`Edit Product - ${SiteName}`} />

            <Container className='mt-5'>
                Error!
            </Container>
        </>)
    }

    if (isLoading || !responseData) {
        return (<>
            <SEO title={`Edit Product - ${SiteName}`} />
            
            <Container className='mt-5'>
                <Loading/>
            </Container>
        </>)
    }

    // const hasImage = !isLoadingCov && !hasErrorCov && responseCov.length>0
    // const hasThumbnail = !isLoadingThu && !hasErrorThu && responseThu.length>0
    // const hasFiles = !isLoadingFile && !hasErrorFile && responseDataFile.length>0


    const del = (id, url, ftype, cb) => {
        const requestObject = {
            method:'POST' ,
            headers: {
                Authorization: `Bearer ${Storage.getItem('access')}`
            },
            body:JSON.stringify({
                url: url,
            })
        }

        const furl = ftype === 'DAT' ? Endpoints.S3S_DEL : Endpoints.S3S_DELIMG

        fetch(BackURL(furl), requestObject)
            .then(async(response) => {
                if (response.status === 200) {
                    const params = {
                        id: id,
                    }
                    await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.UPDATEPRODUCT_DELDATA, params)
                    if (cb)
                        cb()
                }
            })
        .catch((error) => {
            console.log(error)
        })
    }


    const handleSubmit = async(event) => {
        event.preventDefault()

        const dirtyFields = {}
        for (const field in formValue) {
            if (['stores', 'tags'].includes(field) || formValue[field] !== initialForm[field]) {
                dirtyFields[field] = formValue[field]

                if (field === 'price') {
                    dirtyFields[field] = Number.parseFloat(dirtyFields[field]).toFixed(2)
                    formValue[field] = dirtyFields[field]
                }
            }
        }

        const params = {
            ...dirtyFields,
            user: authDispatchContext.state.user.id
        }

        setSlugError(false)
        setSummaryError(false)

        try {
            const resp = await Request.authRequest(authDispatchContext.dispatch, 'patch', `${Endpoints.PRODUCTDETAIL}${responseData.slug}/`, params)
            
            if (resp.data.slug !== responseData.slug) {
                navigate(`/store/productedit/${resp.data.slug}`)
                responseData.slug = resp.data.slug
            }
            
            layoutContext.setNotifContent({message:'Saved!'})
        }
        catch(error) {
            const isSlugError = error.response.data.constructor === Object && 'slug' in error.response.data
            const isSummaryError = error.response.data.constructor === Object && 'summary' in error.response.data

            if (isSlugError) {
                formValue.slug = responseData.slug
                setSlugError(true)
            }

            if (isSummaryError) {
                setSummaryError(true)
            }

            layoutContext.setNotifContent({title:'Error', message:'Changes not saved.'})
        }
    }

    const handleChange = (event) => {
        if (event.target.name === 'price') {
            setUsaPrice(Number.parseFloat(event.target.value * usaConv).toFixed(2))
        }

        setformValue({
            ...formValue,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        })
    }

    const handleUsaPriceChange = (event) => {
        setUsaPrice(event.target.value)
        setformValue({
            price: Number.parseFloat(event.target.value / usaConv).toFixed(2)
        })
    }

    const handleStoresChange = (event) => {
        const id = parseInt(event.target.name)

        if (event.target.checked) {
            if (!formValue.stores.includes(id)) {
                formValue.stores.push(id)
            }
        }
        else {
            const i = formValue.stores.indexOf(id)
            if (i >= 0) {
                formValue.stores.splice(i, 1)
            }
        }

        setNoStore(formValue.stores.length === 0)

        updateCategoriesAndTags()
    }  

    const handleTagsChange = (event) => {
        const id = parseInt(event.target.name)

        if (event.target.checked) {
            if (!formValue.tags.includes(id)) {
                formValue.tags.push(id)
            }
        }
        else {
            const i = formValue.tags.indexOf(id)
            if (i >= 0) {
                formValue.tags.splice(i, 1)
            }
        }

        setNoTag(formValue.tags.length === 0)
    }

    // const handleEditorChange = (contents, $editable) => {
    //     setformValue({
    //         ...formValue,
    //         description: contents
    //     })
    // }

    const setDescription = (description) => {
        setformValue({
            ...formValue,
            description: description
        })
    }

    const updateCover = async(path, isThumbnail) => {
        const params = {
            id: responseData.id,
            path: path,
            isThumbnail: isThumbnail
        }
        await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.UPDATEPRODUCT_COV, params)

        if (isThumbnail) {
            reFetchThu(`${Endpoints.PRODUCTFILE}?filetype=THU&product=${responseData.id}`)
        }
        else {
            reFetchCov(`${Endpoints.PRODUCTFILE}?filetype=COV&product=${responseData.id}`)
        }
    }


    const handleDnDCover = async(path, isThumbnail) => {
        if (!isThumbnail && cover) {
            del(cover.id, cover.url, 'COV', () => updateCover(path, isThumbnail))
        }
        else if (isThumbnail && thumbnail) {
            del(thumbnail.id, thumbnail.url, 'THU', () => updateCover(path, isThumbnail))
        }
        else
            updateCover(path, isThumbnail)
    }


    const handleDnDFile = async(path, isThumbnail) => {
        const params = {
            id: responseData.id,
            path: path,
        }
        await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.UPDATEPRODUCT_ADDDATA, params)
        reFetch(`${Endpoints.PRODUCTFILE}?filetype=DAT&product=${responseData.id}`)
    }


    const handleDel = async(item) => {
        del(item.id, item.url, 'DAT', () => {reFetch(`${Endpoints.PRODUCTFILE}?filetype=DAT&product=${responseData.id}`)})
    }


    const handleDownload = async(item, slug) => {
        const requestObject = {
            method:'POST' ,
            headers: {
                Authorization: `Bearer ${Storage.getItem('access')}`
            },
            body:JSON.stringify({
                url: item.url,
                slug: slug
            })
        }

        fetch(BackURL(Endpoints.S3S_GET), requestObject)
        .then((response) => {
            if (response.status === 200)
                return response.json()
        })
        .then(async(result) => {
            const link = document.createElement("a")
            link.href = result.signed_url
            link.download = ""
            link.click()
            // document.body.removeChild(link)
        })
        .catch((error) => {
            console.log(error)
        })
    }


    return (<>
        <SEO title={`Edit Product - ${SiteName}`} />

        <Modal isOpen={modalSketchfab} toggle={toggleModalSketchfab}>
            <ModalHeader toggle={toggleModalSketchfab}>Get Sketchfab ID</ModalHeader>
            <ModalBody>
                <InputGroup>
                    <InputGroupText>
                        Sketchfab URL
                    </InputGroupText>

                    <Input type="text" value={sketchfabURL} onChange={e => setSketchfabURL(e.target.value)} />
                </InputGroup>
            </ModalBody>

            <ModalFooter>
                <Button onClick={toggleModalSketchfab}>
                    Cancel
                </Button>
                <Button color='primary' onClick={onGetSketchfabID}>
                    Get
                </Button>
            </ModalFooter>
        </Modal>

        
        <Container className="mb-5">
            <Form onSubmit={handleSubmit}>
                {/* <div className="edit-btn">
                    <Button color="primary" className='text-end' type="submit">
                        Save
                    </Button>

                    <Button color="warning" className="ms-3"                            
                        onClick={() => navigate(`/product/${responseData.slug}`)}
                    >
                        View Live
                    </Button>
                </div> */}

                <Row>
                    <Col>
                        <h1>Edit Product</h1>
                    </Col>
                    <Col className="text-end">
                        <Button color="primary" className='text-end' type="submit">
                            Save
                        </Button>

                        <Button color="warning" className="ms-3" onClick={() => navigate(`/product/${responseData.slug}`)}>
                            View Live
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs='auto'>
                        <FormGroup switch>
                            <Input type="switch" role="switch" name='active' onChange={handleChange} checked={formValue.active}/>
                            <Label>Active</Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup switch>
                            <Input type="switch" role="switch" name='affiliation' onChange={handleChange} checked={formValue.affiliation}/>
                            <Label>Affiliation</Label>
                        </FormGroup>
                    </Col>
                </Row>

                <Row
                    // lg="2"
                    // md="1"
                    // sm="1"
                    // xs="1"
                >
                    <Col lg={{size:8, order:'first'}}>
                        {cover && <img
                            style={{width: '100%'}}
                            src={CloudURL(cover.url)}
                            alt='Cover'
                        />}

                        {/* {false && hasThumbnail && <img
                            style={{width: 'inherit'}}
                            src={CloudURL(responseThu[0].url)}
                            alt={responseThu[0].name}
                        />} */}

                        <DragDropUploader text='Drop cover image here' productID={responseData.id} onDnD={handleDnDCover} isPublicImg={true} generateThumbnail={true}/>
                    {/* </Col>

                    <Col lg={{size:8, order:'last', offset:2}} className="mt-3"> */}
                        <div className="mt-3">
                            <h5>Files</h5>
                            <DragDropUploader text='Drop files here' productID={responseData.id} onDnD={handleDnDFile}/>

                            <Container className="p-2">
                            {files && files.map((item, i) => {
                                return (
                                    <Row className="bg-light border" key={item.id}>
                                        <Col>{item.url.split('/').pop()}</Col>
                                        <Col className="pe-0 text-end">
                                            <Button color="primary" outline size="sm" onClick={() => handleDownload(item, slug)}>Download</Button>
                                            <Button color="danger" outline className="ms-1" size="sm" onClick={() => handleDel(item)}>X</Button>
                                        </Col>
                                    </Row>
                                )
                            })}
                            </Container>
                        </div>
                    </Col>

                    <Col lg={{size:4, order:2}}>
                    {/* <div className='form-group'> */}
                        <Label tag='h5' className="mt-3">Product name</Label>
                        <Input
                            className='form-control'
                            type="text"
                            name="name"
                            placeholder="product name"
                            value={formValue.name}
                            onChange={handleChange}
                            required
                        />

                        <Label tag='h5' className="mt-3">Slug</Label>
                        {slugError && <Alert color="danger">Slug must only contain a-zA-Z0-9 and hyphens.</Alert>}
                        <Input
                            className='form-control'
                            type="text"
                            name="slug"
                            placeholder="slug"
                            value={formValue.slug}
                            onChange={handleChange}
                            required
                        />

                        <Label tag='h5' className="mt-3">Summary</Label>
                        {summaryError && <Alert color="danger">Summary must be under 250 characters.</Alert>}
                        <Input
                            className='form-control'
                            type="textarea"
                            name="summary"
                            placeholder="summary"
                            value={formValue.summary}
                            onChange={handleChange}
                            required
                            />

                        <Row>
                            <Col>
                                <Label tag='h5' className="mt-3">Price</Label>
                            </Col>
                            <Col className="text-end" xs="auto">
                                <PriceHelper Toggler={true}/>
                            </Col>
                        </Row>
                        <PriceHelper currency={currency}/>

                        <InputGroup>
                            <InputGroupText>{currency}</InputGroupText>
                            <Input
                                className='form-control'
                                type="float"
                                name="price"
                                placeholder="price"
                                value={formValue.price}
                                onChange={handleChange}
                                required
                            />
                            <span check style={{fontSize:'0.75rem', color:'#6d6e78'}}>
                                Note: The minimum price will be set to $1 USD or its equivalent in the purchasing currency, regardless of whether you enter $0.
                            </span>
                        </InputGroup>
                        <Alert color="info">
                            <span check style={{fontSize:'0.75rem', color:'#6d6e78'}}>
                                This input displays today's price in $(USA), based on today's exchange rate and the current LPPI. It can assist you in determining the appropriate price in your local currency.
                            </span>
                            <InputGroup className="mt-3">
                                <InputGroupText>$(USA)</InputGroupText>
                                <Input
                                    className='form-control'
                                    type="float"
                                    name="priceusa"
                                    placeholder="priceusa"
                                    value={usaPrice}
                                    onChange={handleUsaPriceChange}
                                    // required
                                />
                            </InputGroup>
                        </Alert>

                        <div className="mt-5">
                            <span style={{fontSize:'1.0rem', color:'#6d6e78'}}>
                                To propose a new <b>Foundation</b>, <b>Store</b>, or <b>Tag</b>, kindly reach out to us via email at <a target="_blank" rel="noreferrer" href={`mailto:support@${process.env.REACT_APP_FRONT_DOMAIN}`}>support@{process.env.REACT_APP_FRONT_DOMAIN}</a>.
                            </span>
                        </div>

                        <Label tag='h5' className="mt-3">Foundations</Label>
                        <Row>
                            <Col xs='auto' key='blenderfund'>
                                <Input id='blenderfund' type='checkbox' className="me-1" name='blenderfund' defaultChecked={responseData.blenderfund} onChange={handleChange}/>
                                <Label htmlFor='blenderfund'>Blender Foundation</Label>
                            </Col>
                            <Col xs='auto' key='godotfund'>
                                <Input id='godotfund' type='checkbox' className="me-1" name='godotfund' defaultChecked={responseData.godotfund} onChange={handleChange}/>
                                <Label htmlFor='godotfund'>Godot Foundation</Label>
                            </Col>
                            <Col xs='auto' key='onepercent'>
                                <Input id='onepercent' type='checkbox' className="me-1" name='onepercent' defaultChecked={responseData.onepercent} onChange={handleChange}/>
                                <Label htmlFor='onepercent'>1% for the Planet</Label>
                            </Col>
                        </Row>

                        <Label tag='h5' className="mt-3">Stores</Label>
                        {noStore && <Alert color="danger">To be listed, a product must have at least one store checked.</Alert>}
                        <Row>
                            {responseData.alltags.stores && responseData.alltags.stores.map((store) => {
                                return (
                                    <Col xs='auto' key={store.name}>
                                        <Input id={store.name} type='checkbox' className="me-1" name={store.id} defaultChecked={responseData.stores.includes(store.id)} onChange={handleStoresChange}/>
                                        <Label htmlFor={store.name}>{store.label}</Label>
                                    </Col>
                                )
                            })}
                        </Row>

                        <Label tag='h5' className="mt-3">Tags</Label>
                        {noTag && <Alert color="warning">This product has no tag.</Alert>}
                        {categories && categories.length===0 && <Alert color="warning">You must select at least one store to display Tag's Categories.</Alert>}
                        {categories && categories.map((cat) => {
                            return (
                                <Row key={`toggler_${cat.name}`}>
                                    <Button color="light"
                                        // key={cat.name+'button'}
                                        className='text-start'
                                        id={`toggler_${cat.name}`}
                                        style={{ marginBottom: '1rem' }}
                                    >
                                        {cat.label}
                                    </Button>

                                    <UncontrolledCollapse toggler={`#toggler_${cat.name}`}>
                                        <Row>
                                            {tags && tags.map((tag) => {
                                                if (tag.category_id === cat.id) {
                                                    return (
                                                        <Col xs='auto' key={tag.name}>
                                                        <Input id={tag.name} type='checkbox' className="me-1" name={tag.id} defaultChecked={responseData.tags.includes(tag.id)} onChange={handleTagsChange}/>
                                                        <Label htmlFor={tag.name}>{tag.label}</Label>
                                                    </Col>
                                                    )
                                                }

                                                return <></>
                                            })}
                                        </Row>        
                                    </UncontrolledCollapse>
                                </Row>
                            )
                        })}

                        <div className="text-end mt-3">
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            <Button color="warning" className="ms-3" onClick={() => navigate(`/product/${responseData.slug}`)}>
                                View Live
                            </Button>
                        </div>
                    </Col>

                    <Col lg={{size:8, order:3, offset:2}}>
                        <Label tag='h5' className="mt-3">Sketchfab</Label>
                        <InputGroup className="bg-light">
                            {sketchfabID && <Button color="danger" outline size="sm" onClick={clearSketchfabID}>X</Button>}
                            <Input
                                className='form-control'
                                type="text"
                                name="sketchfab"
                                placeholder="Sketchfab id"
                                value={sketchfabID}
                                onChange={handleChange}
                                disabled
                            />
                            <Button onClick={toggleModalSketchfab}>Get Sketchfab ID</Button>
                        </InputGroup>

                        <Label tag='h2' className="mt-3">Description</Label>
                        <Alert>
                            <p>
                                If you include links to other marketplaces, kindly update your product descriptions on those platforms to include a link to <b>FairMarket.Land</b> in return. Thank you for your fairness!
                            </p>
                            <p>
                                Link: <code>{FrontURL(`product/${slug}`)}</code>
                            </p>
                            <p>
                                Embed code: <code>{EmbedCode(responseData.slug)}</code>
                            </p>
                        </Alert>
                        {/* <input
                            className='form-control'
                            type="text"
                            name="description"
                            placeholder="description"
                            value={formValue.description}
                            onChange={handleChange}
                            required
                        /> */}
                        <DragDropText 
                            productID={responseData.id}
                            value={formValue.description}
                            onChange={setDescription}
                        ></DragDropText>

                        <div className="text-end mt-3">
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            <Button color="warning" className="ms-3" onClick={() => navigate(`/product/${responseData.slug}`)}>
                                View Live
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>)
}

export default ProductEdit