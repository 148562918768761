import React from "react"
import { Spinner } from "reactstrap"
import { Row, Col } from "reactstrap"


const Loading = () => {

    return (
        <Row className="justify-content-center align-items-center">
            <Col xs="auto">
                <Spinner 
                    color="primary"
                    size="sm"
                >
                    Loading...
                </Spinner>
            </Col>
        </Row>
    )
}

export default Loading
