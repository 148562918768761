import React, { createContext, useContext, useEffect } from "react"

import { useRequest } from "../hooks/Hooks"
import { matchPath, useLocation } from "react-router"
import { AuthDispatchContext } from "../App"
import * as Endpoints from '../Endpoints'



export const FetchContext = createContext(null)


const Fetch = (props) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const location = useLocation()

    const matchSlug = matchPath('/product/:slug', location.pathname)
    const slug = matchSlug ? matchSlug.params.slug : ''

    const product = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTPAGE}${slug}/`, { useCache:false, firstCall:false })
    const order = useRequest(authDispatchContext.dispatch, `${Endpoints.ORDERPRODUCT}?slug=${slug}`, { useCache:false, firstCall:false })

    const fetchs = {
        product:product,
        order:order
    }
    
    useEffect(() => {
        if (matchSlug) {
            product.reFetch()
        }
    // eslint-disable-next-line
    }, [location]);


    return (
        <FetchContext.Provider
            value={{
                fetchs:fetchs
            }}>
            {props.children}
        </FetchContext.Provider>
    )
}

export default Fetch