import React, { useContext, useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { Alert, Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem } from "reactstrap"
import { Button } from 'reactstrap'
import { Container, Row, Col } from "reactstrap"

import * as Request from "../actions/Request"
import { AuthDispatchContext } from '../App'
import { LayoutContext } from "../hocs/Layout"
import Loading from "./Loading"

import parse from 'html-react-parser'

import { FormattedNumber } from 'react-intl'
import { CloudURL, BackURL } from '../tools/Cloud'

import * as Storage from '../tools/Storage'
import { useDomainNavigate, UserUrl } from "../tools/Subdomain"

import * as Endpoints from '../Endpoints'
import { FetchContext } from "../hocs/Fetch"

import { CellarName, CellarReplace } from "./CellarReplace"
import { ButtonBG, SiteName } from "./SiteTitle"
import { SEO } from "./SEO"
import { Share } from "./Share"
import { AskSeller } from "./AskSeller"
import { ViewAs, ViewAsUsed } from "../containers/doc/Doc"


export const ProductMenu = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const fetchContext = useContext(FetchContext)
    const navigate = useDomainNavigate()
    const { slug } = useParams()

    const [searchParams] = useSearchParams()
    // const searchParamsRef = useRef(searchParams)
    const affiliate = searchParams.get("a")

    if (affiliate) {
        Storage.setItem(slug, affiliate, true)
    }


    // const [isMounted, setIsMounted] = useState(false)
    // const [isAuthenticated, setIsAuthenticated] = useState(authDispatchContext.state.isAuthenticated)

    // const { responseData: fetchContext.fetchs.order.responseData, isLoading: fetchContext.fetchs.order.isLoadingOrder, hasError: fetchContext.fetchs.order.hasErrorOrder, reFetch:fetchContext.fetchs.order.reFetch } = useRequest(authDispatchContext.dispatch, `${Endpoints.ORDERPRODUCT}?slug=${slug}`, {auth:true})
    // const { responseData: fetchContext.fetchs.cellar.responseData, isLoading: fetchContext.fetchs.cellar.isLoading, hasError: fetchContext.fetchs.cellar.hasError, reFetch: fetchContext.fetchs.cellar.reFetch } = useRequest(authDispatchContext.dispatch, `${Endpoints.CELLARPRODUCT}?slug=${slug}`, {auth:true})
    // const { responseData: fetchContext.fetchs.product.responseData, isLoading: fetchContext.fetchs.product.isLoading, hasError: fetchContext.fetchs.product.hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTPRICE}${slug}/`, { useCache:false })


    // useEffect(() => {
    //     if (isMounted && (true || isAuthenticated != authDispatchContext.state.isAuthenticated)) {
    //         setIsAuthenticated(authDispatchContext.state.isAuthenticated)
    //         fetchContext.fetchs.order.reFetch()
    //         fetchContext.fetchs.cellar.reFetch()
    //     }
    //     else {
    //         setIsMounted(true)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isMounted, authDispatchContext.state.isAuthenticated])



    async function onCellar() {
        if (!authDispatchContext.state.isAuthenticated) {
            const test = <div>
                    You have to log in in order to add it to your <CellarReplace/>.
                    <div>
                        <Button color='primary' onClick={() => {authDispatchContext.navigateRedirect(navigate, '/login'); layoutContext.setForceCloseNotif(true)}}>Login</Button>
                        &nbsp;
                        <Button onClick={() => {authDispatchContext.navigateRedirect(navigate, '/signup'); layoutContext.setForceCloseNotif(true)}}>Sign Up</Button>
                    </div>
                </div>
            // layoutContext.setNotifContent({title:'Authentification needed', message:`You have to log in in order to add it to your ${CellarName}.`})
            layoutContext.setNotifContent({title:'Authentification needed', message:test, notimer:true})
            return
        }


        try {
            const affiliate = Storage.getItem(slug, true)
            const params = {
                slug: slug,
                affiliate: affiliate
            }
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CELLAREDIT, params)

            layoutContext.setNotifContent({message:`Added to ${CellarName}!`})

            fetchContext.fetchs.product.reFetch()
            // fetchContext.fetchs.cellar.reFetch()
            // navigate(`/store/productedit/${slug}`)
        }
        catch (error) {
            layoutContext.setNotifContent({title:'Error', message:`Cannot add to ${CellarName}.`})
            // throw error
        }
    }

    function onGoLibrary() {
        navigate(`/cellar/library`)
    }

    
    function onGoCellar() {
        navigate(`/cellar`)
    }

    if (fetchContext.fetchs.product.isLoading || fetchContext.fetchs.product.hasError || !fetchContext.fetchs.product.responseData) {
        return (<></>)
    }
    
    if (fetchContext.fetchs.product.responseData.order.ordered) {
        return (
            <NavItem key='InLibrary'>
                <Button color="success" onClick={onGoLibrary} className="ps-5 pe-5 w-100">
                    In library
                </Button>
            </NavItem>
        )
    }

    if (fetchContext.fetchs.product.responseData.cellar) {
        return (
            <NavItem key='AddedToCellar'>
                <Button color="info" onClick={onGoCellar} className="ps-5 pe-5 w-100">
                    Added to <CellarReplace/> - &nbsp;
                    <FormattedNumber                    
                        value={fetchContext.fetchs.product.responseData.fairprice}
                        style={`currency`}
                        currency={fetchContext.fetchs.product.responseData.currency}
                        maximumFractionDigits='0'
                    />{/* - {fetchContext.fetchs.product.responseData.cur_country}*/}
                </Button>
            </NavItem>
        )
    }

    if (authDispatchContext.state.isAuthenticated && (authDispatchContext.state.user.doc || authDispatchContext.state.user.id === fetchContext.fetchs.product.responseData.user)) {
        return (
            <NavItem key='EditProduct'>
                <Button color='warning' onClick={() => navigate(`/store/productedit/${fetchContext.fetchs.product.responseData.slug}`)} className="ps-5 pe-5 w-100">
                    Edit Product - &nbsp;
                    <FormattedNumber                    
                        value={fetchContext.fetchs.product.responseData.fairprice}
                        style={`currency`}
                        currency={fetchContext.fetchs.product.responseData.currency}
                        maximumFractionDigits='0'
                    />{/* - {fetchContext.fetchs.product.responseData.cur_country}*/}
                </Button>
            </NavItem>
        )
    }

    return (
        <NavItem key='AddToCellar'>
            <Button color='primary' onClick={onCellar} className="ps-5 pe-5 w-100">
                Add to <CellarReplace/> - &nbsp;
                <FormattedNumber                    
                    value={fetchContext.fetchs.product.responseData.fairprice}
                    style={`currency`}
                    currency={fetchContext.fetchs.product.responseData.currency}
                    maximumFractionDigits='0'
                />{/* - {fetchContext.fetchs.product.responseData.cur_country}*/}
            </Button>
        </NavItem>
    )
}



const reasons = [
    { title: 'Item 1', detail: 'This is the first item' },
    { title: 'Item 2', detail: 'This is the second item' },
    { title: 'Item 3', detail: 'This is the third item' },
]



const ProductFire = ({percent}) => {
    const size = '48px'

    return (
        <div style={{ position: 'relative', width: '100%', height: size, overflow: 'hidden' }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: size,
                    height: '100%',
                }}
            >
                <img
                    src={CloudURL('logo_fire_no_back_64.webp')}
                    alt="fire extinguished"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        opacity: 0.6,
                        // transition: 'opacity 0.5s ease-in-out',
                        // clipPath: 'inset(50% 0 0 0)', // Start with the image hidden
                    }}
                />
                <img
                    src={CloudURL('logo_fire_back_64.webp')}
                    alt="fire"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        // transition: 'opacity 0.5s ease-in-out',
                        clipPath: `inset(${100 - percent}% 0 0 0)`, // Start with the image hidden
                    }}
                />
                <img
                    src={CloudURL('logo_fire_front_64.webp')}
                    alt="fairmarket bird"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        // transition: 'opacity 0.5s ease-in-out',
                        opacity: 1 - Math.min(1, percent/50.0),
                    }}
                />
            </div>
        </div>
    )
}



const OfferProduct = ({productID}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    // const fetchContext = useContext(FetchContext)

    const [offering, settOffering] = useState(false)
    const [destEmail, settDestEmail] = useState()

    const [modalOpen, setModalOpen] = useState(false)
    const toggleModal = () => setModalOpen(!modalOpen)

    const handleOffering = async(event) => {
        // event.preventDefault()
        if (offering) {
            return
        }
        
        try {
            settOffering(true)

            const params = {
                id: productID,
                destEmail: destEmail
            }
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STORE_GIFT, params)
            
            setModalOpen(false)
            layoutContext.setNotifContent({message:'Gift Sent!'})
        }
        catch (error) {
            setModalOpen(false)
            layoutContext.setNotifContent({title:'Error', message:'The product was not offered. Please check that user email is correct.'})
        }
        finally {
            settOffering(false)
        }
    }

    return (<>
        <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Offer as a Gift</ModalHeader>
            <ModalBody>
                You can offer this product to a {SiteName} registered user.<br/>

                <InputGroup>
                    <InputGroupText>
                        Recipient
                    </InputGroupText>
                    <Input type="email" value={destEmail} placeholder="User email" onChange={(e) => settDestEmail(e.target.value)} />
                </InputGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={handleOffering} disabled={offering}>
                    {offering ? 'Offering...' : 'Offer'}
                </Button>
                <Button onClick={toggleModal} disabled={offering}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>

        <Button className="w-100" color="success" onClick={toggleModal}>Offer as a Gift</Button>
    </>)
}



const Product = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const fetchContext = useContext(FetchContext)
    const navigate = useDomainNavigate()
    const { slug } = useParams()
    const [searchParams] = useSearchParams()
    const affiliate = searchParams.get("a")
    const [checkAffiliate, setCheckAffiliate] = useState(true)

    const [isMounted, setIsMounted] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(authDispatchContext.state.isAuthenticated)

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [rejectDetail, setRejectDetail] = useState('')
    const [modalRefund, setModalRefund] = useState(false)

    const [refundable, setRefundable] = useState(true)
    const [SEODesc, setSEODesc] = useState('')

    const [approving, setApproving] = useState(false)
    const [rejecting, setRejecting] = useState(false)
    const [refunding, setRefunding] = useState(false)

    const [formats, setFormats] = useState([])

    const viewAsUsed = ViewAsUsed()

    // const { responseData:fetchContext.fetchs.product.responseData, isLoading:fetchContext.fetchs.product.isLoading, hasError:fetchContext.fetchs.product.hasError, reFetch: fetchContext.fetchs.product.reFetch } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTPRICE}${slug}/`, { useCache:false })
    // const { responseData:fetchContext.fetchs.order.responseData, isLoading:fetchContext.fetchs.order.isLoadingOrder, hasError:fetchContext.fetchs.order.hasErrorOrder, reFetch: fetchContext.fetchs.order.reFetch } = useRequest(authDispatchContext.dispatch, `${Endpoints.ORDERPRODUCT}?slug=${slug}`, {auth:true})
    // const { responseData:fetchContext.fetchs.cover.responseData, isLoading:fetchContext.fetchs.cover.isLoading, hasError:fetchContext.fetchs.cover.hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTFILE}?filetype=COV&slug=${slug}`, { useCache:false })
    // const { responseData:fetchContext.fetchs.file.responseData, isLoading:fetchContext.fetchs.file.isLoading, hasError:fetchContext.fetchs.file.hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.PRODUCTFILE}?filetype=DAT&slug=${slug}`, { useCache:false })
    // const { responseData: responseDataUser, reFetch: reFetchUser } = useRequest(authDispatchContext.dispatch, '', { firstCall:false, useCache:true })

    const toggleRefund = () => setModalRefund(!modalRefund)


    useEffect(() => {
        if (isMounted && (isAuthenticated !== authDispatchContext.state.isAuthenticated)) {
            setIsAuthenticated(authDispatchContext.state.isAuthenticated)
            fetchContext.fetchs.product.reFetch()
            // fetchContext.fetchs.order.reFetch()
            // fetchContext.fetchs.cellar.reFetch()
        }
        else {
            setIsMounted(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, authDispatchContext.state.isAuthenticated])


    useEffect(() => {
        if (!fetchContext.fetchs.product.isLoading && !fetchContext.fetchs.product.hasError && fetchContext.fetchs.product.responseData) {
            if (affiliate && !fetchContext.fetchs.product.responseData.affiliation) {
                navigate('/')
                return
            }

            setCheckAffiliate(false)

            setRefundable(fetchContext.fetchs.product.responseData.order.ordered && fetchContext.fetchs.product.responseData.order.refundable)

            const product = fetchContext.fetchs.product.responseData
            var desc = product.summary

            for (const store of product.stores) {
                desc += ' - ' + store.label
            }

            if (product.blenderfund) {
                desc += ' - Blender Foundation'
            }

            if (product.godotfund) {
                desc += ' - Godot Foundation'
            }

            if (product.onepercent) {
                desc += ' - 1% for the Planet'                
            }

            setSEODesc(desc)

            // Tags
            const tagFormats = []
            if (fetchContext.fetchs.product.responseData.tags && fetchContext.fetchs.product.responseData.tags.length) {
                for (var tag of fetchContext.fetchs.product.responseData.tags) {
                    if (tag.category.name === "Format") {
                        tagFormats.push(tag.label)
                    }
                }
            }
            setFormats(tagFormats)
        }
    // eslint-disable-next-line
    }, [fetchContext.fetchs.product.responseData])


    useEffect(() => {
        if (!fetchContext.fetchs.order.isLoading && !fetchContext.fetchs.order.hasError && fetchContext.fetchs.order.responseData) {
            setRefundable(fetchContext.fetchs.order.responseData.refundable)
        }
    // eslint-disable-next-line
    }, [fetchContext.fetchs.order.responseData])

    // useEffect(() => {
    //     if (fetchContext.fetchs.product.responseData.user) {
    //         reFetchUser(`${Endpoints.ACCOUNTS_USER}${fetchContext.fetchs.product.responseData.user}/`)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fetchContext.fetchs.product.responseData])


    if (fetchContext.fetchs.product.hasError) {// || fetchContext.fetchs.cover.hasError) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
        )
    }

    if (checkAffiliate || fetchContext.fetchs.product.isLoading || !fetchContext.fetchs.product.responseData) {// || fetchContext.fetchs.cover.isLoading) {
        return (
            <Container className='mt-5'>
                <Loading/>
            </Container>
        )
    }

    const owner = authDispatchContext.state.isAuthenticated && authDispatchContext.state.user.id === fetchContext.fetchs.product.responseData.user
    const isDoc = authDispatchContext.state.user && authDispatchContext.state.user.doc

    const approved = fetchContext.fetchs.product.responseData.state.toLowerCase() === "approved"
    const updated = fetchContext.fetchs.product.responseData.state.toLowerCase() === "updated"
    const needsReview = fetchContext.fetchs.product.responseData.state.toLowerCase() === "needs review"
    const rejected = fetchContext.fetchs.product.responseData.state.toLowerCase() === "rejected"


    const populateReason = (index) => {
        setRejectDetail(reasons[index].detail)
    }

    
    const handleDownload = async(url) => {
        const requestObject = {
            method:'POST' ,
            headers: {
                Authorization: `Bearer ${Storage.getItem('access')}`
            },
            body:JSON.stringify({
                url: url,
                slug: slug,
            })
        }

        fetch(BackURL(Endpoints.S3S_GET), requestObject)
        .then((response) => {
            if (response.status === 200)
                return response.json()
        })
        .then(async(result) => {
            const link = document.createElement("a")
            link.href = result.signed_url
            link.download = ""
            link.click()

            fetchContext.fetchs.order.reFetch()
            // document.body.removeChild(link)
        })
        .catch((error) => {
            console.log(error)
        })
    }


    const handleApprove = async() => {
        if (approving || rejecting || refunding) {
            return
        }

        try {
            setApproving(true)

            const params = {
                id: fetchContext.fetchs.product.responseData.id,
            }
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_APPROVE, params)

            layoutContext.setNotifContent({message:'Product Approved!'})

            fetchContext.fetchs.product.reFetch()
        }
        catch (error) {
            layoutContext.setNotifContent({title:'Error', message:'Product not Approved.'})
        }
        finally {
            setApproving(false)
        }
    }


    const handleReject = async(event) => {
        event.preventDefault()

        if (rejecting) {
            return
        }

        try {
            setRejecting(true)

            const params = {
                id: fetchContext.fetchs.product.responseData.id,
                reason: rejectDetail,
            }
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_REJECT, params)

            layoutContext.setNotifContent({message:'Product Rejected!'})
            setRejectDetail('')

            fetchContext.fetchs.product.reFetch()
        }
        catch (error) {
            layoutContext.setNotifContent({title:'Error', message:'Product not Rejected.'})
        }
        finally {
            setRejecting(false)
        }
    }


    const onClickUser = (event) => {
        event.stopPropagation();
        navigate('', fetchContext.fetchs.product.responseData.username, true)
    }


    const handleRefund = async(event) => {
        // event.preventDefault()
        if (approving || rejecting || refunding) {
            return
        }
        
        try {
            setRefunding(true)

            const params = {
                id: fetchContext.fetchs.product.responseData.id,
            }
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_REFUND, params)
            
            setModalRefund(false)
            layoutContext.setNotifContent({message:'Product Refunded!'})
            // setRejectDetail('')

            // fetchContext.fetchs.product.reFetch()
            navigate('/cellar/library')
        }
        catch (error) {
            setModalRefund(false)
            layoutContext.setNotifContent({title:'Error', message:'Product not Refunded.'})
        }
        finally {
            setRefunding(false)
        }
    }

    return (<>
        <SEO
            title={`${fetchContext.fetchs.product.responseData.name} - ${SiteName}`}
            description={SEODesc}
            img={CloudURL(fetchContext.fetchs.product.responseData.thumbnail)}
            canonical={`/product/${fetchContext.fetchs.product.responseData.slug}`}
            domain="www"
        />
        <Container className='mt-5 mb-5 long-words'>
            <ViewAs/>

            {!viewAsUsed && authDispatchContext.state.user && authDispatchContext.state.user.doc && <>
                <Alert color={needsReview ? "warning" : rejected ? "danger" : updated ? "info" : "success"}>
                    <Row>
                        <Col>
                            Product status: <b>{fetchContext.fetchs.product.responseData.state}</b>
                        </Col>
                        {!approved && <Col xs="auto">
                            <Button color='primary' disabled={approving || rejecting || refunding} onClick={handleApprove}>{approving ? 'Approving...' : 'Approve'}</Button>
                        </Col>}
                    </Row>
                    <Alert color="danger" className="mt-3 mb-0">
                        <Form onSubmit={handleReject}>
                            Previous Reason
                            <Input type="textarea" value={fetchContext.fetchs.product.responseData.stateemail} disabled={true} />

                            Current Reason for <b>Rejection</b>
                            <Row className="align-items-end">
                                <Col>
                                    <Input
                                        type="textarea"
                                        style={{ minHeight: '10em' }}
                                        value={rejectDetail}
                                        onChange={(e) => setRejectDetail(e.target.value)}
                                        required
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                        <DropdownToggle caret>
                                            Reasons
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => setRejectDetail(fetchContext.fetchs.product.responseData.stateemail)}>Same Reason</DropdownItem>
                                            <DropdownItem divider />
                                            {reasons.map && reasons.map((item, index) => (
                                                <DropdownItem key={index} onClick={() => populateReason(index)}>
                                                    {item.title}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button color='danger' type="submit" disabled={approving || rejecting || refunding}>{rejecting ? 'Rejecting...' : 'Reject'}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Alert>
                </Alert>

                <Alert color="info">
                    Stores:
                    {fetchContext.fetchs.product.responseData.stores.map && fetchContext.fetchs.product.responseData.stores.map((store) => {
                        return <Badge color="info" className="m-1">{store.label}</Badge>
                    })}
                </Alert>

                <Alert color="info">
                    Tags:
                    {fetchContext.fetchs.product.responseData.tags && fetchContext.fetchs.product.responseData.tags.map((tag) => {
                        return <Badge color="info" className="m-1">{tag.category.name} / {tag.label}</Badge>
                    })}
                </Alert>
            </>}
            {/* <div style={{fontSize:"x-large", fontWeight:'bold', textAlign:'end'}}>
                <FormattedNumber                    
                    value={fetchContext.fetchs.product.responseData.fairprice}
                    style=`currency`
                    currency={fetchContext.fetchs.product.responseData.currency}
                    maximumFractionDigits='0'
                />
            </div> */}

            <Row
                    // lg="2"
                    // md="1"
                    // sm="1"
                    // xs="1"
                >
                <Col lg={{size:8, order:'first'}}>
                    <img
                        className="product-cover"
                        src={CloudURL(fetchContext.fetchs.product.responseData.cover)}
                        alt={fetchContext.fetchs.product.responseData.name}
                        top
                        onError={(e) => { e.target.src = CloudURL('missing_cover.webp') }}
                    />

                    {/* <div className="mt-5">
                        {parse(fetchContext.fetchs.product.responseData.description)}
                    </div> */}
                </Col>

                <Col lg={{size:4, order:2}} className="mt-3">
                    <h1>
                        {fetchContext.fetchs.product.responseData.name}
                    </h1>

                    <Row>
                        <Col xs="auto">
                            {fetchContext.fetchs.product.responseData.username && <Link
                                to={UserUrl(fetchContext.fetchs.product.responseData.username)}
                                color="link"
                                onClick={onClickUser}
                            >
                                {fetchContext.fetchs.product.responseData.username}
                            </Link>}
                        </Col>
                        <Col className="text-end">
                            <Share owner={fetchContext.fetchs.product.responseData.affiliation ? fetchContext.fetchs.product.responseData.useridhash : null} shareUrl={`https://${process.env.REACT_APP_FRONT_DOMAIN}/product/${fetchContext.fetchs.product.responseData.slug}`} title={`${fetchContext.fetchs.product.responseData.name}`}/>
                        </Col>
                    </Row>

                    <Nav fill className="mt-3">
                        <ProductMenu/>
                    </Nav>

                    <Row className="text-center mt-3" style={{fontSize:'0.75rem', color:'#6d6e78'}}>
                        <Col>
                            <ProductFire percent={fetchContext.fetchs.product.responseData.rating.interest}/>
                            Interest Score: {fetchContext.fetchs.product.responseData.rating.interest}
                        </Col>
                        <Col>
                            <ProductFire percent={fetchContext.fetchs.product.responseData.rating.commitment}/>
                            Purchasing Score: {fetchContext.fetchs.product.responseData.rating.commitment}
                        </Col>
                    </Row>

                    <div className="mt-3">
                        {fetchContext.fetchs.product.responseData.summary}
                    </div>

                    {fetchContext.fetchs.product.responseData.blenderfund && <div>
                        <span style={{fontSize:'0.75rem', color:'#6d6e78'}}><img alt='' src={CloudURL('blender_icon_32x32.webp')} className="me-1" style={{height:'1rem', verticalAlign:'text-bottom'}}/>Blender Foundation Contributor</span>
                    </div>}
                    {fetchContext.fetchs.product.responseData.godotfund && <div>
                        <span style={{fontSize:'0.75rem', color:'#6d6e78'}}><img alt='' src={CloudURL('godot_icon_32x32.webp')} className="me-1" style={{height:'1rem', verticalAlign:'text-bottom'}}/>Godot Foundation Contributor</span>
                    </div>}
                    {fetchContext.fetchs.product.responseData.onepercent && <div>
                        <span style={{fontSize:'0.75rem', color:'#6d6e78'}}><img alt='' src={CloudURL('onepercent_icon_32x32.webp')} className="me-1" style={{height:'1rem', verticalAlign:'text-bottom'}}/>1% for the Planet Foundation Contributor</span>
                    </div>}

                    {!!formats.length && <div className="mt-3 mb-4">
                        <Badge color="danger" className="m-1">Formats</Badge>
                        {formats.map((format) => {
                            return (
                                <Badge color="info" className="m-1">{format}</Badge>
                            )
                        })}
                    </div>}

                    <div className="mt-3">
                        <Row xs='auto'>
                            {fetchContext.fetchs.product.responseData.stores && fetchContext.fetchs.product.responseData.stores.map((store) => {
                                return (
                                    <Col xs='auto' key={store.name}>
                                        <Col className="mb-3"><ButtonBG label={store.label} padding="0.2em" fontSize="1.35em"/></Col>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>

                    <div className="mt-3 w-100">
                        {!owner && <AskSeller
                            seller={fetchContext.fetchs.product.responseData.username}
                            label={`Ask a Question to ${fetchContext.fetchs.product.responseData.username}`}
                            product={fetchContext.fetchs.product.responseData.name}
                        />}
                        {owner && <OfferProduct productID={fetchContext.fetchs.product.responseData.id}/>}
                    </div>

                {/* </Col>

                <Col lg={{size:4, order:3}}> */}
                    {(fetchContext.fetchs.product.responseData.order.ordered || owner || isDoc) && <div className="mt-3">
                        <h3>Files</h3>
                        <Container>
                        {fetchContext.fetchs.product.responseData.files && fetchContext.fetchs.product.responseData.files.map((url, i) => {
                            return (
                                <Row className="bg-light border" key={url}>
                                    <Col>{url.split('/').pop()}</Col>
                                    <Col className="pe-0 text-end">
                                        <Button color="primary" outline size="sm" onClick={() => handleDownload(url)}>Download</Button>
                                    </Col>
                                </Row>
                            )
                        })}

                        {refundable && <span check style={{fontSize:'0.75rem', color:'#6d6e78'}}>
                            By downloading this digital content, you waive your right to cancel and acknowledge that no refunds or exchanges will be given. If the purchase is not older than 30 days ago and if the item has never been downloaded, you can <Link color="link" onClick={toggleRefund}>claim a refund</Link>.<br/>
                            Kindly note that Stripe processing fees associated with your payments are non-refundable.
                        </span>}
                        {!refundable && <span check style={{fontSize:'0.75rem', color:'#6d6e78'}}>
                            The purchase is older than 30 days ago or it has already been downloaded, you cannot claim a refund anymore.
                        </span>}
                        </Container>

                        <Modal isOpen={modalRefund} toggle={toggleRefund}>
                            <ModalHeader toggle={toggleRefund}>Claim a refund</ModalHeader>
                            <ModalBody>
                                Are you sure you want to claim a refund for this product?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={handleRefund} disabled={approving || rejecting || refunding}>
                                    {refunding ? 'Refunding...' : 'Claim a refund'}
                                </Button>
                                <Button onClick={toggleRefund} disabled={approving || rejecting || refunding}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>

                    </div>}
                </Col>

                <Col lg={{size:8, order:'last', offset:2}}>
                    <div className="mt-5">
                        {fetchContext.fetchs.product.responseData.sketchfab && <div className="ratio ratio-16x9 mb-3">
                            <iframe
                                title={fetchContext.fetchs.product.responseData.name}
                                frameborder="0"
                                allowfullscreen
                                mozallowfullscreen="true"
                                webkitallowfullscreen="true"
                                allow="autoplay; fullscreen; xr-spatial-tracking"
                                xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share
                                src={`https://sketchfab.com/models/${fetchContext.fetchs.product.responseData.sketchfab}/embed`}
                            >
                            </iframe>
                        </div>}
                        
                        {parse(fetchContext.fetchs.product.responseData.description)}
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}



export default Product
