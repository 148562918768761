const ACCOUNTS_API  = 'accounts/api/'
const MARKET_API    = 'market/api/'


export const ACCOUNTS_TOKEN         = 'accounts/token/'
export const ACCOUNTS_TOKEN_REFRESH = 'accounts/token/refresh/'

export const ACCOUNTS_SIGNUP    = ACCOUNTS_API + 'signup/'
// export const ACCOUNTS_USER      = ACCOUNTS_API + 'user/'
export const ACCOUNTS_USERNAME  = ACCOUNTS_API + 'username/'

export const ACCOUNTS_LOGOUT_OUT = ACCOUNTS_API + 'logout/out/'

export const ACCOUNTS_EMAIL_RESET   = ACCOUNTS_API + 'email/reset/'
export const ACCOUNTS_EMAIL_CHANGE  = ACCOUNTS_API + 'email/change/'
export const ACCOUNTS_EMAIL_VERIFY  = ACCOUNTS_API + 'email/verify/'

export const ACCOUNTS_PROFILE           = ACCOUNTS_API + 'profile/'
export const ACCOUNTS_PROFILE_PWD       = ACCOUNTS_API + 'profile/pwd/'
export const ACCOUNTS_PROFILE_CURRENT   = ACCOUNTS_API + 'profile/current/'
export const ACCOUNTS_PROFILE_RESEND    = ACCOUNTS_API + 'profile/resend/'


export const PRODUCTCARD        = MARKET_API + 'productcard/'
export const PRODUCTPAGE        = MARKET_API + 'productpage/'
export const PRODUCTSTORE       = MARKET_API + 'productstore/'
export const PRODUCTFILE        = MARKET_API + 'productfile/'
export const PRODUCTDETAIL      = MARKET_API + 'productdetail/'
// export const PRODUCTDETAILEDIT  = MARKET_API + 'productdetailedit/'
export const ORDERPRODUCT       = MARKET_API + 'orderproduct/'
export const LIBRARY            = MARKET_API + 'library/'
export const CELLAR             = MARKET_API + 'cellar/'
export const CELLAREDIT         = MARKET_API + 'cellaredit/'
// export const CELLARPRODUCT      = MARKET_API + 'cellarproduct/'
export const CHECKOUT           = MARKET_API + 'checkout/'
// export const PRODUCTPRICE       = MARKET_API + 'productprice/'
export const NEWS               = MARKET_API + 'news/'

export const CUSTODIAN_UPDATERATES      = MARKET_API + 'custodian/updaterates/'
export const CUSTODIAN_UPDATELPPI       = MARKET_API + 'custodian/updatelppi/'
export const CUSTODIAN_UPDATETAGS       = MARKET_API + 'custodian/updatetags/'
export const CUSTODIAN_CLEANEVENTS      = MARKET_API + 'custodian/cleanevents/'
export const CUSTODIAN_SITEMAP          = MARKET_API + 'custodian/sitemap/'
export const CUSTODIAN_SOCIALNETWORKS   = MARKET_API + 'custodian/socialnetworks/'
// export const CUSTODIAN_DOC          = MARKET_API + 'custodian/doc/'
export const CUSTODIAN_APPROVE          = MARKET_API + 'custodian/approve/'
export const CUSTODIAN_REJECT           = MARKET_API + 'custodian/reject/'
export const CUSTODIAN_NEWSLETTER       = MARKET_API + 'custodian/newsletter/'
export const CUSTODIAN_NEWSSCHEDULER    = MARKET_API + 'custodian/newsscheduler/'
export const CUSTODIAN_ADDCOLDEMAILS    = MARKET_API + 'custodian/addcoldemails/'
export const CUSTODIAN_COLDOUTREACH     = MARKET_API + 'custodian/coldoutreach/'
export const CUSTODIAN_ONESHOT          = MARKET_API + 'custodian/oneshot/'

export const UPDATEPRODUCT_IMG              = MARKET_API + 'updateproduct/img/'
export const UPDATEPRODUCT_COV              = MARKET_API + 'updateproduct/cov/'
export const UPDATEPRODUCT_ADDDATA          = MARKET_API + 'updateproduct/adddata/'
export const UPDATEPRODUCT_DELDATA          = MARKET_API + 'updateproduct/deldata/'
export const UPDATEPRODUCT_GETSKETCHFAB     = MARKET_API + 'updateproduct/getsketchfab/'

// export const CHECKS_EMAILEXISTS = MARKET_API + 'checks/emailexists/'

export const S3S_IMG    = MARKET_API + 's3s/img/'
export const S3S_FILE   = MARKET_API + 's3s/file/'
export const S3S_GET    = MARKET_API + 's3s/get/'
export const S3S_DEL    = MARKET_API + 's3s/del/'
export const S3S_DELIMG = MARKET_API + 's3s/delimg/'

export const STORE_CATEGORIES       = MARKET_API + 'store/categories/'
export const STORE_ACTIVES          = MARKET_API + 'store/activestores/'
export const STORE_ALL              = MARKET_API + 'store/stores/'
export const STORE_CONTACT          = MARKET_API + 'store/contact/'
export const STORE_GIFT             = MARKET_API + 'store/gift/'
export const STORE_TAGS             = MARKET_API + 'store/tags/'
export const STORE_UPDATE_TAG_STORE = MARKET_API + 'store/update_tag_store/'

export const COUNTRY_CURRENT    = MARKET_API + 'country/current/'
export const COUNTRY_CHOOSE     = MARKET_API + 'country/choose/'

export const STRIPE_PAYMENTINTENTS  = MARKET_API + 'stripe/paymentintents/'
export const STRIPE_SAVEORDER       = MARKET_API + 'stripe/saveorder/'
export const STRIPE_DETACH          = MARKET_API + 'stripe/detach/'
export const STRIPE_SETUPINTENT     = MARKET_API + 'stripe/setupintent/'
export const STRIPE_STRIPELINK      = MARKET_API + 'stripe/stripelink/'
export const STRIPE_CREATESTRIPE    = MARKET_API + 'stripe/createstripe/'
export const STRIPE_LINKSTRIPE      = MARKET_API + 'stripe/linkstripe/'
export const STRIPE_REMOVE          = MARKET_API + 'stripe/remove/'
export const STRIPE_REFUND          = MARKET_API + 'stripe/refund/'
export const STRIPE_ONBOARD         = MARKET_API + 'stripe/onboard/'
export const STRIPE_PAYTRANSFER     = MARKET_API + 'stripe/paytransfer/'


export const DASHBOARD_ANALYTICS    = MARKET_API + 'dashboard/analytics/'
export const ANALYTICS_VISITORS     = MARKET_API + 'analytics/visitors/'
export const ANALYTICS_PRIDE        = MARKET_API + 'analytics/pride/'
export const ANALYTICS_PURCHASES    = MARKET_API + 'analytics/purchases/'
