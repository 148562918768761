import React, { useContext } from "react";
import { Navigate, Route, Routes } from 'react-router-dom'
import { Container, Nav } from "reactstrap";

import { AuthDispatchContext } from '../../App'
import Cellar from "./Cellar";
import Library from "./Library";
import MenuCellar from "../../components/menus/MenuCellar";
import { SEO } from "../../components/SEO";
import { CellarDoorName } from "../../components/CellarReplace";
import { SiteName } from "../../components/SiteTitle";
import { ViewAs } from "../doc/Doc";


const CellarDoor = () => {
    const authDispatchContext = useContext(AuthDispatchContext)

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    // function onCellar(event) {
    //     event.preventDefault()
    //     navigate(`/cellar`)
    // }

    // function onLibrary(event) {
    //     event.preventDefault()
    //     navigate(`library`)
    // }

    return (<>
        <SEO title={`${CellarDoorName} - ${SiteName}`} />
        
        <Container className='mt-5'>
            <div className='d-none d-md-block mb-3'>
                <Nav tabs>
                    <MenuCellar/>
                </Nav>
            </div>

            <ViewAs/>

            <Routes>
                <Route exact path='library' element={<Library />} />
                <Route path='*' element={<Cellar />} />
            </Routes>
        </Container>
    </>)
}

export default CellarDoor