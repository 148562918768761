import React, { createContext, useEffect, useReducer, useState } from "react"
import { BrowserRouter, Route, Routes, matchPath } from 'react-router-dom'

import Layout from "./hocs/Layout"
// import Home from './containers/Home'
import Search from "./containers/Search"
import Signup from './containers/login/Signup'
import Login from './containers/login/Login'
import Product from "./components/Product"
import Profile from './containers/profile/Profile'
import CellarDoor from './containers/cellar/CellarDoor'
import Store from './containers/store/Store'
import Checkout from "./containers/checkout/Checkout"

import * as Auth from "./actions/AuthActions"

import * as AuthRedux from './reducers/AuthReducer'
import CheckoutPay from "./containers/checkout/CheckoutPay"
import About from "./containers/About"
import FAQs from "./containers/FAQs"
import { getSubdomain } from "./tools/Subdomain"
import Castle from "./containers/Castle"
import UserDomain from "./components/UserDomain"
import Doc from "./containers/doc/Doc"
import Verify from "./containers/login/Verify"
import ResetPWD from "./containers/login/ResetPWD"
import Legal from "./containers/legal/Legal"
import Fetch from "./hocs/Fetch"
import News, { OneNews } from "./containers/News"
import { SiteName } from "./components/SiteTitle"
import { SEO } from "./components/SEO"
import Embed from "./components/Embed"
import Philosophy from "./containers/Philosophy"
import Loading from "./components/Loading"
import { Container } from "reactstrap"


export const AuthDispatchContext = createContext(null)


const App = () => {
    const [auths, authDispatch] = useReducer(AuthRedux.authReducer, AuthRedux.initialState)
    const [navAdd, setNavAdd] = useState([])
    const [filterTags, setFilterTags] = useState([])
    const [filterNames, setFilterNames] = useState([])

    const [redirect, setRedirect] = useState([])

    const [subdomain, isDomain, isUser, isNeither] = getSubdomain()

    const embedSlug = matchPath('/embed/:slug', window.location.pathname)
    const slug = embedSlug ? embedSlug.params.slug : ''

    const embedUser = matchPath('/embed/u/:user', window.location.pathname)
    const embedU = embedUser ? embedUser.params.user : ''

    const pushNavAdd = (func) => {
        setNavAdd([...navAdd, func])
    }

    const navigateRedirect = (navigate, dest, redirect=undefined) => {
        if (redirect) {
            setRedirect(redirect)
        }
        else {
            setRedirect(window.location.pathname)
        }

        navigate(dest)
    }

    const navigateBack = (navigate) => {
        if (redirect) {
            navigate(redirect)
            setRedirect('')
        }
        else {
            navigate('/')
        }
    }

    useEffect(() => {
        if (embedSlug || embedU) {
            return
        }

        // authDispatch({type: AuthRedux.LOGIN_CHECK})
        Auth.relogin(
            authDispatch,
            // formValue.email,
            // formValue.password,
            // (error) => {
            //     if (error.response.status === 401) {
            //         setErrMsg('No active account found with the given credentials')
            //     }
            //}
            )     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (embedSlug) {
        return <Embed slug={slug}/>
    }

    if (embedU) {
        return <Embed user={embedU}/>
    }


    // Checking in progress...
    if (!auths || auths.isAuthenticated === null) {
        return (
            <Container className="mt-3">
                <Loading/>
            </Container>
        )
    }

    return (
        <AuthDispatchContext.Provider value={{
            state: auths, dispatch: authDispatch,
            navAdd:navAdd,
            pushNavAdd:pushNavAdd,
            filterTags:filterTags, setFilterTags:setFilterTags,
            filterNames:filterNames, setFilterNames:setFilterNames,
            navigateRedirect:navigateRedirect,
            navigateBack:navigateBack,
            domain:subdomain,
            isDomain:isDomain,
            isUser:isUser,
        }}>
            <SEO
                title={SiteName}
                description={`Welcome to ${SiteName}: The Marketplace that Empowers Purchasing Power Parity (PPP) and Ensures Fair Visibility!`}
                // canonical={''}
            />

            <BrowserRouter>
                <Fetch>
                    <Layout>
                        {isNeither &&
                        <Routes>
                            <Route exact path='/signup' element={<Signup />} />
                            <Route exact path='/login' element={<Login />} />
                            <Route exact path='/verify' element={<Verify />} />
                            <Route exact path='/product/:slug' element={<Product />} />
                            <Route exact path='/profile' element={<Profile />} />
                            <Route exact path='/cellar/*' element={<CellarDoor />} />
                            <Route exact path='/store/*' element={<Store />} />
                            <Route exact path='/checkout' element={<Checkout />} />
                            <Route exact path='/checkoutpay' element={<CheckoutPay />} />
                            <Route exact path='/about' element={<About />} />
                            <Route exact path='/philosophy' element={<Philosophy />} />
                            <Route exact path='/faqs' element={<FAQs />} />
                            <Route exact path='/legal/*' element={<Legal />} />
                            <Route exact path='/doc' element={<Doc />} />
                            <Route exact path='/resetpwd/:token' element={<ResetPWD />} />
                            <Route exact path='/news/:id' element={<OneNews />} />
                            <Route exact path='/news' element={<News />} />
                            <Route exact path='*' element={<Castle />} />
                        </Routes>}

                        {isDomain &&
                        <Routes>
                            <Route exact path='/signup' element={<Signup />} />
                            <Route exact path='/login' element={<Login />} />
                            <Route exact path='/verify' element={<Verify />} />
                            <Route exact path='/product/:slug' element={<Product />} />
                            <Route exact path='/profile' element={<Profile />} />
                            <Route exact path='/cellar/*' element={<CellarDoor />} />
                            <Route exact path='/store/*' element={<Store />} />
                            <Route exact path='/checkout' element={<Checkout />} />
                            <Route exact path='/checkoutpay' element={<CheckoutPay />} />
                            <Route exact path='/search' element={<Search />} />
                            <Route exact path='/about' element={<About />} />
                            <Route exact path='/philosophy' element={<Philosophy />} />
                            <Route exact path='/faqs' element={<FAQs />} />
                            <Route exact path='/legal/*' element={<Legal />} />
                            <Route exact path='/doc' element={<Doc />} />
                            <Route exact path='/news/:id' element={<OneNews />} />
                            <Route exact path='/news' element={<News />} />
                            <Route exact path='*' element={<Search />} />
                        </Routes>}

                        {isUser &&
                        <Routes>
                            <Route exact path='*' element={<UserDomain />} />
                        </Routes>}
                    </Layout>
                </Fetch>
            </BrowserRouter>
        </AuthDispatchContext.Provider>
    )
}

export default App