


export const slug = function(str) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()
  
    // remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;"
    var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------"
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
  
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
             .replace(/\s+/g, '-') // collapse whitespace and replace by -
             .replace(/-+/g, '-') // collapse dashes
  
    return str
}



export const dictToQuery = function(dict, encodeURI=false) {
    var str = []

    for (var p in dict) {
        if (dict.hasOwnProperty(p)) {
            if (encodeURI) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]))
            }
            else {
                str.push(p + "=" + dict[p])
            }
        }
    }
    return str.join("&")
}


export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}