import React, { useContext } from "react"

import { LayoutContext } from "../hocs/Layout"
import InfiniteScroll from "../components/InfiniteScroll"
import { AuthDispatchContext } from "../App"
import { Container } from "reactstrap"

import * as Endpoints from '../Endpoints'
import { Empty } from "../components/Empty"
import NewsArticle, { EditNewsArticle } from "../components/NewsArticle"
import { CloudURL } from "../tools/Cloud"
import { SEO } from "../components/SEO"
import { SiteName } from "../components/SiteTitle"
import { useParams } from "react-router"
import { useRequest } from "../hooks/Hooks"
import Loading from "../components/Loading"



export const OneNews = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const { id } = useParams()
    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, `${Endpoints.NEWS}${id}/`, { useCache:true })

    if (hasError) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
            )
    }

    if (isLoading || !responseData) {
        return (
            <Container className='mt-5'>
                <Loading/>
            </Container>
            )
    }

    return (
        <Container className='mt-5 mb-5' style={{ maxWidth:960 }}>
            <NewsArticle article={responseData}/>
        </Container>
    )
}



const News = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    const showDoc = authDispatchContext.state.isAuthenticated && authDispatchContext.state.user.doc


    const renderItem = (item) => {
        if (showDoc) {
            return <EditNewsArticle article={item}/>
        }

        return <NewsArticle article={item}/>
    }


    return (<>
        <SEO title={`News - ${SiteName}`} />

        <Container className='mt-5 mb-5' style={{ maxWidth:960 }}>
            {showDoc && <EditNewsArticle/>}

            <h2>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                News
            </h2>

            <InfiniteScroll
                scrollerRef={layoutContext.scroller}
                endpoint={Endpoints.NEWS}
                // filters={}
                getKey={item => item.id}
                renderItem={renderItem}
                useCache={false}
                renderEmpty={() => <Empty msg="No news yet."/>}
                config = {{
                    xl:1,
                    lg:1,
                    md:1,
                    sm:1,
                    xs:1,                
                    fetchRows:3,
                    maxRows:666
                }}
            />
        </Container>
    </>)
}

export default News