import { Col, Container, Row } from "reactstrap"
import { ButtonBG, SiteName } from "../components/SiteTitle"
import { useContext } from "react"
import { AuthDispatchContext } from "../App"
import * as Endpoints from '../Endpoints'
import Loading from "../components/Loading"
import { useRequest } from "../hooks/Hooks"
import { SEO } from "../components/SEO"
import { Link } from "react-router-dom"
import { Trustpilot, TrustpilotReview } from "../components/Trustpilot"



const Castle = () => {
    const authDispatchContext = useContext(AuthDispatchContext)

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, Endpoints.STORE_ACTIVES, { method:'post', useCache:true })

    if (hasError) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
            )
    }

    if (isLoading || !responseData) {
        return (
            <Container className='mt-5'>
                <Loading/>
            </Container>
            )
    }

    return (<>
        <SEO domain="www" canonical={''} />

        <Container className='mt-5'>
            {/* <h1>
                Where is the King?
            </h1> */}
            <p className="text-center">{`Welcome to ${SiteName}: The Marketplace that Empowers Purchasing Power Parity (PPP) and Ensures Fair Visibility!`}</p>

            <h6 className="mt-4 text-center">
                <Link to='/about'>About</Link>
                &nbsp;-&nbsp;
                <Link to='/philosophy'>Philosophy</Link>
            </h6>

            <h5 className="mt-5 mb-5 text-center"><b>A place for Ethical Creators</b>{false && <>&nbsp;<Trustpilot/></>}</h5>
            
            <Row
                xs='1'
                md='2'
                xl='3'
                className="justify-content-center"
            >
                {responseData.blender && <Col className="mb-3"><ButtonBG label={'Blender'}/></Col>}
                {responseData.unreal && <Col className="mb-3"><ButtonBG label={'Unreal'}/></Col>}
                {responseData.godot && <Col className="mb-3"><ButtonBG label={'Godot'}/></Col>}
                {responseData.indiegames && <Col className="mb-3"><ButtonBG label={'Indie Games'}/></Col>}
                {responseData.houdini && <Col className="mb-3"><ButtonBG label={'Houdini'}/></Col>}
                {responseData['3dsmax'] && <Col className="mb-3"><ButtonBG label={'3ds Max'}/></Col>}
                {responseData.maya && <Col className="mb-3"><ButtonBG label={'Maya'}/></Col>}
                {responseData.books && <Col className="mb-3"><ButtonBG label={'Books'}/></Col>}
                {responseData['3dmodels'] && <Col className="mb-3"><ButtonBG label={'3d Models'}/></Col>}
                {responseData.fiberart && <Col className="mb-3"><ButtonBG label={'Fiber Art'}/></Col>}
            </Row>


            <Row
                xs='1'
                md='2'
                xl='3'
                className="mt-5 mb-5 justify-content-center"
            >
                <Col>
                    <TrustpilotReview
                        title = "Exactly who they say they are!"
                        desc = {`Providing a market with fair prices!                        
                            Purchased a software Blender plugin from them that was also listed on the Blender Market website at a lot more expensive price. Cost NZ$36 from them, compared to NZ120.83 on Blender Market. That's a massive difference in price!                        
                            I guess they must be charging developers lower fees for selling their products compared to companies elsewhere.                        
                            Very happy with purchase and will return to buy more from them.`}
                        name = "Julian Groves"
                        link = "https://www.trustpilot.com/reviews/657e588a6fd11de48366908c"
                    />
                </Col>
            </Row>
        </Container>
    </>)
}

export default Castle