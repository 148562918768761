import { useContext, useEffect, useState } from "react"
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, Row } from "reactstrap"
import { useRequest } from "../../hooks/Hooks"

import { AuthDispatchContext } from "../../App"

import Loading from "../../components/Loading"
import Onboard from '../checkout/Onboard'

import * as Endpoints from '../../Endpoints'
import * as Request from '../../actions/Request'
import { useDomainNavigate } from "../../tools/Subdomain"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"

import { Graph } from "../store/Dashboard"
import { ResponsiveChoroplethCanvas } from '@nivo/geo'
import { FormattedNumber } from "react-intl"

import * as Storage from '../../tools/Storage'
import EditTags from "../../components/menus/EditTags"



export const ViewAsUsed = () => {
    const [viewAsEmail] = useState(Storage.getItem('viewAs'))

    return viewAsEmail
}



export const ViewAs = () => {
    const [viewAsEmail] = useState(Storage.getItem('viewAs'))

    return (
        <>
            {viewAsEmail &&
            <Alert>
                Viewing as <b>{viewAsEmail}</b>
            </Alert>}
        </>
    )
}



const Doc = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const [fetching, setFetching] = useState(false)
    const [fetchingLppi, setFetchingLppi] = useState(false)
    const [fetchingTags, setFetchingTags] = useState(false)
    const [fetchingCleanEvents, setFetchingCleanEvents] = useState(false)

    const { responseData, hasError, reFetch } = useRequest(authDispatchContext.dispatch, Endpoints.CUSTODIAN_UPDATERATES, { auth:true, firstCall:false, method:'post' })
    const { responseData: responseDataLppi, hasError: hasErrorLppi, reFetch: reFetchLppi } = useRequest(authDispatchContext.dispatch, Endpoints.CUSTODIAN_UPDATELPPI, { auth:true, firstCall:false, method:'post' })
    const { responseData: responseDataTags, hasError: hasErrorTags, reFetch: reFetchTags } = useRequest(authDispatchContext.dispatch, Endpoints.CUSTODIAN_UPDATETAGS, { auth:true, firstCall:false, method:'post' })
    const { responseData: responseDataCleanEvents, hasError: hasErrorCleanEvents, reFetch: reFetchCleanEvents } = useRequest(authDispatchContext.dispatch, Endpoints.CUSTODIAN_CLEANEVENTS, { auth:true, firstCall:false, method:'post' })

    const { responseData: responseDataVisitors, reFetch: reFetchVisitors } = useRequest(authDispatchContext.dispatch, Endpoints.ANALYTICS_VISITORS, { method:'post' })
    const { responseData: responseDataPride } = useRequest(authDispatchContext.dispatch, Endpoints.ANALYTICS_PRIDE, { method:'post' })
    const { responseData: responseDataPurchases } = useRequest(authDispatchContext.dispatch, Endpoints.ANALYTICS_PURCHASES, { method:'post' })

    const [viewAsEmail, setViewAsEmail] = useState(Storage.getItem('viewAs'))
    const [emails, setEmails] = useState('')
    const [visitors, setVisitors] = useState([])
    const [visitorsPerCountry, setVisitorsPerCountry] = useState([])
    const [maxVisitorsPerCountry, setMaxVisitorsPerCountry] = useState(100)
    const [purchasesPerCountry, setPurchasesPerCountry] = useState([])
    const [maxPurchasesPerCountry, setMaxPurchasesPerCountry] = useState(100)
    const [pride, setPride] = useState()
    const [geoFeatures, setGeoFeatures] = useState(null)


    useEffect(() => {
        fetch('/world_countries.json')
        .then(response => response.json())
        .then(data => {
            setGeoFeatures(data.features)
        })
        .catch(error => {
            console.error('Error fetching the JSON file:', error);
        })
    }, [])


    useEffect(() => {
        if (!responseDataVisitors) {
            return
        }

        const vpc = responseDataVisitors.country

        const maxValue = vpc.reduce((max, current) => (current.value > max ? current.value : max), 0)
        setMaxVisitorsPerCountry(maxValue)

        setVisitors(responseDataVisitors.graph)
        setVisitorsPerCountry(vpc)

    }, [responseDataVisitors])
    

    useEffect(() => {
        if (!responseDataPride) {
            return
        }

        setPride(responseDataPride)

    }, [responseDataPride])
    

    useEffect(() => {
        if (!responseDataPurchases) {
            return
        }

        const ppc = responseDataPurchases.country

        const maxValue = ppc.reduce((max, current) => (current.value > max ? current.value : max), 0)
        setMaxPurchasesPerCountry(maxValue)

        setPurchasesPerCountry(ppc)

    }, [responseDataPurchases])
    

    const onRates = async() => {
        setFetching(true)
        await reFetch()
        setFetching(false)
    }


    const onLppi = async() => {
        setFetchingLppi(true)
        await reFetchLppi()
        setFetchingLppi(false)
    }


    const onTags = async() => {
        setFetchingTags(true)
        await reFetchTags()
        setFetchingTags(false)
    }


    const onCleanEvents = async() => {
        setFetchingCleanEvents(true)
        await reFetchCleanEvents()
        setFetchingCleanEvents(false)
    }    


    const onLinkStripe = async(event) => {
        // setFreezeButton(true)

        try {
            const response = await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_LINKSTRIPE)
            if (response.status === 200) {
                window.location.href = response.data.url
            }
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }


    const onPayTransfer = async(event) => {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_PAYTRANSFER)
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }


    const onUpdateSitemap = async(event) => {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_SITEMAP)
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }


    const onSocialNetworks = async(event) => {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_SOCIALNETWORKS)
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }

    
    const onNewsletter = async(event) => {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_NEWSLETTER)
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }


    const onAddColdEmails = async(event) => {
        try {
            const params = {
                emails: emails,
            }

            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_ADDCOLDEMAILS, params)
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }
    

    const onColdOutreach = async(event) => {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_COLDOUTREACH)
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }

    
    const onOneShot = async(event) => {
        try {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.CUSTODIAN_ONESHOT)
            reFetchVisitors()
        }
        catch (error) {
            // TODO: Notify Error
        }
        finally {
            // setFreezeButton(false)
        }
    }


    const onViewAs = () => {
        if (viewAsEmail) {
            Storage.setItem('viewAs', viewAsEmail)
        }
        else {
            Storage.removeItem('viewAs')
        }
    }


    const isDoc = authDispatchContext.state.isAuthenticated && authDispatchContext.state.user.doc

    if (!isDoc) {
        navigate('/')
        return <></>
    }

    return (<>
        <SEO title={`Doc - ${SiteName}`} />

        <Container className='mt-5' style={{ maxWidth:696 }}>
            <div className='m-1'>
                <Card>
                    <CardHeader>
                        View As...
                    </CardHeader>
                    <CardBody>
                        <InputGroup>
                            <Input
                                // className='form-control'
                                type="email"
                                name="email"
                                placeholder="enter an email"
                                value={viewAsEmail}
                                onChange={(e) => {setViewAsEmail(e.target.value)}}
                                autoComplete="email"
                                // required
                            />
                            <Button onClick={onViewAs}>
                                Apply
                            </Button>
                        </InputGroup>
                    </CardBody>
                </Card>
            </div>

            <div className='m-1'>
                <Card><CardBody>Rates should be automatically pushed via Cron.</CardBody></Card>
                <Button
                    block
                    color={fetching ? "warning" : "primary"}
                    onClick={onRates}
                    disabled={fetching}
                >
                    {fetching && <Loading/>}
                    Rates
                    {responseData.status && <span> Ok</span>}
                </Button>

                {hasError &&
                <Alert color='danger'>
                    An error occured.
                </Alert>}
            </div>

            
            <div className='m-1'>
                <Card><CardBody>Lppi has to be updated twice a year.</CardBody></Card>
                <Button
                    block
                    color={fetchingLppi ? "warning" : "primary"}
                    onClick={onLppi}
                    disabled={fetchingLppi}
                >
                    {fetchingLppi && <Loading/>}
                    Lppi
                    {responseDataLppi.status && <span> Ok</span>}
                </Button>

                {hasErrorLppi &&
                <Alert color='danger'>
                    An error occured.
                </Alert>}
            </div>


            <div className='m-1'>
                <Card><CardBody>Tags might be created by custom request.</CardBody></Card>
                <Button
                    block
                    color={fetchingTags ? "warning" : "primary"}
                    onClick={onTags}
                    disabled={fetchingTags}
                >
                    {fetchingTags && <Loading/>}
                    Tags
                    {responseDataTags.status && <span> Ok</span>}
                </Button>

                {hasErrorTags &&
                <Alert color='danger'>
                    An error occured.
                </Alert>}
            </div>

            <div className='m-1'>
                <Card><CardBody>CleanEvents should be automatically pushed via Cron.</CardBody></Card>
                <Button
                    block
                    color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onCleanEvents}
                    disabled={fetchingCleanEvents}
                >
                    {fetchingCleanEvents && <Loading/>}
                    Tags
                    {responseDataCleanEvents.status && <span> Ok</span>}
                </Button>

                {hasErrorCleanEvents &&
                <Alert color='danger'>
                    An error occured.
                </Alert>}
            </div>

            <div className='m-1'>
                <Card><CardBody>Link Stripe Account.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onLinkStripe}
                    // disabled={fetchingCleanEvents}
                >
                    <Onboard/>
                </Button>
            </div>

            <div className='m-1'>
                <Card><CardBody>Test PayTranfer.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onPayTransfer}
                    // disabled={fetchingCleanEvents}
                >
                    Pay!
                </Button>
            </div>

            <div className='m-1'>
                <Card><CardBody>Update Sitemap.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onUpdateSitemap}
                    // disabled={fetchingCleanEvents}
                >
                    Update!
                </Button>
            </div>

            <div className='m-1'>
                <Card><CardBody>Social Networks.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onSocialNetworks}
                    // disabled={fetchingCleanEvents}
                >
                    Social Networks!
                </Button>
            </div>

            <div className='m-1'>
                <Card><CardBody>Newsletter.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onNewsletter}
                    // disabled={fetchingCleanEvents}
                >
                    Send Newsletter!
                </Button>
            </div>

            <div className='m-1'>
                <Card><CardBody>Cold Email Outreach DB.</CardBody></Card>
                {/* <Form onSubmit={handleSubmit}> */}
                    <Input
                        className='form-control'
                        type="textarea"
                        name="emails"
                        placeholder="emails"
                        value={emails}
                        onChange={(event) => setEmails(event.target.value)}
                        required
                    />
                    <Button
                        block
                        // color={fetchingCleanEvents ? "warning" : "primary"}
                        onClick={onAddColdEmails}
                        // disabled={fetchingCleanEvents}
                        >
                        Add infos!
                    </Button>
                {/* </Form> */}
            </div>

            <div className='m-1'>
                <Card><CardBody>Cold Outreach.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onColdOutreach}
                    // disabled={fetchingCleanEvents}
                >
                    Send Cold Email Outreachs!
                </Button>
            </div>

            <div className='m-1'>
                <Card><CardBody>OneShot.</CardBody></Card>
                <Button
                    block
                    // color={fetchingCleanEvents ? "warning" : "primary"}
                    onClick={onOneShot}
                    // disabled={fetchingCleanEvents}
                >
                    OneShot
                </Button>
            </div>

            <Card>
                <CardHeader>
                    Visitors per Day
                </CardHeader>
                <CardBody style={{ height: "200px" }}>
                    <Graph data={visitors}/>
                </CardBody>
            </Card>

            {geoFeatures && visitorsPerCountry.length && <Card>
                <CardHeader>
                    Visitors per Country
                </CardHeader>
                <CardBody>
                    <div style={{ height: '500px' }}>
                        <ResponsiveChoroplethCanvas
                            data={visitorsPerCountry}
                            features={geoFeatures}
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            colors="nivo"
                            domain={[ 0, maxVisitorsPerCountry ]}
                            unknownColor="#666666"
                            label="properties.name"
                            valueFormat=".0f"
                            projectionTranslation={[ 0.5, 0.5 ]}
                            projectionRotation={[ 0, 0, 0 ]}
                            enableGraticule={true}
                            graticuleLineColor="rgba(0, 0, 0, .2)"
                            borderWidth={0.5}
                            borderColor="#152538"
                            legends={[
                                {
                                    anchor: 'bottom-left',
                                    direction: 'column',
                                    justify: true,
                                    translateX: 20,
                                    translateY: -60,
                                    itemsSpacing: 0,
                                    itemWidth: 92,
                                    itemHeight: 18,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 18
                                }
                            ]}
                        />
                    </div>
                </CardBody>
            </Card>}

            <Card>
                <CardHeader>
                    Pride Numbers
                </CardHeader>
                <CardBody>
                {pride && pride.map((item, i) => {
                    return (                        
                        <Row key={item.name}>
                            <Col>
                                <b>{item.name}</b>
                            </Col>
                            <Col className="text-end">
                                <FormattedNumber                    
                                    value={item.value / 100.0}
                                    style={`currency`}
                                    currency={'USD'}
                                    maximumFractionDigits='2'
                                />
                            </Col>
                        </Row>
                    )
                })}
                </CardBody>
            </Card>

            {geoFeatures && purchasesPerCountry.length && <Card>
                <CardHeader>
                    Purchases per Country
                </CardHeader>
                <CardBody>
                    <div style={{ height: '500px' }}>
                        <ResponsiveChoroplethCanvas
                            data={purchasesPerCountry}
                            features={geoFeatures}
                            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                            colors="nivo"
                            domain={[ 0, maxPurchasesPerCountry ]}
                            unknownColor="#666666"
                            label="properties.name"
                            valueFormat=".0f"
                            projectionTranslation={[ 0.5, 0.5 ]}
                            projectionRotation={[ 0, 0, 0 ]}
                            enableGraticule={true}
                            graticuleLineColor="rgba(0, 0, 0, .2)"
                            borderWidth={0.5}
                            borderColor="#152538"
                            legends={[
                                {
                                    anchor: 'bottom-left',
                                    direction: 'column',
                                    justify: true,
                                    translateX: 20,
                                    translateY: -60,
                                    itemsSpacing: 0,
                                    itemWidth: 92,
                                    itemHeight: 18,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 18
                                }
                            ]}
                        />
                    </div>
                </CardBody>
            </Card>}
        </Container>

        <Container className='mt-5' style={{ maxWidth:1280 }}>
            <div className='m-1'>
                <Card>
                    <CardHeader>
                        Tags
                    </CardHeader>
                    <CardBody style={{ maxHeight: "600px", overflow:"scroll" }} className="pt-0">
                        <EditTags/>
                    </CardBody>
                </Card>
            </div>
        </Container>
    </>)
}



export default Doc