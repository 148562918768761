import React, { useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { AuthDispatchContext } from "../App"
import CategoryFilters from "../components/CategoryFilters"
import InfiniteScroll from "../components/InfiniteScroll"
import NavCategory from "../components/NavCategory"
import ProductCard from "../components/ProductCard"
// import SearchBar from "../components/SearchBar"
import { LayoutContext } from "../hocs/Layout"
import { areArraysEqual } from "../tools/ArrayTools"
import Loading from "../components/Loading"
import { Col, Container, Input, Label, Row } from "reactstrap"

import * as Endpoints from '../Endpoints'
import { EmptySearch } from "../components/Empty"
import { SEO } from "../components/SEO"
import { SiteName } from "../components/SiteTitle"



const createFilterTag = (name, mode, f) => {
    return {
        name: name,
        mode: mode,
        catName: f[2],
        label: f[3]
    }
}



const serializeFilterTag = (item) => {
    return [item.name, item.mode.name, item.catName, item.label].join('_')
}



const toStringFilterTag = (item) => {
    return `${item.catName}: ${item.label}`
}



const createFilterName = (name, mode, f) => {
    return {
        name: name,
        mode: mode,
    }
}



const serializeFilterName = (item) => {
    return [item.name, item.mode.name].join('_')
}



const toStringFilterName = (item) => {
    return `${item.name}`
}



const Search = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)
    const [searchParams] = useSearchParams()

    const filterTags = authDispatchContext.filterTags
    const setFilterTags = authDispatchContext.setFilterTags
    const filterNames = authDispatchContext.filterNames
    const setFilterNames = authDispatchContext.setFilterNames

    const [filters, setFilters] = useState()

    const [docFilters, setDocFilters] = useState({
        active: true,
        rejected: false,
        needsReview: true,
        updated: true,
        approved: false
    })

    function getFilters() {
        const tags = searchParams.getAll('tags')
        const names = searchParams.getAll('names')

        if (names.length>0 && tags.length>0) {
            return { store:authDispatchContext.domain, names:names, tags:tags }
        }

        if (names.length>0) {
            return { store:authDispatchContext.domain, names:names }
        }
        
        if (tags.length>0) {
            return { store:authDispatchContext.domain, tags:tags }
        }
        
        return { store:authDispatchContext.domain }
    }


    function renderItem(item) {
        return <ProductCard product={item} hideAuthor={false} editCard={false}/>
    }


    useEffect(() => {
        const newFilters = getFilters()
        if (areArraysEqual(filters, newFilters)) {
            return
        }

        setFilters(newFilters)

    // eslint-disable-next-line
    }, [searchParams])


    if (!filters) {
        return <Loading/>
    }

    const onChangeFilters = (event) => {
        setDocFilters({
            ...docFilters,
            [event.target.name]: event.target.checked
        })
    }
    const showDoc = authDispatchContext.state.isAuthenticated && authDispatchContext.state.user.doc

    return (<>
        <SEO title={`${authDispatchContext.domain.toUpperCase()} - ${SiteName}`} domain={authDispatchContext.domain} canonical={''} />

        <Container className='mt-3'>
            <NavCategory filtername='tags'/>

            <CategoryFilters filtername='tags' filters={filterTags} setFilters={setFilterTags} createFilter={createFilterTag} serializeFilter={serializeFilterTag} toStringFilter={toStringFilterTag}/>

            {/* <SearchBar filtername='names'/> */}

            <CategoryFilters filtername='names' filters={filterNames} setFilters={setFilterNames} createFilter={createFilterName} serializeFilter={serializeFilterName} toStringFilter={toStringFilterName}/>

            {showDoc && <Container><Row>
                <Col>
                    <Input id='active' type="checkbox" onChange={onChangeFilters} defaultChecked={docFilters.active} name='active'/>
                    <Label htmlFor='active'>Active</Label>
                </Col>
                <Col>
                    <Input id='rejected' type="checkbox" onChange={onChangeFilters} defaultChecked={docFilters.rejected} name='rejected'/>
                    <Label htmlFor='rejected'>Rejected</Label>
                </Col>
                <Col>
                    <Input id='needsReview' type="checkbox" onChange={onChangeFilters} defaultChecked={docFilters.needsReview} name='needsReview'/>
                    <Label htmlFor='needsReview'>NeedsReview</Label>
                </Col>
                <Col>
                    <Input id='updated' type="checkbox" onChange={onChangeFilters} defaultChecked={docFilters.updated} name='updated'/>
                    <Label htmlFor='updated'>Updated</Label>
                </Col>
                <Col>
                    <Input id='approved' type="checkbox" onChange={onChangeFilters} defaultChecked={docFilters.approved} name='approved'/>
                    <Label htmlFor='approved'>Approved</Label>
                </Col>
            </Row></Container>}

            <InfiniteScroll
                scrollerRef={layoutContext.scroller}
                endpoint={Endpoints.PRODUCTCARD}
                // filters={filters}
                filters={showDoc ? {...filters, ...docFilters} : filters}
                getKey={item => item.id}
                renderItem={renderItem}
                useCache={!showDoc}
                renderEmpty={EmptySearch}
            />
        </Container>
    </>)
}

export default Search