import React from "react"
import { Container } from "reactstrap"
import { CloudURL } from "../tools/Cloud"
import { SiteName } from "../components/SiteTitle"
import { SEO } from "../components/SEO"



const QA = (question, answers) => {
    return (
        <div className="mt-5">
            <p><b>{question}</b></p>
            {answers && answers.map((answer, i) => <p>{answer}</p>)}            
        </div>
    )
}


const FAQs = () => {

    return (<>
        <SEO title={`Frequently Asked Questions - ${SiteName}`} />

        <Container className='mt-5 mb-5' style={{ maxWidth:960 }}>
            {/* <h1>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                {SiteName}
            </h1> */}
            <h2>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                Frequently Asked Questions
            </h2>

            <h3 className="mt-5">General</h3>

            {QA(`Is ${SiteName} responsible for defective products sold on the marketplace?`, [
            `No, the marketplace is not responsible for defective products sold. It is the seller's responsibility to ensure the quality of their products.`])}

            {QA(`What is the refund policy on ${SiteName}?`, [
            `There is a no refund policy after 30 days or once a digital good has been downloaded.`])}

            <h3 className="mt-5">As a Seller</h3>

            {QA(`What's the deal?`, [
                <><b>💸 Transparent Pricing:</b> No hidden listing fees, subscriptions, discovery fees, increased merchant or transaction fees, or exorbitant commission rates. You can showcase your work without incurring any costs, and our focus is on providing fair visibility, not pay-to-win schemes.</>,
                <><b>💰 Fair Commission:</b> We charge a modest 10% commission, plus Stripe fees (check out Stripe's pricing details here: <a href="https://stripe.com/pricing" target="_blank" rel="noreferrer">https://stripe.com/pricing</a> and <a href="https://stripe.com/connect/pricing" target="_blank" rel="noreferrer">https://stripe.com/connect/pricing</a>). Our goal is to support your success, not exploit it.</>,
                <><b>🌍 Broader Pool of Buyers:</b> FairMarket.Land is all about fairness. We use the Local Purchasing Power Index (LPPI) and real-time Exchange Rates to meet buyers & seller halfway. Buyers can make purchases in their local currency, ensuring fair and accessible pricing worldwide, while sellers maintain a reasonable and equitable selling price.</>,
                <><b>🌟 Fair Visibility:</b> To achieve this, we have developed an advanced algorithm that calculates visibility fairness based on a combination of factors such as prints, views, purchases, and time.</>,
                <><b>🌈 Choose to Give Back:</b> Yes, you can choose to donate 1% of your sale to either the Blender Foundation and/or the Godot Foundation and/or 1% for the Planet. It's a way to contribute to the creative community you love.</>
            ])}

            {QA(`Can I sell products that are not my original work on ${SiteName}?`, [
            `No, all products sold on ${SiteName} must be made or designed by you and must be original work.`])}

            {QA(`What kind of images or videos can I use to represent my products?`, [
            `You must use your own images or video content that accurately represents the product and its use. Stock photos, images not created by or representing the use of the product, catalogue images, or photos used by other sellers or sites are not allowed.`])}

            {QA(`Can I create a listing on ${SiteName} without offering a digital item for sale?`, [
            `No, all listings on ${SiteName} must offer an item for sale. You cannot create a listing for the purpose of sharing a referral code, posting a want ad, or engaging in similar activities that do not involve offering a digital item for sale.`])}

            {QA(`Can I resell products on ${SiteName}?`, [
            `No, reselling is not allowed on ${SiteName}. Reselling refers to listing an item that you were not involved in designing or making.`])}

            {QA(`What happens if I violate ${SiteName}'s policies?`, [
            `If your listings violate ${SiteName}'s policies, they may be flagged by members and reviewed by ${SiteName}. ${SiteName} reserves the right to remove any listings that violate the policies. Additionally, your account may be suspended or terminated for any violations, and any earnings or commissions associated with the account may be revoked.`])}

            {QA(`Is there a cost to use ${SiteName} as a seller?`, [
            `${SiteName} is free to use, but there are fees associated with each sale. There is a 10% market fee and Stripe charge fee applied to each sale. Additionally, each payout will have a Stripe Express fee.`])}

            {QA(`Can I choose to donate a percentage of my sale to a specific foundation?`, [
            `Yes, you can choose to donate 1% of your sale to either the Blender Foundation and/or the Godot Foundation and/or 1% for the Planet.`])}
        </Container>
    </>)
}

export default FAQs