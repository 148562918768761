import { Container, List } from "reactstrap"
import { SiteName } from "../../components/SiteTitle"
import { CloudURL } from "../../tools/Cloud"
import { SEO } from "../../components/SEO"



const PrivacyPolicy = () => {
    return (<>
        <SEO title={`Privacy Policy - ${SiteName}`} />

        <Container style={{ maxWidth:960 }}>
            {/* <h1>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                {SiteName}
            </h1> */}
            <h2>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                Privacy Policy
            </h2>

            <p>At {SiteName}, we value and respect your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you access and use our online marketplace and related services. By using our website or any of its features, you consent to the practices described in this Privacy Policy. If you do not agree with this Privacy Policy, please refrain from using our services.</p>

            <h5>1. Information We Collect</h5>
            <List>
            <li>Personal Information: When you create an account or interact with our platform, we may collect certain personal information, such as your name, email address, contact details, and payment information.</li>
            <li>Usage Data: We may collect information about your interactions with our website, including your IP address, device information, browser type, pages visited, and other usage data.</li>
            <li>Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience on our website and collect information about your browsing activities.</li>
            </List>

            <h5>2. How We Use Your Information</h5>
            <List>
            <li>To Provide and Improve Services: We use your personal information to operate and maintain the Online Marketplace, process transactions, and improve our services.</li>
            <li>Communication: We may use your contact information to send you important updates, newsletters, and marketing communications related to our services. You can opt-out of these communications at any time.</li>
            <li>Personalization: We may use your information to personalize your experience on the Online Marketplace, recommend relevant digital products, and display targeted advertisements.</li>
            </List>

            <h5>3. Data Sharing and Disclosure</h5>
            <List>
            <li>With Sellers and Buyers: To facilitate transactions, your information may be shared with sellers or buyers involved in the sale or purchase of digital products.</li>
            <li>Third-Party Service Providers: We may share your information with trusted third-party service providers to assist us in operating the platform, processing payments, and improving our services.</li>
            <li>Legal Requirements: We may disclose your information if required by law, in response to a subpoena, court order, or government request, or to protect our rights, privacy, safety, or property.</li>
            </List>

            <h5>4. Data Retention</h5>
            <List>
            <li>We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</li>
            </List>

            <h5>5. Data Security</h5>
            <List>
            <li>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</li>
            <li>While we take reasonable precautions, no data transmission over the internet or electronic storage method is entirely secure. We cannot guarantee the absolute security of your information.</li>
            </List>

            <h5>6. Children's Privacy</h5>
            <List>
            <li>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age. If you believe we have inadvertently collected information from a child, please contact us immediately.</li>
            </List>

            <h5>7. Third-Party Links and Services</h5>
            <List>
            <li>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties, and you should review their respective privacy policies.</li>
            </List>

            <h5>8. Changes to the Privacy Policy</h5>
            <List>
            <li>We reserve the right to update or modify this Privacy Policy at any time. The effective date will be revised accordingly, and significant changes will be communicated to you.</li>
            </List>

            <h5>9. Contact Information</h5>
            <List>
            <li>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <a target="_blank" rel="noreferrer" href={`mailto:support@${process.env.REACT_APP_FRONT_DOMAIN}`}>support@{process.env.REACT_APP_FRONT_DOMAIN}</a>.</li>
            </List>

            <p>By using {SiteName}, you acknowledge that you have read, understood, and agreed to this Privacy Policy. Thank you for trusting us with your personal information, and we are committed to safeguarding your privacy.</p>
        </Container>
    </>)
}

export default PrivacyPolicy