import * as Storage from './Storage'
import Resizer from "react-image-file-resizer"
import * as Endpoints from '../Endpoints'


export function CloudURL(url) {
    if (!url)
        return ''
        
    return process.env.REACT_APP_R2 + '/' + url
}


export function BackURL(url) {
    return process.env.REACT_APP_BACKURL + '/' + url
}


export function FrontURL(url) {
    return window.location.origin + '/' + url
}


export function PrefixENV() {
    const env = process.env.REACT_APP_ENV.toUpperCase()

    if (['PROD', 'PRODUCTION'].includes(env)) {
        return ''
    }
    
    return `${env}`
}


export const resizeFile = (file, maxWidth, maxHeight, compressFormat='WEBP') => new Promise(resolve => {
    Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, 80, 0,
    uri => {
        resolve(uri)
    }, 'file' )
})



const SanitizeFilename = (filename, postfix='') => {
    let parts = filename.split('.')
    const extension = parts.pop()

    parts.push(postfix)

    parts = parts.filter(part => part !== '')

    const joinedString = `${parts.join('_')}.${extension}`

    return joinedString.replace(/_+/g, '_')
}



export const Upload = (file, filename, productID, onUpload=() => {}, isPublicImg=false, isThumbnail=false) => {
    if (isPublicImg) {
        filename = SanitizeFilename(filename, isThumbnail ? 'thumbnail' : '')
    }

    const requestObject = {
        method:'POST' ,
        headers: {
            Authorization: `Bearer ${Storage.getItem('access')}`,
        },
        body:JSON.stringify({
            fileName: filename,
            fileType: file.type,
            pid:productID
        })
    }

    const ep = isPublicImg ? Endpoints.S3S_IMG : Endpoints.S3S_FILE

    fetch(BackURL(ep), requestObject)
    .then((response) => {
        if (response.status === 200)
            return response.json()
    })
    .then((result) => {
        fetch(result.signed_url, {
            method: "PUT",
            body: file,
            headers: {
                'content-type': result.filetype,
                'content-disposition': result.content_disposition
            }
        })
        .then((response) => {
            if (response.status === 200) {
                onUpload(result.filepath, isThumbnail)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    })
    .catch((error) => {
        console.log(error)
    })
}
