import * as Storage from "../tools/Storage"

export const TOKEN_SUCCESS = 'TOKEN_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const LOGIN_CHECK = 'LOGIN_CHECK'


export const initialState = {
    // access: Storage.getItem('access'),
    // refresh: Storage.getItem('refresh'),
    isAuthenticated: null,
    user: null
}


export function authReducer(state=initialState, action) {
    const { type, payload } = action;
    
    switch(type) {
        case TOKEN_SUCCESS:
            Storage.setItem('access', payload.access)
            // Storage.setItem('refresh', payload.refresh)
            return {
                ...state,
                // isAuthenticated: true
            }
        // case LOGIN_FAIL:
        //     Storage.removeItem('access')
        //     Storage.removeItem('refresh')

        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: payload,
            }


        case LOGIN_CHECK:
            const access = Storage.getItem('access')

            if (!access) {
                return {
                    ...state,
                    isAuthenticated: false,
                    user: null
                }
            }

            // Should Verify with Endpoint here
            return {
                ...state,
                isAuthenticated: true
            }


        default:
            Storage.removeItem('access')
            // Storage.removeItem('refresh')
            return {
                ...state,
                isAuthenticated: false,
                user: null
            }
    }
}

