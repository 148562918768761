import React, { useContext } from "react"
import { Navigate } from "react-router"

import { AuthDispatchContext } from '../../App'

import InfiniteScroll from "../../components/InfiniteScroll"
import ProductCard from "../../components/ProductCard"
import { LayoutContext } from "../../hocs/Layout"

import * as Endpoints from '../../Endpoints'
import { EmptyStore } from "../../components/Empty"
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"
import { ViewAs } from "../doc/Doc"



const Products = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const layoutContext = useContext(LayoutContext)

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    const renderItem = (item) => {
        return <ProductCard product={item} hideAuthor={true} editCard={true}/>
    }

    return (
        <>
            <SEO title={`Products - ${SiteName}`} />
            
            <div className='d-md-none d-block mb-3'>
                <h1>Products</h1>
            </div>

            <ViewAs/>

            <InfiniteScroll
                scrollerRef={layoutContext.scroller}
                endpoint={Endpoints.PRODUCTSTORE}
                // filters={{}}
                getKey={item => item.id}
                renderItem={renderItem}
                renderEmpty={EmptyStore}
                auth={true}
                useCache={false}
            />
        </>
    )
}

export default Products