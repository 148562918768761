import { CloudURL } from "../tools/Cloud"


export const CellarDoorName = 'Vault'
export const CellarName = 'Collection'
const shouldUseImg = true


export const CellarDoorReplace = () => {
    return <>{CellarDoorName}</>
}

export const CellarReplace = ({useImg=false, imgCol=false, height=24}) => {
    if (shouldUseImg && useImg) {
        return <img
            height={height}
            // style={{width: '100%', aspectRatio:'16/9', objectFit:'cover'}}
            src={imgCol ? CloudURL('collection_col.webp') : CloudURL('cellar.webp')}
            alt={CellarName}
            // top
            onError={(e) => { e.target.src = CloudURL('missing_cover.webp') }}
        />
    }

    return <>{CellarName}</>
}