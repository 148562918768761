import * as AuthRedux from "../reducers/AuthReducer"
import * as Request from './Request'
import * as Endpoints from '../Endpoints'


export async function login(dispatch, email, password, otp, cbError=()=>{}) {
    try {
        // const loginData = { email: email, password: password }
        // const response = await Request.request(dispatch, 'post', Endpoints.ACCOUNTS_TOKEN, loginData)
        // dispatch({ type: AuthRedux.TOKEN_SUCCESS, payload: response.data })
        await Request.tokenObtainPair(dispatch, email, password, otp)

        relogin(dispatch, cbError)
        // const responseUser = await Request.authRequest(authDispatchContext, 'get', Endpoints.ACCOUNTS_USERDETAIL_CURRENT)
        // authDispatchContext.dispatch({ type: AuthRedux.LOGIN_SUCCESS, payload: responseUser.data })
    }
    catch(error) {
        cbError(error)
    }
}


export async function relogin(dispatch, cbError=()=>{}) {
    try {
        // const loginData = { email: email, password: password }
        // const response = await Request.request(authDispatchContext, 'post', Endpoints.ACCOUNTS_TOKEN, loginData)
        // authDispatchContext.dispatch({ type: AuthRedux.TOKEN_SUCCESS, payload: response.data })

        const responseUser = await Request.authRequest(dispatch, 'post', Endpoints.ACCOUNTS_PROFILE_CURRENT)
        // let doc = responseUser.data.doc
        // try {
        //     const responseDoc = await Request.authRequest(dispatch, 'post', Endpoints.CUSTODIAN_DOC)
        //     doc = responseDoc.status === 200
        // }
        // catch(error) {
        //     cbError(error)
        // }
            
        dispatch({ type: AuthRedux.LOGIN_SUCCESS, payload: { ...responseUser.data }}) //, doc: doc }})
    }
    catch(error) {
        cbError(error)
    }
}


export async function logout(dispatch) {
    await Request.request(dispatch, 'post', Endpoints.ACCOUNTS_LOGOUT_OUT)
    dispatch({ type: AuthRedux.LOGOUT })
}


export async function signup(dispatch, username, email, password, twitter, facebook, cbVerify=()=>{}, cbError=()=>{}) {
    try {
        const signupData = {
            username: username,
            email: email,
            password: password,
            twitter: twitter,
            facebook: facebook
        }

        // const response = await Request.request(dispatch, 'post', Endpoints.ACCOUNTS_SIGNUP, signupData)
        await Request.request(dispatch, 'post', Endpoints.ACCOUNTS_SIGNUP, signupData)
        
        cbVerify(email)
        // login(dispatch, email, password)
    }
    catch(error) {
        cbError(error)
    }
}
