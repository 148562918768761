import { Button, Col, Row } from "reactstrap"
import { CloudURL, FrontURL } from "../tools/Cloud"
import { SiteName } from "./SiteTitle"



export const EmbedCode = (slug) => {
    const url = FrontURL(`embed/${slug}`)
    return `<iframe src="${url}" style="display:flex; border:0" seamless="" width="100%" height="135px"></iframe>`
}



const Embed = ({slug='', user=''}) => {
    const url = FrontURL(`product/${slug}`)
    const urlUser = `https://${user}.s.${process.env.REACT_APP_FRONT_DOMAIN}`


    const openNewTab = () => {
        if (user) {
            window.open(urlUser, '_blank', 'noreferrer')
        }
        else {
            window.open(url, '_blank', 'noreferrer')
        }
    }


    return (
        <div style={{height:'100%', backgroundImage:`url(${CloudURL('banner.webp')})`, backgroundSize:'cover'}}>
            <Row className="align-items-center g-0" style={{height:'60px'}}>
                <Col>
                    <img
                        alt=''
                        src={CloudURL('logo64.webp')}
                        style={{verticalAlign:'text-bottom', height:'1.4em'}}
                        className="mx-2"
                    />
                    <span style={{letterSpacing: 2, textTransform: 'uppercase', fontWeight:900}} className="ql-color-light">
                        {SiteName}
                    </span>
                </Col>
                <Col className="col-3 embed-grad">
                </Col>
                <Col className="text-end col-auto ps-5 pe-3" style={{backgroundColor:'white'}}>
                    <Button color='primary' onClick={openNewTab} className="bm-btn">Get a Fair Price</Button>
                </Col>
            </Row>
        </div>
    )
}



export default Embed