import React from "react"
import { NavItem, NavLink } from 'reactstrap'
import { Button } from 'reactstrap'

import { useDomainNavigate } from "../../tools/Subdomain"
import { featCoupons, featDashboard, featInbox } from "../../containers/store/Store"


const MenuStore = ({showTitle=false, closeOffCanvas=()=>{}}) => {
    const navigate = useDomainNavigate()

    // const onHome = () =>  { closeOffCanvas(); navigate(`dashboard`) }
    const onCoupons = () => { closeOffCanvas(); navigate(`coupons`) }
    const onDashboard = (e) => { closeOffCanvas(); navigate(`dashboard`) }
    const onInbox = () => { closeOffCanvas(); navigate(`inbox`) }
    const onProducts = () => { closeOffCanvas(); navigate(`products`) }
    const onProductNew = () => { closeOffCanvas(); navigate(`productnew`) }

    return (
        <>
        {showTitle && <h6>MyStore</h6>}
        <NavItem>
            <NavLink href='#' onClick={onProducts} className = { (window.location.pathname === "/store" || window.location.pathname === "/store/products") ? 'active' : '' }>
                Products
            </NavLink>
        </NavItem>

        {featDashboard && <NavItem>
            <NavLink href='#' onClick={onDashboard} className = { window.location.pathname === "/store/dashboard" ? 'active' : '' }>
                Dashboard
            </NavLink>
        </NavItem>}

        {featCoupons && <NavItem>
            <NavLink href='#' onClick={onCoupons} className = { window.location.pathname === "/store/coupons" ? 'active' : '' }>
                Coupons
            </NavLink>
        </NavItem>}

        {featInbox && <NavItem>
            <NavLink href='#' onClick={onInbox} className = { window.location.pathname === "/store/inbox" ? 'active' : '' }>
                Inbox
            </NavLink>
        </NavItem>}

        <NavItem className="ms-auto me-1">
            <Button color='primary' onClick={onProductNew} outline>New Product</Button>
        </NavItem>
        </>
    )
}

export default MenuStore;
