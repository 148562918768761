import React, { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router"
import { AuthDispatchContext } from '../../App'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveFunnel } from '@nivo/funnel'
import { Badge, Button, ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Row } from "reactstrap"
import { useRequest } from "../../hooks/Hooks"
import { FormattedDate, FormattedNumber, FormattedTime } from "react-intl"
import * as Endpoints from '../../Endpoints'

import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"
import { ViewAs } from "../doc/Doc"

const config = {
    xl:3,
    lg:3,
    md:2,
    sm:1,
    xs:1,

    fetchRows:3,
    maxRows:20
}

const PRINT = 'Print'
const VIEW = 'View'
const PURCHASE = 'Purchase'


// const dataF = [
//     {
//       "id": "step_sent",
//       "value": 63305,
//       "label": "Print"
//     },
//     {
//       "id": "step_view",
//       "value": 55141,
//       "label": "View"
//     },
//     {
//       "id": "step_purchased",
//       "value": 27100,
//       "label": "Purchase"
//     }
//   ]


// const data_g = (name, max) => {
//     const getRandomInt = (min, max) => {
//         return Math.floor(Math.random() * (max - min + 1)) + min
//       }

//     const data = []
//     const currentDate = new Date(); // Create a copy of the starting date

//       for (var i = 0; i < 30; i++) {
//         data.push({
//           x: new Date(currentDate), // Use the current date in each iteration
//           y: getRandomInt(0, max),
//         })

//         currentDate.setDate(currentDate.getDate() + 1); // Increment the date by 1
//       }

//     return {
//         'id': name,
//         'data': data
//     }
// }


const Events = (events, graphOptions) => {
    const d = []

    for (const event of events) {
        if (graphOptions.includes(event.id))
            d.push(event)
    }

    // if (graphOptions.includes(PRINT)) {
    //     d.push(data_g(PRINT, 100))
    // }

    // if (graphOptions.includes(VIEW)) {
    //     d.push(data_g(VIEW, 30))
    // }

    // if (graphOptions.includes(PURCHASE)) {
    //     d.push(data_g(PURCHASE, 3))
    // }

    return d
}

// const Prods = () => {
//     const p = []

//     for (var i=0 ; i<20 ; i++) {
//         p.push({
//             'id': i,
//             'name': `Some Name ${i}`
//         })
//     }

//     return p
// }


const Products = (products, productFilters, setProductFilters=()=>{}) => {
    const cols = []

    if (!productFilters) {
        return cols
    }

    const onClick = (id) => {
        const index = productFilters.indexOf(id)
        
        if (index < 0) {
            if (id<0) {
                productFilters.length = 0

                for (const prod of products) {
                    productFilters.push(prod.id)
                }
            }
            productFilters.push(id)
            
            const allIndex = productFilters.indexOf(-1)
            if (id>=0 && allIndex>=0) {
                productFilters.splice(allIndex, 1)
            }
        } else {
            productFilters.splice(index, 1)
            if (id<0) {
                productFilters.length = 0
            }

            const allIndex = productFilters.indexOf(-1)
            if (id>=0 && allIndex>=0) {
                productFilters.splice(allIndex, 1)
            }
        }

        setProductFilters([...productFilters])
    }

    const prods = [
        {
            'id': -1,
            'name': `All`
        },
        ...products
    ]

    for (const prod of prods) {
        const id = prod.id
        const col = <Col key={id}>
            <FormGroup switch className="m-3">
                <Input
                    type="switch"
                    role="switch"
                    checked={productFilters.includes(id)}
                    onClick={()=>{onClick(id)}}
                />
                <Label check>                    
                    {id<0 ? 'All' : prod.name}
                </Label>
            </FormGroup>
        </Col>

        cols.push(col)
    }

    return cols
}



const OrderRows = (orders, minWidth) => {
    const rows = []

    for (var o of orders) {
        const row = <Row style={{ minWidth: minWidth }}> {/* className="small"> */}
            <Col className="square border-end border-top" style={{ minWidth: 282}}>
                <FormattedDate
                    value={o.Date}
                    day="numeric"
                    month="long"
                    year="numeric"
                />
                {/* <br/> */}
                ,&nbsp;
                <FormattedTime
                    value={o.Date}
                />
            </Col>
            <Col className="square border-end border-top product-title" style={{ minWidth: 250}}>
                {o.Product}
            </Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Amount/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Market/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Stripe/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.BlenderFund/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.GodotFund/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.OnePercent/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.AffiliateFee/100.0}
                style={`currency`}
                currency="USD"
            /></Col>
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Taxes/100.0}
                style={`currency`}
                currency="USD"
                /></Col> */}
            <Col className="text-end square border-end border-top">
                <span style={o.Refunded ? {textDecoration:'line-through'} : {fontWeight:'bold'}}>
                    <FormattedNumber
                        value={o.Earnings/100.0}
                        style={`currency`}
                        currency="USD"
                    />
                </span>
            </Col>
            <Col className="text-end square border-end border-top">{o.Refunded && <i>Refunded</i>}</Col>
            <Col className="text-end square border-top">
                {!o.Refundable && !o.Refunded && o.Paid && <Badge color="success">Paid</Badge>}
                {!o.Refundable && !o.Refunded && !o.Paid && <Badge color="warning">Unpaid</Badge>}
                {o.Refundable && !o.Paid && <Badge color="info">On Hold</Badge>}
            </Col>
        </Row>

        rows.push(row)
    }

    return rows
}



const AffiliateRows = (orders, minWidth) => {
    const rows = []

    for (var o of orders) {
        const row = <Row style={{ minWidth: minWidth }}> {/* className="small"> */}
            <Col className="square border-end border-top" style={{ minWidth: 282}}>
                <FormattedDate
                    value={o.Date}
                    day="numeric"
                    month="long"
                    year="numeric"
                />
                {/* <br/> */}
                ,&nbsp;
                <FormattedTime
                    value={o.Date}
                />
            </Col>
            <Col className="square border-end border-top product-title" style={{ minWidth: 250}}>
                {o.Product}
            </Col>
            <Col className="text-end square border-end border-top">
                <span style={o.Refunded ? {textDecoration:'line-through'} : {fontWeight:'bold'}}>
                    <FormattedNumber
                        value={o.AffiliateFee/100.0}
                        style={`currency`}
                        currency="USD"
                    />
                </span>
            </Col>
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Market/100.0}
                style={`currency`}
                currency="USD"
            /></Col> */}
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Stripe/100.0}
                style={`currency`}
                currency="USD"
            /></Col> */}
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.BlenderFund/100.0}
                style={`currency`}
                currency="USD"
            /></Col> */}
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.GodotFund/100.0}
                style={`currency`}
                currency="USD"
            /></Col> */}
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.OnePercent/100.0}
                style={`currency`}
                currency="USD"
            /></Col> */}
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.AffiliateFee/100.0}
                style={`currency`}
                currency="USD"
            /></Col> */}
            {/* <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Taxes/100.0}
                style={`currency`}
                currency="USD"
                /></Col> */}
            {/* <Col className="text-end square border-end border-top">
                <span style={o.Refunded ? {textDecoration:'line-through'} : {fontWeight:'bold'}}>
                    <FormattedNumber
                        value={o.Earnings/100.0}
                        style={`currency`}
                        currency="USD"
                    />
                </span>
            </Col> */}
            <Col className="text-end square border-end border-top">{o.Refunded && <i>Refunded</i>}</Col>
            <Col className="text-end square border-top">
                {!o.Refundable && !o.Refunded && o.Paid && <Badge color="success">Paid</Badge>}
                {!o.Refundable && !o.Refunded && !o.Paid && <Badge color="warning">Unpaid</Badge>}
                {o.Refundable && !o.Paid && <Badge color="info">On Hold</Badge>}
            </Col>
        </Row>

        rows.push(row)
    }

    return rows
}



const PayoutsRows = (payouts, minWidth) => {
    const rows = []

    for (var o of payouts) {
        const row = <Row style={{ minWidth: minWidth }}> {/* className="small"> */}
            <Col className="square border-end border-top" style={{ minWidth: 230}}>
                <FormattedDate
                    value={o.Date}
                    day="numeric"
                    month="long"
                    year="numeric"
                />
                {/* <br/> */}
                ,&nbsp;
                <FormattedTime
                    value={o.Date}
                />
            </Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Amount/100.0}
                style={`currency`}
                currency="USD"
                /></Col>
            <Col className="text-end square border-end border-top"><FormattedNumber
                value={o.Stripe/100.0}
                style={`currency`}
                currency="USD"
                /></Col>
            <Col className="text-end square border-top"><b><FormattedNumber
                value={o.Earnings/100.0}
                style={`currency`}
                currency="USD"
                /></b></Col>
        </Row>

        rows.push(row)
    }

    return rows
}



export const Graph = ({data}) => {
    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 10, right: 22, bottom: 30, left: 40 }}
            colors={{ scheme: 'spectral' }}
            // colors={['#ff0000', '#00ff00', '#0000ff']}
            curve="monotoneX"
            axisBottom={{
                format: '%b %d',
                // legend: 'time scale',
                legendOffset: 30,
                tickValues: 'every 5 days'
            }}
            xFormat="time:%Y-%m-%d"
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            yScale={{
                type: 'linear',
                min: '0',
                max: 'auto',
                stacked: false,
                reverse: false
            }}
        yFormat=" >-.2f"
            useMesh={true}
        />
    )
}



const Funnel = ({data}) => {
    if (!data.length) {
        return <></>
    }

    return (
        <ResponsiveFunnel
            data={data}
            direction="horizontal"
            shapeBlending={0.33}
            valueFormat=" >-.4s"
            colors={{ scheme: 'spectral' }}
            borderWidth={20}
            labelColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        3
                    ]
                ]
            }}
            beforeSeparatorLength={100}
            beforeSeparatorOffset={20}
            afterSeparatorLength={100}
            afterSeparatorOffset={20}
            currentBorderWidth={40}
        />
    )
}

const Dashboard = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const [graphOptions, setGraphOptions] = useState([PRINT, VIEW, PURCHASE])
    const [productFilters, setProductFilters] = useState([])
    const [productFiltersInit, setProductFiltersInit] = useState(false)

    const [sales, setSales] = useState(0)
    const [salesTT, setSalesTT] = useState(0)
    const [amount, setAmount] = useState(0)
    const [amountOnHold, setAmountOnHold] = useState(0)
    const [amountTT, setAmountTT] = useState(0)
    const [fees, setFees] = useState(0)

    const [events, setEvents] = useState([])
    const [prods, setProds] = useState([])
    const [funnel, setFunnel] = useState([])
    const [payouts, setPayouts] = useState([])
    const [orders, setOrders] = useState([])
    const [aorders, setAOrders] = useState([])

    const [payoutInfo, setPayoutInfo] = useState("")
    

    const [convPV, setConvPV] = useState(0)
    const [convVP, setConvVP] = useState(0)
    const [convPP, setConvPP] = useState(0)
    
    const dataRequest = {
        ids: productFilters,
    }
    const { responseData, isLoading, reFetch } = useRequest(authDispatchContext.dispatch, Endpoints.DASHBOARD_ANALYTICS, { auth:true, method:'post', data:dataRequest })

    useEffect(() => {
        if (responseData.sales !== undefined) {
            setFees(responseData.fees)
            setProds(responseData.products)
            setSales(responseData.sales)
            setSalesTT(responseData.sales_tt)
            setAmount(responseData.amount)
            setAmountOnHold(responseData.amount_onhold)
            setAmountTT(responseData.amount_tt)
            setEvents(responseData.events)
            setFunnel(responseData.funnel)
            setPayouts(responseData.payouts)
            setOrders(responseData.orders)
            setAOrders(responseData.aorders)
            setPayoutInfo(responseData.payoutInfo)

            if (responseData.funnel[0].value) {
                setConvPV(responseData.funnel[1].value / responseData.funnel[0].value)
                setConvPP(responseData.funnel[2].value / responseData.funnel[0].value)
            }
            if (responseData.funnel[1].value) {
                setConvVP(responseData.funnel[2].value / responseData.funnel[1].value)
            }

            if (!productFiltersInit) {
                setProductFiltersInit(true)
                for (const prod of responseData.products) {
                    productFilters.push(prod.id)
                }
                productFilters.push(-1)
                setProductFilters([...productFilters])
            }
        }
    // eslint-disable-next-line
    }, [responseData])


    useEffect(() => {
        if (!isLoading) {
            reFetch()
        }
    // eslint-disable-next-line
    }, [productFilters])

    
    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }


    const onGraphOptions = (option) => {
        const index = graphOptions.indexOf(option)

        if (index < 0) {
            graphOptions.push(option)
        } else {
            graphOptions.splice(index, 1)
        }

        setGraphOptions([...graphOptions])
    }


    // if (isLoading || hasError) {
    //     return <></>
    // }

    return (<>
        <SEO title={`Dashboard - ${SiteName}`} />
    
        <Container className="mb-5">
            <div className='d-md-none d-block mb-3'>
                <h1>Dashboard</h1>
            </div>

            <ViewAs/>

            {/* <Row
                xl={config.xl}
                lg={config.lg}
                md={config.md}
                sm={config.sm}
                xs={config.xs}
            >
                <Col> */}
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Products&nbsp;-&nbsp;
                            <FormattedNumber                    
                                value={fees}
                                style={`percent`}
                                maximumFractionDigits='2'
                            />
                            &nbsp;Fees
                        </CardHeader>
                        <CardBody style={{ maxHeight: "160px", overflow:"scroll" }}>
                            <Row
                                xl={5}
                                lg={4}
                                md={3}
                                sm={2}
                                xs={1}
                            >
                                {Products(prods, productFilters, setProductFilters)}
                            </Row>
                        </CardBody>
                    </Card>
                {/* </Col>
            </Row> */}

            <Row
                xl={config.xl}
                lg={config.lg}
                md={config.md}
                sm={config.sm}
                xs={config.xs}
            >
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Sales
                        </CardHeader>
                        <CardBody tag="h3" className="text-end text-notransform">
                            {sales} of {salesTT}
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Total
                        </CardHeader>
                        <CardBody tag="h3" className="text-end text-notransform">
                            <h5>Unpaid Earnings</h5>
                            <FormattedNumber                    
                                value={(amount + amountOnHold)/100.0}
                                style={`currency`}
                                currency={'USD'}
                                maximumFractionDigits='2'
                            />

                            <h6>
                                Including&nbsp;
                                <b>
                                <FormattedNumber                    
                                    value={amountOnHold/100.0}
                                    style={`currency`}
                                    currency={'USD'}
                                    maximumFractionDigits='2'
                                /></b>
                                &nbsp;on hold
                            </h6>


                            {!payoutInfo &&
                                <h6 className="mt-5">Select all Products to get Payout Info</h6>
                            }
                            {payoutInfo && <>
                                <h5 className="mt-5">Available to Payout</h5>
                                <FormattedNumber                    
                                    value={(amount)/100.0}
                                    style={`currency`}
                                    currency={'USD'}
                                    maximumFractionDigits='2'
                                />
                                <h6>{payoutInfo}</h6>
                            </>}

                            <h5 className="mt-5">Total Revenue</h5>
                            <FormattedNumber                    
                                value={amountTT/100.0}
                                style={`currency`}
                                currency={'USD'}
                                maximumFractionDigits='2'
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Conversion Funnel&nbsp;-&nbsp;
                            <FormattedNumber                    
                                value={convPP}
                                style={`percent`}
                                maximumFractionDigits='2'
                            />
                        </CardHeader>
                        <CardTitle>
                            Print to View:&nbsp;
                            <FormattedNumber                    
                                value={convPV}
                                style={`percent`}
                                maximumFractionDigits='2'
                            />
                            <br/>
                            View to Purchase:&nbsp;
                            <FormattedNumber                    
                                value={convVP}
                                style={`percent`}
                                maximumFractionDigits='2'
                            />
                        </CardTitle>
                        <CardBody style={{ height: "200px" }}>
                            <Funnel data={funnel}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row
                // xl={config.xl}
                // lg={config.lg}
                // md={config.md}
                // sm={config.sm}
                // xs={config.xs}
            >
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => onGraphOptions(PRINT)}
                                    active={graphOptions.includes(PRINT)}
                                >
                                    Print
                                </Button>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => onGraphOptions(VIEW)}
                                    active={graphOptions.includes(VIEW)}
                                >
                                    View
                                </Button>
                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => onGraphOptions(PURCHASE)}
                                    active={graphOptions.includes(PURCHASE)}
                                >
                                    Purchase
                                </Button>
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody style={{ height: "200px" }}>
                            <Graph data={Events(events, graphOptions)}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Payouts
                        </CardHeader>
                        <CardBody style={{ maxHeight: "300px", overflow:"scroll" }} className="pt-0">
                            <Row style={{ minWidth: 600, position:"sticky", top:0, fontWeight:"bold", backgroundColor: '#f8f9fa', zIndex: 1}}>
                                <Col style={{ minWidth: 230}} className="text-start square border-end border-bottom">Date</Col>
                                <Col className="text-end square border-end border-bottom">Amount</Col>
                                <Col className="text-end square border-end border-bottom">Stripe</Col>
                                <Col className="text-end border-bottom">Earnings</Col>
                            </Row>
                            {PayoutsRows(payouts, 600)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Sales
                        </CardHeader>
                        <CardBody style={{ maxHeight: "300px", overflow:"scroll" }} className="pt-0">
                            <Row style={{ minWidth: 1900, position:"sticky", top:0, fontWeight:"bold", backgroundColor: '#f8f9fa', zIndex: 1}}>
                                <Col style={{ minWidth: 282}} className="text-start square border-end border-bottom">Date</Col>
                                <Col style={{ minWidth: 250}} className="text-start square border-end border-bottom">Product</Col>
                                <Col className="text-end square border-end border-bottom">Amount</Col>
                                <Col className="text-end square border-end border-bottom">Market</Col>
                                <Col className="text-end square border-end border-bottom">Stripe</Col>
                                <Col className="text-end square border-end border-bottom">BlenderFund</Col>
                                <Col className="text-end square border-end border-bottom">GodotFund</Col>
                                <Col className="text-end square border-end border-bottom">OnePercent</Col>
                                <Col className="text-end square border-end border-bottom">Affiliate</Col>
                                {/* <Col>Taxes</Col> */}
                                <Col className="text-end square border-end border-bottom">Earnings</Col>
                                <Col className="text-end square border-end border-bottom">Refunded</Col>
                                <Col className="text-end border-bottom">Status</Col>
                            </Row>
                            {OrderRows(orders, 1900)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card className='m-2' color="light">
                        <CardHeader tag="h5">
                            Affiliate Sales
                        </CardHeader>
                        <CardBody style={{ maxHeight: "300px", overflow:"scroll" }} className="pt-0">
                            <Row style={{ minWidth: 900, position:"sticky", top:0, fontWeight:"bold", backgroundColor: '#f8f9fa', zIndex: 1}}>
                                <Col style={{ minWidth: 282}} className="text-start square border-end border-bottom">Date</Col>
                                <Col style={{ minWidth: 250}} className="text-start square border-end border-bottom">Product</Col>
                                {/* <Col className="text-end square border-end border-bottom">Amount</Col> */}
                                {/* <Col className="text-end square border-end border-bottom">Market</Col> */}
                                {/* <Col className="text-end square border-end border-bottom">Stripe</Col> */}
                                {/* <Col className="text-end square border-end border-bottom">BlenderFund</Col> */}
                                {/* <Col className="text-end square border-end border-bottom">GodotFund</Col> */}
                                {/* <Col className="text-end square border-end border-bottom">OnePercent</Col> */}
                                {/* <Col className="text-end square border-end border-bottom">Affiliate</Col> */}
                                {/* <Col>Taxes</Col> */}
                                <Col className="text-end square border-end border-bottom">Earnings</Col>
                                <Col className="text-end square border-end border-bottom">Refunded</Col>
                                <Col className="text-end border-bottom">Status</Col>
                            </Row>
                            {AffiliateRows(aorders, 900)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

        {/* // <div>
        //     <Line
        // data={data}
        // margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        // xScale={{ type: 'point' }}
        // yScale={{
        //     type: 'linear',
        //     min: 0,
        //     max: 'auto',
        //     stacked: true,
        //     reverse: false
        // }}
        // yFormat=" >-.2f"
        // curve="cardinal"
        // axisTop={null}
        // axisRight={null}
        // axisBottom={{
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'transportation',
        //     legendOffset: 36,
        //     legendPosition: 'middle'
        // }}
        // axisLeft={{
        //     tickSize: 5,
        //     tickPadding: 5,
        //     tickRotation: 0,
        //     legend: 'count',
        //     legendOffset: -40,
        //     legendPosition: 'middle'
        // }}
        // pointSize={10}
        // pointColor={{ theme: 'background' }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: 'serieColor' }}
        // pointLabelYOffset={-12}
        // useMesh={true}
        // legends={[
        //     {
        //         anchor: 'bottom-right',
        //         direction: 'column',
        //         justify: false,
        //         translateX: 100,
        //         translateY: 0,
        //         itemsSpacing: 0,
        //         itemDirection: 'left-to-right',
        //         itemWidth: 80,
        //         itemHeight: 20,
        //         itemOpacity: 0.75,
        //         symbolSize: 12,
        //         symbolShape: 'circle',
        //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemBackground: 'rgba(0, 0, 0, .03)',
        //                     itemOpacity: 1
        //                 }
        //             }
        //         ]
        //     }
        // ]}/>
        // </div> */}
    </>)
}

export default Dashboard