import { useEffect, useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"



let timer = undefined



const Notification = ({content={message:undefined}, forceCloseNotif=false, setForceCloseNotif=() => {}}) => {
    const [isOpen, setisOpen] = useState(false)


    function onToggle() {
        setisOpen(!isOpen)

        if (isOpen) {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }


    useEffect(() => {
        if (!content) {
            return
        }

        if (!content.title && !content.message) {
            return
        }

        setisOpen(true)

        if (timer) {
            clearTimeout(timer)
        }

        if (!content.notimer) {
            timer = setTimeout(() => {
                setisOpen(false)
            }, 3000)
        }

    }, [content])


    useEffect(() => {
        if (forceCloseNotif) {
            if (isOpen) {
                onToggle()
                setForceCloseNotif(false)
            }
        }
    // eslint-disable-next-line
    }, [forceCloseNotif])


    function getModalBody() {
        if (content.title && content.message) {
            return (
            <>
                <ModalHeader toggle={onToggle}>
                    {content.title}
                </ModalHeader>
                <ModalBody>
                    {content.message}
                </ModalBody>
            </>)
        }

        if (content.title) {
            return (
                <ModalHeader toggle={onToggle}>
                    {content.title}
                </ModalHeader>
            )
        }
        if (content.message) {
            return (
                <ModalHeader toggle={onToggle}>
                    {content.message}
                </ModalHeader>
            )
        }
    }


    return (
        <Modal
            backdrop={true}
            // fullscreen=""
            size=""
            toggle={onToggle}
            isOpen={isOpen}
        >
            {getModalBody()}
            {/* <ModalHeader toggle={onToggle}>
                {content.message}
            </ModalHeader> */}
            {/* <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                {/* <Button
                    color="primary"
                    onClick={function noRefCheck(){}}
                >
                    Do Something
                </Button>
                {' '}
                <Button onClick={function noRefCheck(){}}>
                    Cancel
                </Button> */}
            {/*</ModalFooter> */}
        </Modal>
    )
}

export default Notification