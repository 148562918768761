import React, { useRef, useState } from 'react';
import { Upload, resizeFile } from '../tools/Cloud';
import { Button } from 'reactstrap';



const DragDropUploader = ({ text, productID, onDnD=() => {}, isPublicImg=false, generateThumbnail=false, thumbSize={width:500, height:282} }) => {
    const [dragging, setDragging] = useState(false)
    const fileInputRef = useRef(null);


    const UploadFile = async(file) => {
        if (isPublicImg) {
            const fileImg = await resizeFile(file, 1920, 1080)
            Upload(fileImg, fileImg.name, productID, onDnD, isPublicImg)

            if (generateThumbnail) {
                const fileThumbImg = await resizeFile(file, thumbSize.width, thumbSize.height, 'JPEG')
                Upload(fileThumbImg, fileThumbImg.name, productID, onDnD, isPublicImg, true)
            }
        }
        else {
            Upload(file, file.name, productID, onDnD)
        }
    }

    const handleFileInputChange = () => {
      const file = fileInputRef.current.files[0]
      UploadFile(file)
    };
  

    const handleDragEnter = (e) => {
        e.preventDefault()
        setDragging(true)
    }


    const handleDragLeave = (e) => {
        e.preventDefault()
        setDragging(false)
    }


    const handleDrop = async(e) => {
        e.preventDefault()
        setDragging(false)

        const files = Array.from(e.dataTransfer.files)
        let file = files[0];

        UploadFile(file)
    }


    return (
        <div
            // className={`dropzone ${dragging ? 'dragging' : ''}`}
            style={{
                width: "100%",
                height: "100px",
                border: "2px dashed #ccc",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "24px",
                // margin: "auto",
                
                backgroundColor: dragging ? '#f2f2f2' : ''
            }}

            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragEnter}
            onDrop={handleDrop}
        >
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
            />

            {text} or
            <Button color='primary' outline className='ms-2' size="sm" type='button'
                onClick={() => fileInputRef.current.click()}
            >
                Browse
            </Button>
        </div>
    )
}

export default DragDropUploader;
