import React, { useContext, useState } from "react"
import { Navigate } from "react-router"
import { Link } from "react-router-dom"
import { Alert, Button, Container, Form, Input, Label } from "reactstrap"

import { AuthDispatchContext } from '../../App'
import * as Request from "../../actions/Request"
import * as StringTools from "../../tools/StringTools"
import { domains, useDomainNavigate } from "../../tools/Subdomain"

import * as Endpoints from '../../Endpoints'
import { SEO } from "../../components/SEO"
import { SiteName } from "../../components/SiteTitle"



const ProductNew = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const [hasError, setHasError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [formValue, setformValue] = useState({
        name: '',
        // description: '',
        // slug: '',
        // summary: '',
        // price: ''
    })

    if (!authDispatchContext.state.isAuthenticated) {
        return <Navigate to='/' />
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        let store = ''
        if (domains.includes(authDispatchContext.domain)) {
            store = authDispatchContext.domain
        }

        try {
            const slug = StringTools.slug(formValue.name)
            const params = {
                name: formValue.name,
                description: formValue.name,
                slug: slug,
                summary: formValue.name,
                price: 0,
                user: authDispatchContext.state.user.id,
                store: store
            }
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.PRODUCTDETAIL, params)
            
            navigate(`/store/productedit/${slug}`)
        }
        catch (error) {
            if (error.response.status === 400 && error.response.data.stripeMissing) {
                setErrorMsg(<>
                    Your account has to be linked to a <b>Fully Onboarded Stripe Express Account</b> first.<br/>
                    You can link one or continue onboarding in your <Link to='/profile'>Profile Page</Link>.
                </>)
                setHasError(true)
            }
            setErrorMsg(<span>{error.response.data.name[0].charAt(0).toUpperCase() + error.response.data.name[0].slice(1)}</span>)
            setHasError(true)
        // throw error
        }
    }

    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        })
    }

    return (<>
        <SEO title={`New Product - ${SiteName}`} />

        <Container>
            <h1>Create a new Product</h1>

            <Form onSubmit={handleSubmit}>
                <div className='form-group'>
                    <Label>Product name</Label>
                    <Input
                        className='form-control'
                        type="text"
                        name="name"
                        placeholder="product name"
                        value={formValue.name}
                        onChange={handleChange}
                        required
                    />
                    <Button color="primary" type="submit">
                        Create
                    </Button>
                </div>
            </Form>

            {hasError && <Alert color="danger">
                {errorMsg}
            </Alert>
            }
        </Container>
    </>)
}

export default ProductNew