import React, { useContext, useState, useEffect, createContext } from "react"

import { AuthDispatchContext } from "../../App"
import { useRequest } from "../../hooks/Hooks"

import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Loading from "../../components/Loading"
import { FormattedNumber } from "react-intl"
import { FrontURL } from "../../tools/Cloud"
import { Alert, Button, Container, FormGroup, Input, Label } from "reactstrap"
import * as Request from "../../actions/Request"
import { useDomainNavigate } from "../../tools/Subdomain"

import * as Endpoints from '../../Endpoints'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)
export const StripeContext = createContext(null)



export const StripeClientSecret = ({children}) => {
    const clientSecret = useState(new URLSearchParams(window.location.search).get('payment_intent_client_secret'))

    const options = {
        clientSecret: clientSecret,
    }

    return (
        <Elements stripe={stripePromise} options={options} key={clientSecret}>
            {children}
        </Elements>
    )
}



const StripeSetupForm = ({amount, currency, curmul}) => {
    const stripe = useStripe()
    const elements = useElements()
    // const authDispatchContext = useContext(AuthDispatchContext)

    const [errorMessage, setErrorMessage] = useState(null)
    // const [email, setEmail] = useState('')
    // const [emailexists, setEmailexists] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCardElementReady, setIsCardElementReady] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()

        // setEmailexists(false)

        if (!stripe || !elements)
            return
            
        // if (!authDispatchContext.state.isAuthenticated) {
        //     const response = await Request.request(authDispatchContext.dispatch, 'post', Endpoints.CHECKS_EMAILEXISTS, {email:email})
        //     if (response.data.exists) {
        //         // An account is already registered with this email, please sign in or enter another one.
        //         setEmailexists(true)
        //         return
        //     }
        // }

        setIsLoading(true)

        const {error} = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: FrontURL('checkoutpay'),
                // receipt_email: email
            },
        })

        if (error) {
            setErrorMessage(error.message)
        } else {
        }

        setIsLoading(false)
    }

    // useEffect(() => {
    //     if (authDispatchContext.state && authDispatchContext.state.user)
    //         setEmail(authDispatchContext.state.user.email)
    // }, [authDispatchContext, elements])


    if (!stripe || !elements) {
        return (
            <Container className='mt-5'>
                <Loading />
            </Container>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            {/* <Input
                className='form-control'
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={authDispatchContext.state.isAuthenticated}
            />

            {emailexists && <Alert color='danger'>
                An account is already registered with this email, please sign in or enter another one.
            </Alert>} */}
            
            <PaymentElement onReady={() => setIsCardElementReady(true)} />

            <Label check className="stripe-info" hidden={!isCardElementReady}>
            Please note that as soon as the transaction is successfully processed, your card information will be promptly and securely removed from our systems, ensuring that it will not be stored or retained in any form.
            </Label>

            <FormGroup check style={{margin:'1em'}} hidden={!isCardElementReady}>
                <Input id='agreement' type="checkbox" required="required"/>
                <Label htmlFor='agreement' check className="stripe-info">
                    By downloading this digital content, I waive my right to cancel and acknowledge that no refunds or exchanges will be given. I confirm that I have reviewed and agree to the terms of this agreement.<br/>
                    I understand that Stripe processing fees associated with my payments are non-refundable.
                </Label>
            </FormGroup>
            
            <Button color='primary' block disabled={isLoading || !stripe || !elements || !isCardElementReady} hidden={!isCardElementReady}>
                {isLoading ? 
                    <Loading /> : 
                    <span>
                        Pay &nbsp;
                        <FormattedNumber                    
                            value={amount / curmul}
                            style={`currency`}
                            currency={currency}
                            maximumFractionDigits='0'
                        />
                    </span>
                }
            </Button>

            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </form>
    )
}



export const StripeCheckoutPay = ({children}) => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const stripe = useStripe()
    const [message, setMessage] = useState(null)
    const [color, setColor] = useState(null)

    const { responseData, isLoading, hasError, reFetch } = useRequest(authDispatchContext.dispatch, Endpoints.STRIPE_PAYMENTINTENTS, { method:'post', auth:true, firstCall:false })

    const [paymentDone, setPaymentDone] = useState(false)
    const [returnToCheckout, setReturnToCheckout] = useState(false)


    useEffect(() => {
        if (!stripe) {
            return
        }

        const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')
        // const customer = new URLSearchParams(window.location.search).get('customer')

        stripe
            .retrieveSetupIntent(clientSecret)
            .then(({setupIntent}) => {
                switch (setupIntent.status) {
                    case 'succeeded':
                        setMessage('Success! Your payment method has been saved.')
                        setColor('success')
                        reFetch(Endpoints.STRIPE_PAYMENTINTENTS, {paymentmethod:setupIntent.payment_method})
                        break

                    case 'processing':
                        setMessage("Processing payment details. We'll update you when processing is complete.")
                        setColor('primary')
                        break

                    case 'requires_payment_method':
                        setMessage('Failed to process payment details. Please try another payment method.')
                        setColor('danger')
                        break

                    default:
                        setMessage('Unexpected status.')
                        setColor('danger')
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe])


    useEffect(() => {

        const save_order = async(intentId) => {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_SAVEORDER, {intentid:intentId})
        }

        const confirmIntents = async(intents) => {
            for (const intent of intents) {                
                await stripe
                    .confirmCardPayment(intent.client_secret, {payment_method: intent.last_payment_error.payment_method.id})
                    .then(function(result) {
                        if (result.error) {
                            setMessage(result.error.message)
                            setColor('danger')
                        } else {
                            if (result.paymentIntent.status === 'succeeded') {
                                save_order(intent.id)
                            }
                        }
                    })
                }

            await detach(intents[0].last_payment_error.payment_method.id)

            setMessage('Purchase complete.')
            setColor('success')
            setPaymentDone(true)
        }

        const detach = async(paymentMethod) => {
            await Request.authRequest(authDispatchContext.dispatch, 'post', Endpoints.STRIPE_DETACH, {paymentmethod:paymentMethod})
        }

        if (!responseData.status)
            return

        if (responseData.status === 'success') {
            setMessage('Purchase complete.')
            setColor('success')
            setPaymentDone(true)
        }
        else if (responseData.status === 'intents') {
            setMessage('Purchase requires additional authentication for each digital good.')
            setColor('primary')

            confirmIntents(responseData.intents)        
        }
        else if (responseData.status === 'countryerror') {
            setMessage(<>
                <b>Error during the purchase</b><br/>
                The country of the card used (<b>{responseData.cardcountry}</b>) does not match the country of purchase (<b>{responseData.currentcountry}</b>).<br/>
                <br/>
                This could be caused by the usage of special financing services, such as <i>Payoneer</i>, in which case you need to select the financing service card's country, <b>{responseData.cardcountry}</b> in the current case.
            </>)
            setColor('danger')
            setReturnToCheckout(true)
        }
        else if (responseData.status === 'unsupported') {
            setMessage(<>
                <b>Error during the purchase</b><br/>
                The purchase method cannot be used to verify the country of purchase.
            </>)
            setColor('danger')
            setReturnToCheckout(true)
        }
        else if (responseData.status === 'card_declined') {
            setMessage(<>
                <b>Card declined</b><br/>
                {responseData.err_msg}<br/>
                Some digital goods might not have been purchased.
            </>)
            setColor('danger')
            setPaymentDone(true)
        }
        else {
            setMessage(<>
                <b>Error during the purchase</b><br/>
                Some digital goods might not have been purchased.
                {responseData.err_msg && <><br/>{responseData.err_msg}</>}
            </>)
            setColor('danger')
            setPaymentDone(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])


    useEffect(() => {
        if (hasError) {
            setMessage('Error during the purchase. Some digital goods might not have been purchased.')
            setColor('danger')
            setReturnToCheckout(true)
        }
    }, [hasError])

    // if (hasError) {
    //     return (
    //         <Container className='mt-5'>
    //             Error!
    //         </Container>
    //     )
    // }

    if (isLoading || !responseData) {
        return (
            <Container className='mt-5'>
                <div className="text-center">
                    Processing payment... Please wait.
                </div>
                <Loading />
            </Container>
        )
    }

    return (
        <div>
            <Alert color={color}>
                {message}
            </Alert>

            {returnToCheckout &&
            <Button
                color="primary"
                onClick={() => navigate('/checkout')}
            >
                Return to Checkout
            </Button>
            }

            {paymentDone && children}
        </div>
    )
}



export const StripePay = ({children}) => {
    return (
        <Elements stripe={stripePromise}>
            <StripeCheckoutPay>
                {children}
            </StripeCheckoutPay>
        </Elements>
    )
}



export const StripeSetup = () => {
    const authDispatchContext = useContext(AuthDispatchContext)
    const navigate = useDomainNavigate()

    const { responseData, isLoading, hasError } = useRequest(authDispatchContext.dispatch, Endpoints.STRIPE_SETUPINTENT, { method:'post', auth:true })

    if (hasError) {
        return (
            <Container className='mt-5'>
                Error!
            </Container>
        )
    }

    if (isLoading || !responseData) {
        return (
            <Container className='mt-5'>
                <Loading />
            </Container>
        )
    }

    if (responseData.amount === 0) {
        navigate('/cellar')
    }

    if (!responseData.cookieok) {
        return (
            <Alert color="danger">In order to complete your purchase, please temporarily enable third-party cookies in your browser settings. As it is required to authenticate back during the payment process.</Alert>
        )
    }
        
    const options = {
        clientSecret: responseData.client_secret,
    }

    return (
        <Elements stripe={stripePromise} options={options} key={responseData.client_secret}>
            <StripeSetupForm amount={responseData.amount} currency={responseData.currency} curmul={responseData.curmul}/>
        </Elements>
    )
}


// const StripeElement = ({children, fetch=''}) => {
//     const authDispatchContext = useContext(AuthDispatchContext)
//     const [clientSecret, setClientSecret] = useState(new URLSearchParams(window.location.search).get('payment_intent_client_secret'))

//     const { responseData: responseDataSetup, isLoading: isLoadingSetup, hasError: hasErrorSetup, reFetch: reFetchSetup } = useRequest(authDispatchContext.dispatch, Endpoints.SETUPINTENT, { method:'post', auth:true, firstCall:false })
//     const dataFetchedRef = useRef(false)

//     useEffect(() => {
//         if (clientSecret)
//             return

//         if (dataFetchedRef.current)
//             return
//         dataFetchedRef.current = true

//         // if (fetch === 'intent')
//         //     reFetchPayment()
//         // else if (fetch === 'setup')
//             reFetchSetup()
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])

//     // useEffect(() => {
//     //     if (responseDataPayment.intent && responseDataPayment.intent.client_secret)
//     //         setClientSecret(responseDataPayment.intent.client_secret)
//     // // eslint-disable-next-line react-hooks/exhaustive-deps
//     // }, [responseDataPayment])

//     useEffect(() => {
//         if (responseDataSetup.client_secret)
//             setClientSecret(responseDataSetup.client_secret)
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [responseDataSetup])

//     // if (hasErrorPayment) {
//     //     return (
//     //         <Container className='mt-5'>
//     //             Error!
//     //         </Container>
//     //     )
//     // }

//     // if (!clientSecret && isLoadingPayment) {
//     //     return (
//     //         <Container className='mt-5'>
//     //             <Loading />
//     //         </Container>
//     //     )
//     // }

//     const options = {
//         // passing the client secret obtained from the server
//         clientSecret: clientSecret,
//         // appearance: {
//         //     // theme: 'night',
//         //     // labels: 'floating'
//         // }
//     }

//     console.log(clientSecret)

//     return (
//         <Elements stripe={stripePromise} options={options} key={clientSecret}>
//             {/* <StripeContext.Provider value={{paymentIntent:responseDataPayment.intent, curmul:responseDataPayment.curmul}} >
//                 {children}
//             </StripeContext.Provider> */}
//         </Elements>
//     )
// }

// export default StripeElement