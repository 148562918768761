import { Container, List } from "reactstrap"
import { SiteName } from "../../components/SiteTitle"
import { CloudURL } from "../../tools/Cloud"
import { SEO } from "../../components/SEO"



const CustomerPolicy = () => {
    return (<>
        <SEO title={`Customer Policy - ${SiteName}`} />

        <Container style={{ maxWidth:960 }}>
            {/* <h1>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                {SiteName}
            </h1> */}
            <h2>
                <img
                    src={CloudURL('logo64.webp')}
                    style={{verticalAlign:'bottom', height:'1.2em'}}
                    className="mx-2"
                    alt=''
                />
                Customer Policy
            </h2>

            <p>Welcome to {SiteName}! This Customer Policy outlines the guidelines and expectations for customers using our online marketplace to purchase digital products. By using our website or any of its features, you agree to comply with the terms outlined in this Customer Policy. If you do not agree with any part of this policy, please refrain from using our services.</p>

            <h5>1. Eligibility</h5>
            <List>
                <li>To make purchases on {SiteName}, you must be at least 18 years old or the age of majority in your jurisdiction.</li>
                <li>By making a purchase, you confirm that you have the legal capacity to enter into contracts and abide by the terms set by sellers.</li>
            </List>

            <h5>2. Digital Products Purchase</h5>
            <List>
                <li>When you purchase a digital product on {SiteName}, you are granted a limited, non-exclusive, non-transferable license to use the product for personal or commercial purposes, subject to the terms set by the seller.</li>
                <li>It is your responsibility to review the product details, including the description, license terms, and compatibility, before making a purchase.</li>
            </List>

            <h5>3. Payment and Transaction Processing</h5>
            <List>
                <li>All purchases are processed securely through trusted third-party payment gateways.</li>
                <li>You agree to pay the total amount specified for the digital product, including any applicable taxes and fees.</li>
            </List>

            <h5>4. Refunds and Cancellations</h5>
            <List>
                <li>After the digital content is downloaded, no refund will be issued. Additionally, refund requests will not be accepted after 30 days from the date of purchase, except as required by law or if explicitly specified by the seller.</li>
                <li>Please be aware that Stripe processing fees associated with your payments are non-refundable. These fees are set by the payment processor and cannot be reimbursed by {SiteName}.</li>
            </List>

            <h5>5. Prohibited Activities</h5>
            <List>
                <li>You shall not engage in any fraudulent, abusive, or unlawful activities on {SiteName}.</li>
                <li>You shall not resell, distribute, or share purchased digital products beyond the scope of the license granted by the seller.</li>
            </List>

            <h5>6. Intellectual Property</h5>
            <List>
                <li>All intellectual property rights to digital products remain with the respective sellers.</li>
                <li>You may not use, modify, or reproduce digital products beyond the terms specified by the seller's license.</li>
            </List>

            <h5>7. Content Moderation</h5>
            <List>
                <li>{SiteName} reserves the right to moderate and remove any content that violates our policies or may be deemed inappropriate.</li>
            </List>

            <h5>8. Communication</h5>
            <List>
                <li>By making a purchase on {SiteName}, you consent to receive transactional emails related to your order, as well as important updates and marketing communications.</li>
                <li>You can manage your communication preferences or unsubscribe from marketing emails at any time.</li>
            </List>

            <h5>9. Data Protection and Privacy</h5>
            <List>
                <li>{SiteName} values your privacy and protects your personal information as outlined in our Privacy Policy.</li>
            </List>

            <h5>10. Changes to the Customer Policy</h5>
            <List>
                <li>We reserve the right to update or modify this Customer Policy at any time. The effective date will be revised accordingly, and significant changes will be communicated to you.</li>
            </List>

            <h5>11. Contact Information</h5>
            <List>
                <li>If you have any questions, concerns, or requests regarding this Customer Policy or your experience on {SiteName}, please contact us at <a target="_blank" rel="noreferrer" href={`mailto:support@${process.env.REACT_APP_FRONT_DOMAIN}`}>support@{process.env.REACT_APP_FRONT_DOMAIN}</a>.</li>
            </List>

            <p>By using {SiteName}, you acknowledge that you have read, understood, and agreed to this Customer Policy. Thank you for being a valued customer, and we look forward to providing you with a seamless and enjoyable experience on our platform!</p>
        </Container>
    </>)
}

export default CustomerPolicy