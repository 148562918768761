import React from "react"
import { NavItem, NavLink } from 'reactstrap'
import { useDomainNavigate } from "../../tools/Subdomain"



const MenuLegal = ({showTitle=false, closeOffCanvas=()=>{}}) => {
    const navigate = useDomainNavigate()

    const onCustomerPolicy = () => { closeOffCanvas(); navigate(`customerpolicy`) }
    const onPrivacyPolicy = (e) => { closeOffCanvas(); navigate(`privacypolicy`) }
    const onTermsOfService = () => { closeOffCanvas(); navigate(`termsofservice`) }

    return (<>
        {showTitle && <h6>Legal</h6>}

        <NavItem>
            <NavLink href='#' onClick={onTermsOfService} className = { (window.location.pathname === "/legal" || window.location.pathname === "/legal/termsofservice") ? 'active' : '' }>
                Terms Of Service
            </NavLink>
        </NavItem>

        <NavItem>
            <NavLink href='#' onClick={onPrivacyPolicy} className = { window.location.pathname === "/legal/privacypolicy" ? 'active' : '' }>
                Privacy Policy
            </NavLink>
        </NavItem>

        <NavItem>
            <NavLink href='#' onClick={onCustomerPolicy} className = { window.location.pathname === "/legal/customerpolicy" ? 'active' : '' }>
            Customer Policy
            </NavLink>
        </NavItem>

    </>)
}

export default MenuLegal
